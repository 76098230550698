import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import moment from "moment";
import { clearGolfer } from "./actions/golfer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setEvent } from "../../firebaseConfig";

class Header extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
  }

  componentDidMount() {
    setEvent(
      this.props.kiosk.kiosk_setup.association_name,
      this.props.kiosk.kiosk_setup.club_display_name,
      "Sentry_Logo_Display",
      "Logo_Display",
      "Header_04_01_2021",
    );
  }

  componentDidUpdate(){
    setEvent(
      this.props.kiosk.kiosk_setup.association_name,
      this.props.kiosk.kiosk_setup.club_display_name,
      "Sentry_Logo_Display",
      "Logo_Display",
      "Header_04_01_2021",
    );
  }

  _onIdle() {
    console.log(
      "user inactive",
      moment(this.idleTimer.getLastActiveTime()).format("hh:mm")
    );
    this.onExit();
  }

  onExit() {
    this.props.clearGolfer(null);
    this.props.history.push(`/kiosk/${this.props.match.params.club_name}/`);
  }

  render() {
    return (
      <Fragment>
        <header className="gap__header">
          <div className="container max">
            <div className="row">
              {/* <div
                                className={`control ${this.props.location.pathname.indexOf('/golfer/search/') === -1 ? 'visible' : ''}`}
                                onClick={() => this.onExit()}>EXIT
                            </div> */}
              <div className="branded-identity_container">
                <div
                  className="branded-identity"
                  style={{
                    backgroundImage: `url(${
                      Object.keys(this.props.kiosk).length
                        ? this.props.kiosk.kiosk_setup.association_logo
                        : ""
                    })`,
                  }}
                ></div>
                <div className="branded-identity_name">
                  {this.props.kiosk.kiosk_setup.association_name}
                </div>
              </div>
              <div className="identity">
                <div className="identity_logo"></div>
                <p className="identity_sub">
                  GOLF HANDICAP <br />
                  INFORMATION NETWORK
                </p>
              </div>
              <div className="powered-by">
                <div className="powered-by_logo sentry"></div>
              </div>
            </div>
          </div>
        </header>
        {this.props.location.pathname.indexOf("/golfer/search/") === -1 && (
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            element={document}
            onIdle={this.onIdle}
            debounce={250}
            timeout={1000 * 60 * 5}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    kiosk: state.kiosk,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ clearGolfer }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
