import requestNewApiXML from './../shared/requestNewApiXML';
import requestNewApiJson from './../shared/requestNewApiJSON';
function findKiosk(name, updated_at) {
    if(updated_at){
        return requestNewApiXML({
            url: `/kiosk/${name}.json`,
            method: 'GET',
            params: {updated_at: updated_at}
        })
    } else {
        return requestNewApiXML({
            url: `/kiosk/${name}.json`,
            method: 'GET',
        })
    }
}

function getClubHomeCourses(clubId) {
    return requestNewApiJson({
        url: `/clubs/${clubId}/facility_home_courses.json`,
        method: 'GET',
    })
}

const KisokService = {
    findKiosk,
    getClubHomeCourses
};

export default KisokService;
