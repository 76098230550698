import React, {Component} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";

import LookupSearch from './LookupSearch';
import LookupGolfer from './LookupGolfer';
import LookupGolferSelection from './Results';
import {bindActionCreators} from "redux";
import {changeLookupGolfer} from "../../shared/actions/golfer";
import {changeGolfer} from "../../shared/actions/golfer";
import {connect} from "react-redux";
import GolferService from "../../../services/api/golfer";
import KisokService from "../../../services/api/kiosk";
import NotFound from "../../shared/NotFound";

class Lookup extends Component {

    getGolferProfile(selectedGhin) {
        this.setState({loading: true});
        let ghin =  this.props.match.params.id.replace(/_/g, '=').replace(/!/g, '-').replace(/&/g, '+');
        ghin = selectedGhin ? selectedGhin : parseInt(atob(ghin));
        GolferService.findGolferByGHIN(ghin)
            .then(res => {
                if (res && res.golfers && res.golfers) {
                    let golfer = res.golfers[0];
                    golfer.affiliations = res.golfers.map(afiliation => {return afiliation.club_id});
                    golfer.id = golfer.ghin;
                    let courses = [];
                    GolferService.getHomeCourses(ghin)
                        .then(res => {
                            res.facilities.map(facility => facility.home_courses.map(homeCourse => {courses.push(homeCourse)}));
                            golfer.homeCourses = courses;
                            this.props.changeGolfer(golfer);
                            this.setState({Golfer: golfer, loading: false});
                        })
                        .catch(err => {
                            console.log(err);
                        })

                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    componentDidMount() {
        if (this.props.Golfer) {
            this.getGolferProfile(this.props.Golfer.id);
        } else {
            this.getGolferProfile();
        }
    }

    render() {
        let ghin = this.props.match.params.id.replace(/_/g, '=').replace(/!/g, '-').replace(/&/g, '+');
        ghin = parseInt(atob(ghin));
        return (
            <Switch>
                <Redirect exact from={`${this.props.match.path}`} to={`${this.props.match.path}/search`}/>
                <Route path={`${this.props.match.url}/search`}
                       render={(props) => <LookupSearch {...props} changeLookupGolfer={this.props.changeLookupGolfer}
                                                        kiosk={this.props.kiosk}/>}/>
                <Route path={`${this.props.match.url}/golfer`}
                       render={(props) => <LookupGolfer {...props} ghin={ghin} kiosk={this.props.kiosk}/>}/>
                <Route path={`${this.props.match.url}/my-profile`}
                       render={(props) => <LookupGolfer {...props} ghin={ghin} myGolfer={true} kiosk={this.props.kiosk}/>}/>
                <Route path={`${this.props.match.url}/results`} exact render={(props) => <LookupGolferSelection
                    changeLookupGolfer={this.props.changeLookupGolfer} {...props} kiosk={this.props.kiosk}/>}/>
                <Route component={NotFound}/>
            </Switch>

        );
    }
}

function mapStateToProps(state) {
    return {
        kiosk: state.kiosk,
        Golfer: state.Golfer
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({changeLookupGolfer, changeGolfer}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Lookup);
