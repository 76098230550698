import React, {Component, Fragment} from 'react';

import CustomScrollbars from '../../../shared/CustomScrollbars';

import ScoreService from '../../../../services/api/score';
import moment from "moment";
import AlertsModal from "../../../Modals/AlertsModal";

export default class MostRecentScores extends Component {


    constructor(props) {
        super(props);
        this.state = {
            recentScores: [],
            revisionScores: [],
            scoresFor9Holes: [],
            loading: true,
            adjustmentModalIsOpen: false,
            selectedAdjustments: null,
            infoModalIsOpen: false,
            childScores: null
        }
    }

    getScore() {
        ScoreService.getScores( this.props.Golfer.id, this.props.Golfer.association_id)
            .then((response) => {
                this.setState({
                    recentScores: response.recent_scores.scores,
                    revisionScores: response.revision_scores.scores,
                    scoresFor9Holes: response['9_hole_score'].scores,
                    loading: false
                })

            })
            .catch(error => {
                console.log(error);
            })
    }

    sortChildScores(scores) {
        if (scores[0].played_at !== scores[1].played_at) {
           return scores.sort((a, b) => (moment(a.played_at) > moment(b.played_at)) ? 1 : -1)
        } else {
            return scores.sort((a, b) => (moment(a.score_day_order) > moment(b.score_day_order)) ? 1 : -1)
        }
    }

    componentDidMount() {
        if (this.props.Golfer &&  this.props.Golfer.id && this.props.Golfer.association_id) {
            this.getScore()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.Golfer !== prevProps.Golfer) {
            this.getScore()
        }
    }

    getChildScores(scoreId) {
        ScoreService.getChildScores(scoreId, {golfer_id: this.props.Golfer.id})
            .then(res => {this.setState({childScores: res.scores})})
            .catch(err => {console.log(err); this.setState({combinedScoresModalIsOpen: false})})
    }

    renderCombinedScoresInfo(score) {
        if (score?.score_type === "C") {
            return (
                <button
                    onClick={() => this.setState({ combinedScoresModalIsOpen: true, childScores: null }, () => this.getChildScores(score.id))}
                    className="info-modal-trigger"
                >
                    i
                </button>
            );
        }
    }

    renderScaledScoresInfo(score) {
        if (score.number_of_played_holes < 18) {
            return (
                <button
                    onClick={() => this.setState({ scaledScoresInfoModalIsOpen: true })}
                    className="info-modal-trigger"
                >
                    i
                </button>
            );
        }
    }

    getDifferential = (score, useScaling) => {
        let differential

        if (!useScaling) {
            differential = score?.differential
        } else {
            differential = score?.number_of_played_holes === 18
                ? score?.differential
                : score?.adjusted_scaled_up_differential
        }

        return differential === '' 
            ? '' : differential === 999 
                ? '-' : parseFloat(differential).toFixed(1)
    }

    renderPlayedHoles(score) {
        if (score.number_of_played_holes > 9 && score.number_of_played_holes < 18) {
            return (
                <span>
                    <span className="subscript">{`(${score.number_of_played_holes})`}</span>
                </span>
            );
        }
    }

    renderScore(score, key, type) {
        const useScaling = this.props.Golfer?.use_scaling
        const hasHandicapIndex = this.props.Golfer?.handicap_index != 'NH' && this.props.Golfer?.handicap_index != 'WD' 
        let date = moment(score.played_at, 'YYYY-MM-DD').format('MM/YYYY');
        let adjustments = score.adjustments ? score.adjustments.filter(adj => adj.type === 'esr' || adj.type ==='committee') : null;
        const differential = this.getDifferential(score, useScaling)
        return (
            <tr key={type + score.id} className={`table_row ${type === "italic" || type === "nine-holes" ? 'italic' : ''}`}>
                <td className="table_cell star">{score.used && '*'}</td>
                <td className="table_cell type">{score.score_type_display_short}</td>
                <td className="table_cell date">{date}</td>
                <td className={`table_cell esc-score ${score.revision ? 'faded-yellow' : ''} ${type}`}>
                    {score.adjusted_gross_score}
                    {useScaling && this.renderPlayedHoles(score)}
                    {useScaling ? hasHandicapIndex && this.renderScaledScoresInfo(score) : this.renderCombinedScoresInfo(score)}
                </td>
                <td className="table_cell rating-slope">{score.course_rating && score.course_rating.toFixed(1)} / {score.slope_rating}</td>
                <td className="table_cell pcc">{score.pcc === 0 ? '-' : score.adjustments.filter(adj => adj.type === "pcc")[0].display }</td>
                <td className="table_cell diff">
                    <span>{this.getDifferential(score, useScaling)}</span>
                    {adjustments.length > 0 && differential !== '-'
                        ? <button 
                            onClick={() => this.setState({adjustmentModalIsOpen: true, selectedAdjustments: score.adjustments})} 
                            className="info-modal-trigger">!</button> 
                        : '' 
                    }
                    {differential === '-' && score.number_of_played_holes === 9 && (
                        <button
                            onClick={() => this.setState({differentialModalIsOpen: true})}
                            className="info-modal-trigger">!</button>
                    )}
                </td>
            </tr>
        );
    }

    render() {
        return (
            <Fragment>
                {this.state.loading ?
                    <div className="details-container">
                        <div className="loading"></div>
                    </div>
                    :
                    <Fragment>
                        {this.state.scoresFor9Holes.length > 0 &&
                            <div className="table-legend__container">
                                <div className="table-legend__item">
                                    <span className="legend__item-color faded-red"></span>
                                    <span className="legend__item-name">9-Hole score waiting to be combined</span>
                                </div>
                            </div>
                        }

                        <div className={`data-table_container grow`}>
                            {(this.state.recentScores.length > 0 || this.state.revisionScores.length > 0 || this.state.scoresFor9Holes.length > 0) ?
                                <Fragment>
                                    <div className="table_head most-recent-scores">
                                        <div className="table_row">
                                            <div className="table_cell star">Used</div>
                                            <div className="table_cell type">Type</div>
                                            <div className="table_cell date">Date</div>
                                            <div className="table_cell esc-score">Score</div>
                                            <div className="table_cell rating-slope">C.R. / Slope</div>
                                            <div className="table_cell pcc">PCC <button
                                                onClick={() => this.setState({infoModalIsOpen: true})}
                                                className="info-modal-trigger">i</button></div>
                                            <div className="table_cell diff">Diff</div>
                                        </div>
                                    </div>

                                    <CustomScrollbars>
                                        <table className="data-table most-recent-scores">
                                            <tbody>

                                            {(this.state.recentScores.length > 0 || this.state.scoresFor9Holes.length > 0)  &&
                                            <Fragment>
                                                <div className="table_row title">Recent Scores</div>
                                                {this.state.recentScores.map((score, key) => {
                                                    return this.renderScore(score, key, 'italic');
                                                })}
                                                {this.state.scoresFor9Holes.length > 0 && this.state.scoresFor9Holes.map((score, key) => {
                                                    return this.renderScore(score, key, 'nine-holes');
                                                })}
                                            </Fragment>}
                                            {this.state.revisionScores.length > 0 &&
                                            <Fragment>
                                                <div className="table_row title">Revision Scores</div>
                                                {this.state.revisionScores.map((score, key) => {
                                                    return this.renderScore(score, key, '');
                                                })}
                                            </Fragment>}


                                            </tbody>
                                        </table>
                                    </CustomScrollbars>
                                </Fragment> :
                                <p className={'center'}>No recent scores posted</p>
                            }
                            <div className="table-legend__container bottom">
                                <ul className="table__legend">
                                    <li>
                                        Score Type: <span>H</span> - Home, <span>A</span> - Away, <span>C</span> - Competition,  <span>N</span> - {this.props.Golfer?.use_scaling || "Combined"} 9-Hole Rounds, <span>P</span> - Penalty, <span>E</span> - Exceptional
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </Fragment>}
                <AlertsModal type={this.state.infoModalIsOpen && 'pcc'}
                             open={this.state.infoModalIsOpen}
                             closeModal={() => {
                                 this.setState({infoModalIsOpen: false})
                             }}/>
                <AlertsModal type={'adjustments'}
                             data={this.state.selectedAdjustments}
                             open={this.state.adjustmentModalIsOpen}
                             closeModal={() => {
                                 this.setState({adjustmentModalIsOpen: false})
                             }}/>
                <AlertsModal type={'combined-scores'}
                             data={this.state.childScores && (this.state.childScores.length > 0 ? this.sortChildScores(this.state.childScores) : [])}
                             open={this.state.combinedScoresModalIsOpen}
                             closeModal={() => {
                                 this.setState({combinedScoresModalIsOpen: false})
                             }}/>
                <AlertsModal type={'scaled-scores'}
                             open={this.state.scaledScoresInfoModalIsOpen}
                             closeModal={() => {
                                 this.setState({scaledScoresInfoModalIsOpen: false})
                             }}/>
                <AlertsModal type={'differential'}
                             open={this.state.differentialModalIsOpen}
                             closeModal={() => {
                                 this.setState({differentialModalIsOpen: false})
                             }}/>
            </Fragment>
        );
    }
}


