import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import * as searchBarActions from './actions/searchBar';
import CustomKeyboard from './Keyboard';
import {isMobile} from "react-device-detect";
import {isIpad} from "../../checkIpad";

class SearchBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            textField: '',
            focused: false
        };
        this.searchRef = React.createRef();
    }

    _onChange(value) {
        this.setState({
            textField: value
        });
        this.props.changeQuery(value);
    }

    componentWillUnmount() {
        this.props.changeQuery('');
    }


    render() {
        return (
            <Fragment>
            <div className="f-col is-full">
                <label
                    htmlFor="search">{this.props.type === "golfer" ? "Enter Last Name or GHIN Number" : "Enter Course Name"}</label>
                <input type="text" readOnly={(isMobile || isIpad()) && this.props.keyboard}
                       autoComplete="off"
                       className="search"
                       ref={this.searchRef}
                       autoFocus
                       value={this.state.textField}
                       onFocus={() => this.props.focus(true)}
                       onChange={event => this._onChange(event.target.value)}
                       name="search"
                       id="search"/>
            </div>
                <CustomKeyboard changeValue={(value) => {
                    this._onChange(value)
                }}
                                hideKeyboard={() => {let self=this; setTimeout(function(){self.props.focus(false)},500)}}
                                type={'default'}
                                name={'search'}
                                value={this.state.textField}
                                focused={this.props.focused}/>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        searchTerm: state.shared.searchTerm,
        keyboard:  (state.kiosk.kiosk_setup && state.kiosk.kiosk_setup.show_keyboard)
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(searchBarActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
