import requestNewApiXML from './../shared/requestNewApiXML';
import requestNewApiJSON from './../shared/requestNewApiJSON';

function findCourse(name, country,state, facilityId) {
    return requestNewApiXML({
        url: 'crsCourseMethods.asmx/SearchCourses.json',
        method: 'GET',
        params: {
            country: country,
            state: state,
            name: name,
            facilityId: facilityId
        }
    })
}

function getDetails(courseID) {
    return requestNewApiXML({
        url: `courses/${courseID}.xml`,
        method: 'GET',
        params: {
            course_id: courseID
        }
    })
}

function getTeeAGS(params, tee_set_side) {
    return requestNewApiXML({
        url: 'maximum_hole_scores.xml',
        method: 'GET',
        params: {tee_set_side: tee_set_side || 'All18', ...params}
    })
}

function getCourseHandicaps(golferID, courseID, playedAt) {
    return requestNewApiJSON({
        url: 'course_handicaps.json',
        method: 'GET',
        params: {
            GolferId: golferID,
            CourseID: courseID,
            played_at: playedAt
        }
    })
}

const CourseService = {
    findCourse,
    getDetails,
    getCourseHandicaps,
    getTeeAGS
};

export default CourseService;
