export function searchBarReducer(state = "", action) {
    switch (action.type) {
        case 'CHANGE_SEARCH_TERM':
            return action.payload;
        case 'CLEAR_SEARCH_BAR':
            return action.payload;
        default:
            return state;
    }
}

