import React, {Component, Fragment} from 'react';
import CustomScrollbars from "../shared/CustomScrollbars";
import {Scrollbars} from "react-custom-scrollbars";
import {selectTeeType} from "../shared/CourseHelper"

export default class FormTeesModal extends Component {

    renderTees(tee) {
        let rating = tee.Ratings.Rating;
        let totalPar = 0;
        let totalFront = 0;
        let totalBack = 0;
        let holes = tee.Holes.Hole;
        if(holes) {
            for(let i = 0; i < 9; i++){
                totalFront += holes[i] ? holes[i].Par : 0;
            }

            for(let i = 9; i < 18; i++){
                totalBack += holes[i] ? holes[i].Par : 0;
            }

            for(let i = 0; i < 18; i++){
                totalPar += holes[i] ? holes[i].Par : 0;
            }
        }

        let teeName = tee.TeeSetRatingName.toString().replace(/&amp;/g, '&');
        const frontRating = Array.isArray(rating) ?
            rating.find(r => r.RatingType === "Front") :
            (rating.RatingType === "Front" ? rating : undefined);
        const backRating = Array.isArray(rating) ?
            rating.find(r => r.RatingType === "Back") :
            (rating.RatingType === "Back" ? rating : undefined);
        const totalRating = Array.isArray(rating) ?
            rating.find(r => r.RatingType === "Total") :
            (rating.RatingType === "Total" ? rating : undefined);
        return (
            this.props.nrHoles === 9 ?
                <Fragment key={tee.TeeSetRatingId}>
                    {frontRating && frontRating.CourseRating  &&<div className={`tees-table_row`} onClick={() => {
                        this.props.onSelectTee(tee, frontRating.RatingType.toLowerCase());
                        this.props.closeModal();
                    }}>
                        <div className="tees-table_cell">{teeName} ({frontRating.RatingType})</div>
                        <div className="tees-table_cell">{frontRating.CourseRating && frontRating.CourseRating.toFixed(1)} / {frontRating.SlopeRating} {totalFront  ? '/' : ''} {totalFront ? totalFront : ''}</div>
                    </div>}

                    {backRating && backRating.CourseRating &&  <div className={`tees-table_row`} onClick={() => {
                        this.props.onSelectTee(tee, backRating.RatingType.toLowerCase());
                        this.props.closeModal();
                    }}>
                        <div className="tees-table_cell">{teeName} ({backRating.RatingType})</div>
                        <div className="tees-table_cell">{backRating.CourseRating && backRating.CourseRating.toFixed(1)} / {backRating.SlopeRating} {totalBack ? '/' : ''} {totalBack ? totalBack : ''}</div>
                    </div>}

                </Fragment>

                :
                (totalRating && totalRating.CourseRating  && <div className={`tees-table_row`} key={tee.TeeSetRatingId} onClick={() => {
                    this.props.onSelectTee(tee, selectTeeType(tee.Ratings.Rating));
                    this.props.closeModal();
                }}>
                    <div className="tees-table_cell">{teeName}</div>
                    <div className="tees-table_cell">{totalRating.CourseRating && totalRating.CourseRating.toFixed(1)} / {totalRating.SlopeRating} {totalPar ? '/' : ''} {totalPar ? totalPar : ''}</div>
                </div>)
        );
    }

    render() {
        if (this.props.teesList.length === 0) return null;

        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.closeModal()
                }}>Close
                </button>
                <div className="form-modal">

                    <div className="tees-table">
                        <div className="tees-table_head">
                            <div className="tees-table_row">
                                <div className="tees-table_cell">Tees</div>
                                <div className="tees-table_cell">C.R. / Slope {this.props.teesList[0].Holes.Hole ? '/ Par' : ''}</div>
                            </div>
                        </div>
                        <div className="tees-table_body">
                            <Scrollbars autoHeightMax={'60vh'}
                                        autoHeight
                                        renderThumbVertical={props => <div {...props} style={{position:'relative', right:6, width: 12, borderRadius:10, backgroundColor:'#c7c8c9'}}/>}
                                        autoHide={false}
                            >
                                {this.props.teesList.sort((a, b) => ((a.Ratings.Rating.length > 0 && a.Ratings.Rating[0].CourseRating) < (b.Ratings.Rating.length > 0 && b.Ratings.Rating[0].CourseRating)) ? 1 : -1).map(tee => {
                                    return this.renderTees(tee);
                                })}
                            </Scrollbars>
                        </div>
                    </div>

                </div>
            </Fragment>
        );
    }
}
