import React, {Component, Fragment} from 'react';
import moment from "moment";
export default class CombinedScores extends Component {

    render() {
        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.triggerAlertModal()
                }}>Close
                </button>
                <div className="alert-modal">
                    {this.props.data ?
                        (this.props.data.length > 0 ?
                            <div className="alert-modal_message no-margin">
                                <div className={'separator'}>
                                    <p>Score 1: {this.props.data[0].adjusted_gross_score}</p>
                                    <p>Month Played: {moment(this.props.data[0].played_at, "YYYY-MM-DD").format("MM/YYYY")}</p>
                                </div>

                                <div className={'separator'}>
                                    <p>Score 2: {this.props.data[1].adjusted_gross_score}</p>
                                    <p>Month Played: {moment(this.props.data[1].played_at, "YYYY-MM-DD").format("MM/YYYY")}</p>
                                </div>
                            </div>
                            :
                            <div className="alert-modal_message">
                                No scores available
                            </div>
                        )
                        :
                        <div className="details-container">
                             <div className="loading gray"></div>
                        </div>}
                </div>
            </Fragment>
        );
    }
}


