import React, {Component, Fragment} from 'react';

export default class AlertClearAllScores extends Component {

    render() {
        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.triggerAlertModal()
                }}>Close
                </button>
                <div className="alert-modal">
                    <div className="alert-modal_icon"></div>
                    {this.props.message ?
                        <div className="alert-modal_message">
                            You are about to be taken back to the Round Setup screen.<br/>
                            Hole-by-hole information entered will not be saved.
                        </div>
                     :
                        <div className="alert-modal_message">
                            Are you sure you want
                            <br/>
                            to clear all scores?
                        </div>
                    }
                    <div className="alert-modal_actions">
                        <button className="btn fill blue" onClick={() => {
                            this.props.onAnsware(true)
                        }}>OK
                        </button>
                        <button className="btn outline blue" onClick={() => {
                            this.props.onAnsware(false)
                        }}>CANCEL
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    }
}


