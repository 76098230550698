import React, {Fragment, useState, useEffect} from 'react';
import Select from 'react-select';
import GolferService from "../../../../services/api/golfer";
import AlertsModal from '../../../Modals/AlertsModal';
import CustomKeyboard from "../../../shared/Keyboard";
import {isMobile} from "react-device-detect";
import {isIpad} from "../../../../checkIpad";
import * as Constants from "../../shared/constants";

export default function LookupByName(props) {

    const [loading, setLoading] = useState(false);
    const [lastName, setLastName] = useState('');
    const [nonUS, setNonUS] = useState([]);
    const [USstates, setUSstates] = useState([]);
    const [defaultCountryState, setDefaultCountryState] = useState(null);
    const [country, setCountry] = useState(null);
    const [defaultState, setDefaultState] = useState(null);
    const [state, setState] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [target, setTarget] = useState(false);
    const [focused, setFocus] = useState(false);
    const [modalError, triggerModal] = useState(false);
    const [searchType, changeSearchType] = useState('club');

    useEffect(() => {
        let us = [{value: 'USA', label: 'All States'}];
        let nonUs= [];
        GolferService.getCountriesAndStates().then(res => {
            res.countries.map(country => {
                if (country.code === 'USA') {
                    country.states.map(state => {
                        if(state.code === props.kiosk.kiosk_setup.club_state){
                            setDefaultState({value: state.code, label: state.name});
                        }
                        us.push({value: state.code, label: state.name});
                        return null;
                    });
                } else {
                    nonUs.push({label: country.name, options: country.states.map(state => {
                            if (country.code === props.kiosk.kiosk_setup.club_country) {
                                if(state.code === props.kiosk.kiosk_setup.club_state){
                                    setDefaultCountryState({value: state.code, label: state.name, group: country.name});
                                }
                            }

                            return {value: state.code, label: state.name, group: country.name};
                        })
                    });

                }
                return null;
            });
            setNonUS(nonUs);
            setUSstates(us);
        });
    }, []);


    const onSubmit = (event) => {
        event.preventDefault();

        if (!lastName) {
            return;
        }
        setLoading(true);
        if (searchType !== 'club') {
            if ((searchType === 'country' && (country || defaultCountryState)) || (searchType === 'state' && (state || defaultState))) {
                GolferService.findGolferByNameAndState(lastName, firstName, searchType === 'country' ? (country ? country : defaultCountryState.value) : (state ? state : defaultState.value))
                    .then(res => {
                        if (res && res.golfers && res.golfers.length > 0 && res.golfers.filter(golfer => golfer.status === "Active").length > 0) {
                            let golfers = res.golfers.filter(golfer => golfer.status === "Active");
                            golfers = golfers.map(golfer => {return {...golfer, ...{id: golfer.ghin}}});
                            if (golfers.length > 1) {
                                props.history.push({
                                    pathname: '../results',
                                    state: {
                                      Golfer: golfers,
                                      searchParams: {
                                        lastName,
                                        firstName,
                                        searchType: searchType === 'country' ? (country ? country : defaultCountryState.value) : (state ? state : defaultState.value),
                                        totalPages: parseInt(res['total-pages'])
                                      }
                                    }
                                })
                            } else {
                                props.changeLookupGolfer(golfers[0]);
                                props.history.push(`../golfer`);
                            }
                        } else {
                            setLoading(false);
                            triggerModal(true);
                        }
                    })
                    .catch(error => {
                        setLoading(false);
                        console.log(error);
                    })
            } else {
                setLoading(false);
                return null;
            }
        } else {
            Promise.all(props.kiosk.clubs.map(club=>{return GolferService.findGolferByLastName(lastName, firstName, club.id)})).then(response => {
                let res = {golfers: []};
                response.map(r => {
                    res.golfers = [...res.golfers, ...r.golfers];
                    return null;
                });

                if (res && res.golfers && res.golfers.length > 0 && res.golfers.filter(golfer => golfer.status === "Active").length > 0) {
                    let golfers = res.golfers.filter(golfer => golfer.status === "Active");
                    if (golfers.length > 1) {
                        props.history.push({
                            pathname: '../results',
                            state: {
                                Golfer: golfers,
                                searchParams: {
                                        lastName,
                                        firstName,
                                        searchType: searchType === 'country' ? (country ? country : defaultCountryState.value) : (state ? state : defaultState.value),
                                        totalPages: parseInt(res['total-pages'])
                                      }
                            }
                        })
                    } else {
                        props.changeLookupGolfer(golfers[0]);
                        props.history.push(`../golfer`);
                    }
                } else {
                    setLoading(false);
                    triggerModal(true);
                }
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
            })
        }
    }

    const clubGolfers = () => {
        Promise.all([GolferService.findAllGolfersFromClub(props.kiosk.clubs[0].id, 1)]).then(response => {
            let res={golfers: []};
            let totalGolfers = 0;
            let fetchNextPage = false;

            response.map(r => {
                res.golfers = [...res.golfers, ...r.golfers];
                totalGolfers = r.meta.active_golfers_count;
                return null;
            });

            if (totalGolfers > Constants.OFFSET) {
                fetchNextPage = true;
            }

            if (res && res.golfers && res.golfers.length > 0) {
                let golfers = res.golfers;
                if (golfers.length > 1) {
                    props.history.push({
                        pathname: '../results',
                        state: {
                            Golfer: golfers,
                            totalGolfers,
                            fetchNextPage,
                            clubSelection: true,
                        }
                    })
                } else {
                    props.changeLookupGolfer(golfers[0]);
                    props.history.push(`../golfer`);
                }

            } else {
                setLoading(false);
                triggerModal(true);
            }

        })
        .catch(error => {
            setLoading(false);
            console.log(error);
        });
    }

    return (

        <Fragment>
            {loading ?
                <div className="details-container">
                    <div className="loading"></div>
                </div>

                :
            <div className="lookup-tab__panel">
                <form onSubmit={onSubmit}>

                    <div className="f-row">
                        <div className="f-col is-full">
                            <div className="default-rardio-container">
                                <div className="default-radio">
                                    <input type="radio"
                                           name="search-by"
                                           value="search-by-club"
                                           checked={searchType === 'club'}
                                           onChange={() => {
                                               changeSearchType('club');
                                               setCountry(null);
                                               setState(null);
                                               setFocus(false)
                                           }}
                                           id="search-by-club"/>
                                    <label htmlFor="search-by-club">Search Club</label>
                                </div>
                                <div className="default-radio">
                                    <input type="radio"
                                           name="search-by"
                                           value="search-by-us-state"
                                           checked={searchType === 'state'}
                                           onChange={() => {
                                               changeSearchType('state');
                                               setCountry(null);
                                               setState(null);
                                               setFocus(false);
                                           }}
                                           id="search-by-us-state"/>
                                    <label htmlFor="search-by-us-state">Search by U.S. State/Territory</label>
                                </div>
                                <div className="default-radio">
                                    <input type="radio"
                                           name="search-by"
                                           value="search-by-country"
                                           checked={searchType === 'country'}
                                           onChange={() => {
                                               changeSearchType('country');
                                               setCountry(null);
                                               setState(null);
                                               setFocus(false)
                                           }}
                                           id="search-by-country"/>
                                    <label htmlFor="search-by-country">Search Outside the U.S.</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="f-row">
                        <div className="f-col">
                            <label htmlFor="name">Enter Last Name</label>
                            <div className={`input-container`}>
                                <input type="text" readOnly={(isMobile || isIpad()) && props.keyboard}
                                       value={lastName}
                                       onChange={(e) => setLastName(e.target.value)}
                                       onFocus={() => {
                                           setFocus(true);
                                           setTarget('last-name')
                                       }}
                                       autoComplete="off"
                                       id="last-name"
                                       name="last-name"/>
                                {lastName && <span className="clear-input" onClick={() => {
                                    setLastName('');
                                }}></span>}
                            </div>
                        </div>
                        <div className="f-col">
                            <label htmlFor="name">Enter First Name (optional)</label>
                            <div className={`input-container`}>
                                <input type="text" readOnly={(isMobile || isIpad()) && props.keyboard}
                                       value={firstName}
                                       onChange={(e) => setFirstName(e.target.value)}
                                       onFocus={() => {
                                           setFocus(true);
                                           setTarget('first-name')
                                       }}
                                       autoComplete="off"
                                       id="first-name"
                                       name="first-name"/>
                                {firstName && <span className="clear-input" onClick={() => {
                                    setFirstName('');
                                }}></span>}
                            </div>
                        </div>
                        {searchType === 'state' && <div className="f-col" onClick={(event)=> { event.preventDefault();setFocus(false)}}>
                            <label htmlFor="name">State</label>
                            <div className="input-container">
                                <Select
                                    className='react-select-container'
                                    classNamePrefix="react-select"
                                    onChange={(selectedOption) => {
                                        setState(selectedOption.value)
                                    }}
                                    value={ state ? USstates.filter(s => s.code === state)[0] : defaultState}
                                    onFocus={()=>{setFocus(false)}}
                                    options={USstates}
                                    isSearchable={false}
                                />
                            </div>
                        </div>}
                        {searchType === 'country' && <div className="f-col">
                            <label htmlFor="name">Country</label>
                            <div className="input-container">
                                <Select
                                    className='react-select-container'
                                    classNamePrefix="react-select"
                                    onChange={(selectedOption) => {
                                        setCountry(selectedOption.value)
                                    }}
                                    value={country ? nonUS.filter(s => s.code === country)[0] : defaultCountryState}
                                    onFocus={()=>{setFocus(false)}}
                                    options={nonUS}
                                    isSearchable={false}
                                />
                            </div>
                        </div>}
                        {searchType === 'club' && <div className="f-col is-auto">
                            <input className="btn fill red" type="submit" value="Continue"
                                   disabled={!lastName || (searchType === 'country' && !country && !defaultCountryState) || (searchType === 'state' && !state && !defaultState)}/>
                        </div>}
                    </div>

                    {searchType !== 'club' && <div className="f-row">
                         <div className="f-col is-auto centered">
                            <input className="btn fill red" type="submit" value="Continue"
                                   disabled={!lastName || (searchType === 'country' && !country && !defaultCountryState) || (searchType === 'state' && !state && !defaultState)}/>
                        </div>
                    </div>}
                </form>

                {searchType === 'club' && <div className="f-row j-center">
                    <div className="f-col is-1-of-2">
                        <button onClick={clubGolfers} 
                                className="btn outline blue transparent">
                            View Club Roster
                        </button>
                    </div>
                </div>}
            </div>}
            <CustomKeyboard changeValue={(value) => {
                target === 'first-name' ? setFirstName(value) : setLastName(value)
            }}
                            hideKeyboard={() => {
                                setFocus(false)
                            }}
                            type={'default'}
                            name={target}
                            value={target === 'first-name' ? firstName : lastName}
                            focused={focused}/>
            <AlertsModal open={modalError} closeModal={() => {
                triggerModal(false)
            }} type='no-golfer'/>

        </Fragment>
    )
}


