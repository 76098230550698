import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';
import {withRouter} from "react-router-dom";
import {clearGolfer} from "./actions/golfer";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import KioskService from "../../services/api/kiosk"
import ContentTerms from '../Modals/ContentTerms';
import ContentPrivacy from '../Modals/ContentPrivacy';
import moment from "moment";
import { setEvent } from "../../firebaseConfig";

class Footer extends Component {

    constructor() {
        super();

        this.state = {
            termsModalIsOpen: false,
            privacyModalIsOpen: false,
            promotionalImage: 0,
            kiosk_images: [],
        };

        this.openTermsModal = this.openTermsModal.bind(this);
        this.openPrivacyModal = this.openPrivacyModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
      if (this.props.kiosk.promotional_images[this.state.promotionalImage])
        setEvent(this.props.kiosk.kiosk_setup.association_name, this.props.kiosk.kiosk_setup.club_display_name, "Kiosk_Promotional_Image_Display", "Display", this.props.kiosk.promotional_images[this.state.promotionalImage].tag_analytics);
    }

    openTermsModal() {
        this.setState({
            termsModalIsOpen: true
        });
    }

    openPrivacyModal() {
        this.setState({
            privacyModalIsOpen: true
        });
    }

    closeModal() {
        this.setState({
            termsModalIsOpen: false,
            privacyModalIsOpen: false
        });
    }

    onExit() {
        this.props.clearGolfer(null);
        this.props.history.push(`/kiosk/${this.props.match.params.club_name}/`);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({promotionalImage : Math.floor((Math.random() * this.props.kiosk.promotional_images.length))});
        }

        if (this.props.kiosk.promotional_images[this.state.promotionalImage])
          setEvent(this.props.kiosk.kiosk_setup.association_name, this.props.kiosk.kiosk_setup.club_display_name, "Kiosk_Promotional_Image_Display", "Display", this.props.kiosk.promotional_images[this.state.promotionalImage].tag_analytics);
    }

    render() {

        let promotionalImage = Object.keys(this.props.kiosk).length ?
            this.props.kiosk.promotional_images[this.state.promotionalImage] : '';

        return (
            <Fragment>

                <footer className="gap__footer">

                    <div className="container auto">
                        <div className={`row ${this.props.location.pathname.indexOf('/golfer/search/') === -1 ? '' : 'centered'} v-centered`}>
                            {(this.props.location.pathname.indexOf('/profile') !== -1 || this.props.location.pathname.indexOf('/recent-score') !== -1 || this.props.location.pathname.indexOf('/course-handicap') !== -1  || this.props.location.pathname.indexOf('/most-recent-scores') !== -1) && <div className="col">
                                <button
                                    className={`btn fill red `}
                                    onClick={() => this.onExit()}>EXIT
                                </button>
                            </div>}
                            {(promotionalImage && promotionalImage.url) &&  <div className={`col`}>
                                    <figure className="footer-banner__holder">
                                        <img src={ promotionalImage.url}
                                             alt={(promotionalImage && promotionalImage.alt) ?
                                                 promotionalImage.alt
                                                 :
                                                 'banner'}/>
                                    </figure>
                            </div>}
                        </div>
                    </div>

                    <div className="footer_copyright">
                        <div className="container wide">
                            <ul className="footer_list">
                                <li>
                                    <button onClick={this.openPrivacyModal}>Privacy Policy</button>
                                </li>
                                <li>
                                    <button onClick={this.openTermsModal}>Terms of Service</button>
                                </li>
                                <li>&copy; {moment().format('YYYY')} United States Golf Association. All Rights Reserved.</li>
                            </ul>
                        </div>
                    </div>
                </footer>

                <Modal
                    isOpen={this.state.termsModalIsOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Terms"
                    portalClassName="modal"
                    overlayClassName="modal__overlay"
                    className="modal__content"
                    bodyOpenClassName="modal--is-open"
                    htmlOpenClassName="enable-scroll"
                    shouldCloseOnOverlayClick={true}
                    shouldFocusAfterRender={true}
                >
                    <ContentTerms closeModal={() => {
                        this.closeModal()
                    }}/>
                </Modal>

                <Modal
                    isOpen={this.state.privacyModalIsOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Privacy"
                    portalClassName="modal"
                    overlayClassName="modal__overlay"
                    className="modal__content"
                    bodyOpenClassName="modal--is-open"
                    htmlOpenClassName="enable-scroll"
                    shouldCloseOnOverlayClick={true}
                    shouldFocusAfterRender={true}
                >
                    <ContentPrivacy closeModal={() => this.closeModal()}/>
                </Modal>

            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {kiosk: state.kiosk};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({clearGolfer}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
