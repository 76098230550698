import React, {Component, Fragment} from 'react';
import {Redirect} from "react-router-dom";
import GolferHead from "../../shared/GolferHead";
import Navigation from "../../../shared/Navigation";
import Filters from "../shared/Filters";
import {getFormValues, reduxForm} from "redux-form";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import AlertsModal from "../../../Modals/AlertsModal";
import moment from "moment/moment";
import ScoreService from "../../../../services/api/score";
import CourseService from "../../../../services/api/course";

class ReviewScore extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            infoModalIsOpen: false,
            loading: false,
            waiting: false,
            confirmModal: false,
            confirmModalMessage:  null,
            scoreId: null,
            changeModal: false,
            dataToChange: null,
            confirmTeeChange: false,
            teeToChange: null,
            errorModal: false,
            error: null
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    openModal() {
        this.setState({infoModalIsOpen: true});
    }

    closeModal() {
        this.setState({infoModalIsOpen: false});
    }

    returnTotal(array, from, to) {
        let total = 0;
        for (let i = from; i <= to; i++) {
            if(array['hole' + i] !== '')
                total += parseInt(array['hole' + i]);
        }
        return total;
    }

    selectDate(date) {
        this.props.change('date', moment(date).format('MMMM DD, YYYY'));
        if (this.props.formValues.tee != null) {
          let tee_set_side = this.props.formValues.nrHoles === 18 ? 'All18' : (this.props.formValues.teeType === 'front' ? 'F9' : 'B9')
          this.getTeeAGS(this.props.formValues.tee.TeeSetRatingId, date, tee_set_side);
        }
    }

    selectScore(type) {
        this.props.change('scoreType', type);
    }

    selectTee(tee, type) {
        this.setState({loading: true, confirmTeeChange:false});
        this.props.change('teeType', type);
        this.props.change('tee', tee);
        let tee_set_side = this.props.formValues.nrHoles === 18 ? 'All18' : (type === 'front' ? 'F9' : 'B9')
        this.getTeeAGS(tee.TeeSetRatingId, this.props.formValues.date, tee_set_side);
    }

    getTeeAGS(tee_set_id, date, tee_set_side) {
      let params = {golfer_id: this.props.Golfer.id, tee_set_id: tee_set_id, played_at: moment(date).format('YYYY-MM-DD')}
        CourseService.getTeeAGS(params, tee_set_side)
            .then(response => {
                if (response.MaximumScores && response.MaximumScores.MaximumScore.length > 0) {
                    let ags = {};
                    Object.keys(this.props.formValues.ags).map((name, key) => {
                        if(this.props.formValues.ags[name] === "") {
                            ags[name] = "";
                        } else {
                            ags[name] = Math.min(parseInt(this.props.formValues.holes[name]), response.MaximumScores.MaximumScore[key]['maximum-score']);
                        }
                    });
                    this.props.change('ags', ags);
                    this.props.change('agsMax', response.MaximumScores.MaximumScore);
                    this.setState({loading: false});
                }
            })
            .catch(error => {
                this.props.change('agsMax', null);
                this.setState({errorModal: 'cant-post-hbh', loading: false});
                console.log(error);
            })
    }

    submit() {
        this.setState({loading: true});
        let values = this.props.formValues;
        let playedAt = moment(values.date, 'MMMM DD, YYYY').format('YYYY-MM-DD');
        let teeType = 'All18';

        let date = moment(this.props.formValues.date, 'MMMM DD, YYYY').format('MM/DD').split('/');
        let start = moment(date + ' 2000', "MM/DD YYYY").isBefore(moment(values.season.SeasonStartDate + ' 2000', "MM/DD YYYY"));
        let end = moment(date + ' 2000', "MM/DD YYYY").isAfter(moment(values.season.SeasonEndDate + ' 2000', "MM/DD YYYY"));
        //
        if (!values.season.IsAllYear) {
            if (start || end) {
                this.setState({loading: false, errorModal: 'alert-season'});
                return;
            }
        }

        if (values.nrHoles === 9) {
            switch (values.teeType) {
                case 'front':
                    teeType = 'F9';
                    break;
                case 'back':
                    teeType = 'B9';
                    break;
                default:
                    break;
            }
        }

        let holes = [];
        if (values.nrHoles === 9) {
            Object.keys(values.holes).forEach((hole, key) => {
                if (values.holes[hole]) {
                    holes.push({ hole_number: key + 1, raw_score: parseInt(values.holes[hole]) });
                }
            });
        }
        else {
            Object.keys(values.holes).forEach((hole, key) => {
                if (values.holes[hole]) {
                    holes.push({ hole_number: key + 1, raw_score: parseInt(values.holes[hole]) });
                }
                else {
                    holes.push({ hole_number: key + 1, raw_score: 0, x_hole: true });
                }
            });
        }

        let data = {
            golfer_id: this.props.Golfer.id.toString(),
            course_id: values.course.CourseID.toString(),
            tee_set_id: values.tee.TeeSetRatingId.toString(),
            tee_set_side: teeType,
            played_at: playedAt,
            scoreType: values.scoreType === "Competition" ? 'T' : values.scoreType.charAt(0),
            number_of_holes: values.nrHoles.toString(),
            gender: this.props.Golfer.gender,
            override_confirmation: false,
            is_manual: false,
            hole_details: holes
        };

        ScoreService.postHoleByHole(data)
            .then(res => {
                const message = res.score.validation_message_display
                if (res.score.status === "UnderReview" && message) {
                    this.setState({confirmModal: true, confirmModalMessage: message, loading: false, scoreId: res.score.id});
                } else {
                    this.props.history.push('../recent-score');
                }
            })
            .catch(err => {
                if(err.data.errors && err.data.errors.played_at) {
                    this.setState({
                        error: err.data.errors.played_at[0],
                        loading: false
                    })
                } else if (err.data.errors && err.data.errors.adjusted_gross_score) {
                    this.setState({
                        error: "Adjusted gross score " + err.data.errors.adjusted_gross_score[0] + ". " + "Adjusted gross score " + err.data.errors.adjusted_gross_score[1] + ".",
                        loading: false
                    })
                }
                else if(err.data.errors && err.data.errors.hi){
                    this.setState({
                        error: err.data.errors.hi[0],
                        loading: false
                    })
                } else if(err.data.errors && err.data.errors.tee_set){
                    this.setState({
                        error: err.data.errors.tee_set[0],
                        loading: false
                    }) 
                } else if (err.data.errors && err.data.errors.score) {
                    this.setState({
                        error: 'You have exceeded the number of scores that can be posted for this day. Please ensure the correct date played has been selected when posting scores.',
                        loading: false
                    });
                }
                console.log(err);
            })

    }

    setupRound(){this.goToSetup()}

    goToSetup() {
        let holes = {
            hole1: '',
            hole2: '',
            hole3: '',
            hole4: '',
            hole5: '',
            hole6: '',
            hole7: '',
            hole8: '',
            hole9: '',
            hole10: '',
            hole11: '',
            hole12: '',
            hole13: '',
            hole14: '',
            hole15: '',
            hole16: '',
            hole17: '',
            hole18: ''
        };
        let ags = {
            hole1: '',
            hole2: '',
            hole3: '',
            hole4: '',
            hole5: '',
            hole6: '',
            hole7: '',
            hole8: '',
            hole9: '',
            hole10: '',
            hole11: '',
            hole12: '',
            hole13: '',
            hole14: '',
            hole15: '',
            hole16: '',
            hole17: '',
            hole18: ''
        };
        this.props.change('holes', holes);
        this.props.change('ags', ags);
        this.props.history.push('../hole-by-hole')
    }

    showChangeModal(data) {
        this.setState({changeModal:true});
    }

    confirmScore() {
        this.setState({loading: true});
        ScoreService.confirmScore(this.props.Golfer.id, this.state.scoreId)
            .then(res => {
                this.props.history.push('../recent-score');
            })
            .catch(err => {
                console.log(err);
            })
    }

    render() {
        if (!this.props.Golfer) return null;
        let {Golfer} = this.props;
        let values = this.props.formValues;
        const nrHoles = values.nrHoles === 9 ? 9
            : Object.values(values.holes).filter(value => value).length === 18 ? 18 : 17

        if (!values.tee) {
            return (<Redirect to="../hole-by-hole"/>);
        }

        return (
            <Fragment>
                <Navigation backButton={true} reviewBackButton={true}/>

                <section className="top-padding small">

                    <div className="container wide">

                        <GolferHead Golfer={Golfer}/>
                        {this.state.loading ?
                            <div className="details-container">
                                <div className="loading"></div>
                            </div>
                            :
                            <div className="details-container">
                                <Filters loading={false}
                                         date={values && values.date}
                                         nrHoles={nrHoles}
                                         course={values && values.course}
                                         scoreType={values && values.scoreType}
                                         teeType={values && values.teeType}
                                         tee={values && values.tee}
                                         teesList={values && values.teesList}
                                         onSelectDate={(date) => this.selectDate(date)}
                                         onSelectTee={(data)=> this.setState({confirmTeeChange: true, teeToChange: data})}
                                         onSelectScore={(type) => this.selectScore(type)}
                                         review={true}
                                         showChangeModal={()=> this.showChangeModal()}
                                />
                                <div className="details_column">
                                    <div className={`esc-score_container center`}>
                                    <span className="esc-score">Adjusted Gross Score
                                        <button onClick={() => this.openModal()}
                                                className="info-modal-trigger">i</button>
                                    </span>
                                    </div>

                                    <div className="post-total-score-container">
                                        <div className="score-cell only-of-type">
                                            <h2 className="large-score">{values && (
                                                values.nrHoles === 18 ?
                                                    this.returnTotal(values.ags, 1, 18)
                                                    :
                                                    this.returnTotal(values.ags, values.teeType === "front" ? 1 : 10, values.teeType === "front" ? 9 : 18)
                                            )
                                            }</h2>
                                        </div>
                                    </div>

                                    <button onClick={() => this.submit()} className="btn fill red full">POST SCORE
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </section>

                <AlertsModal type={'adjusted'}
                             open={this.state.infoModalIsOpen}
                             closeModal={() => {
                                 this.closeModal()
                             }}/>

                <AlertsModal answare={(value) => {
                    value ? this.confirmScore() : this.setState({confirmModal: false})
                }}
                             type={'alert-under-review-score'}
                             open={this.state.confirmModal}
                             message={this.state.confirmModalMessage}
                             closeModal={() => {
                                 this.setState({confirmModal: false})
                             }}/>

                <AlertsModal answare={(value) => {
                                        value ? this.setupRound() : this.setState({changeModal: false})
                                    }}
                             type={'change-setup-score'}
                             open={this.state.changeModal}
                             closeModal={() => {
                                 this.setState({changeModal: false})
                             }}/>

                <AlertsModal answare={(value) => { value ? this.selectTee(this.state.teeToChange, this.props.formValues.teeType) : this.setState({confirmTeeChange: false})}}
                             type={'change-tee'}
                             open={this.state.confirmTeeChange}
                             closeModal={()=> {
                                 this.setState({confirmTeeChange: false})
                             }}/>

                <AlertsModal type={'api-error'} open={this.state.error ? true : false} errorMessage={this.state.error} closeModal={() => this.setState({error: null})} />


                <AlertsModal type={this.state.errorModal}
                             open={this.state.errorModal ? true : false}
                             closeModal={() => {
                                 this.setState({errorModal: false})
                             }}/>
            </Fragment>
        );
    }
}

ReviewScore = reduxForm({
    form: 'hbh',
    destroyOnUnmount: false
})(ReviewScore);

function mapStateToProps(state) {
    const values = getFormValues('hbh')(state)
    const nrHolesPlayed = Object.values(values?.holes).filter(value => value).length
    values.nrHoles = nrHolesPlayed === 9 ? 9 : 18
    values.teeType = nrHolesPlayed === 9 ? (values?.holes['hole1'] ? 'front' : 'back') :  values?.teeType
    return {
        formValues: values,
        Golfer: state.Golfer,
        kiosk: state.kiosk
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewScore);
