import React, {Component, Fragment} from 'react';
import Navigation from "../../shared/Navigation";
import SearchByLastName from "./LastName";
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import SearchByLocalNumber from "./LocalNumber";
import SearchByGhinNumber from "./GhinNumber";
import SearchGuest from "./SearchGuest";
import {changeGolfer} from "../../shared/actions/golfer";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

class GolferSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            guest: false
        }
    }

    render() {
        return (
            <Fragment>
                <Navigation backButton={false} postButton={false}/>
                <section className="top-padding large">
                    <div className="container wide">
                        <h1>{this.props.kiosk.kiosk_setup.club_display_name}</h1>
                        {!this.state.guest &&
                        <div className="tabs-container">
                            <ul className="tabs">
                                <li><NavLink to={`${this.props.match.url}/name`} activeClassName="is-active">Last Name</NavLink>
                                </li>
                                <li><NavLink to={`${this.props.match.url}/localnumber`} activeClassName="is-active">Local
                                    Number</NavLink></li>
                                <li><NavLink to={`${this.props.match.url}/ghinnumber`} activeClassName="is-active">Ghin
                                    Number</NavLink></li>
                            </ul>
                        </div>
                        }
                        <Switch>
                            <Redirect exact from={this.props.match.url} to={`${this.props.match.url}/name`}/>
                            <Route path={`${this.props.match.url}/name`} exact
                                   render={() => <SearchByLastName {...this.props}/>}/>
                            <Route path={`${this.props.match.url}/localnumber`} exact
                                   render={() => <SearchByLocalNumber {...this.props}/>}/>
                            <Route path={`${this.props.match.url}/ghinnumber`} exact
                                   render={() => <SearchByGhinNumber {...this.props}/>}/>
                            <Route path={`${this.props.match.url}/guest`} exact
                                   render={() => <SearchGuest {...this.props}/>}/>

                        </Switch>

                        <div className="guest">
                            <div className="f-row j-center">
                                <div className="f-col is-1-of-2">
                                    {this.state.guest ?
                                        <NavLink to={`${this.props.match.url}/name`} onClick={() => {
                                            this.setState({guest: false})
                                        }} className="btn outline blue transparent">
                                            GO TO Member SCORE POSTING</NavLink>
                                        :
                                        <NavLink to={`${this.props.match.url}/guest`} onClick={() => {
                                            this.setState({guest: true})
                                        }} className="btn outline blue transparent">
                                            GO TO GUEST SCORE POSTING</NavLink>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        kiosk: state.kiosk,
        keyboard:  (state.kiosk.kiosk_setup && state.kiosk.kiosk_setup.show_keyboard)
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({changeGolfer}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GolferSearch);