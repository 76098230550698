import React, {Component, Fragment} from 'react';
import MostRecentScores from "../../Lookup/LookupGolfer/MostRecentScores";
import Navigation from "../../../shared/Navigation";
import GolferHead from "../../shared/GolferHead";

export default class RecentScore extends Component {

    render() {
        if (!this.props.Golfer) return null;
        let Golfer = this.props.Golfer;
        return (
            <Fragment>
                <Navigation backButton={false} exitKiosk={true} postAnotherScore={true} lookup={false}/>
                <section className="top-padding small golfer-lookup">
                    <div className="container wide dont-grow">
                        {Golfer && <GolferHead Golfer={Golfer}/>}
                    </div>

                    <div className="container wide grow data-table-pre_container">
                        <MostRecentScores Golfer={Golfer}/>
                    </div>
                </section>
            </Fragment>
        );
    }
}



