import React, {Component, Fragment} from 'react';

export default class AlertValidScore extends Component {

    render() {
        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.triggerAlertModal()
                }}>Close
                </button>
                <div className="alert-modal">
                    <div className="alert-modal_icon"></div>
                    <div className="alert-modal_message">
                        {this.props.scaled18HBH 
                            ? 'You must enter a score for at least 9 holes of the same side (e.g. Front 9, Back 9) in order to post your score.'
                            : 'Please enter a value for each hole.'}</div>
                    <div className="alert-modal_actions">
                        <button className="btn fill blue" onClick={() => this.props.triggerAlertModal()}>Ok</button>
                    </div>
                </div>
            </Fragment>
        );
    }
}


