import {createStore, applyMiddleware} from 'redux';
import {routerMiddleware} from 'react-router-redux';
import thunk from 'redux-thunk';
import {createBrowserHistory} from 'history';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistStore, persistCombineReducers} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sharedComponentsEpic from './screens/shared/epics';
import {createEpicMiddleware} from 'redux-observable';
import rootReducer from './rootReducer';
import {createBlacklistFilter} from "redux-persist-transform-filter";
import ReactGA from 'react-ga4';
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

export const history = createBrowserHistory();
const gaTrackingMiddleware = store => next => action => {
    if (action.type === '@@router/LOCATION_CHANGE') {
        const nextPage = `${action.payload.location.pathname}`;
        trackPage(nextPage,store.getState().kiosk);
    }
    return next(action);
};
// const loggerMiddleWare = store => next => action => {
//     console.log("[LOG] Action triggered", action);
//     next(action);
// };
const trackPage = (page,kiosk) => {
    ReactGA.ga('send', 'pageview', page, kiosk.kiosk_setup && {dimension3: kiosk.kiosk_setup.association_name, dimension4: kiosk.kiosk_setup.club_display_name});
};

const rootEpic = sharedComponentsEpic,
    epicMiddleware = createEpicMiddleware();


const initialState = {
        auth: {
            isAuthenticated: false
        },
        shared: {
            appConfig: {
                networkStatus: true,
                networkModal: false
            },
            searchTerm: null
        }
    },
    enhancers = [],
    middleware = [
        thunk,
        epicMiddleware,
        routerMiddleware(history),
        gaTrackingMiddleware,
        // GaTrackingAssociationAndClub,
        // loggerMiddleWare
    ];


const composedEnhancers = composeWithDevTools(
    applyMiddleware(...middleware),
    ...enhancers
);

const config = {
    key: 'kiosk',
    storage,
    blacklist: ['router', 'shared'],
    transforms: [
        createBlacklistFilter('shared.searchTerm'),
    ]
};
const reducer = persistCombineReducers(config, rootReducer(history));

function configureStore() {
    let store = createStore(
        reducer,
        initialState,
        composedEnhancers
    );
    epicMiddleware.run(rootEpic);
    let persistor = persistStore(store);

    return {persistor, store};
}

export const {persistor, store} = configureStore();
