import React, {Component, Fragment} from 'react';
import Calendar from 'react-calendar';

export default class FormDateModal extends Component {


    onChange = date => this.setState({date}, () => {
        this.props.onSelectDate(date)
    });

    render() {

        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.closeModal()
                }}>Close
                </button>
                <div className="form-modal">

                    <Calendar
                        className="modal-type"
                        onChange={(date) => {
                            this.props.onSelectDate(date);
                            this.props.closeModal();
                        }}
                        maxDate={new Date()}
                        minDate={new Date('1990-01-01T00:00:00')}
                        value={new Date(this.props.date)}
                        calendarType="US"
                        view="month"
                    />

                </div>
            </Fragment>
        );
    }
}


