export function changeGolfer(data) {
    return (dispatch) => {
        dispatch({type: 'CHANGE_GOLFER', payload: data});
    }
}

export function clearGolfer() {
    return (dispatch) => {
        dispatch({type: 'CLEAR_GOLFER'});
    }
}

export function changeLookupGolfer(data) {
    return (dispatch) => {
        dispatch({type: 'CHANGE_LOOKUP_GOLFER', payload: data});
    }
}

export function clearLookupGolfer() {
    return (dispatch) => {
        dispatch({type: 'CLEAR_LOOKUP_GOLFER'});
    }
}