import React, {Component, Fragment} from 'react';
import Modal from "react-modal";
import Filters from "../shared/Filters";
import moment from "moment";
import CourseService from "../../../../services/api/course";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import CantPostHbhModal from "../../../Modals/CantPostHbhModal";
import AlertsModal from '../../../Modals/AlertsModal';
import GolferHead from "../../shared/GolferHead";
import Navigation from "../../../shared/Navigation";
import {reduxForm, getFormValues} from 'redux-form';
import SameDayModal from '../../../Modals/SameDayModal';
import KisokService from "../../../../services/api/kiosk";
import { sameAsCurrentDate } from '../../../../helpers/date-helper';
import {selectTeeType} from '../../../shared/CourseHelper'

class InitialForm extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            Golfer: null,
            scoreModal: false,
            dateModal: false,
            nrHolesModal: false,
            teesModal: false,
            courseModal: false,
            scoreType: 'Home',
            nrHoles: 18,
            tee: null,
            teeError: null,
            teeType: null,
            teesList: [],
            handicaps: {},
            yourCourseHandicap: null,
            date: moment().format('MMMM DD, YYYY'),
            season: {},
            loading: true,
            step: '',
            blocked: false,
            errorModal: false,
            infoModal: false,
            error: false
        }
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.kiosk) {
            if (this.props.formValues && !this.props.formValues.course) {
                this.selectCourse(this.props.kiosk.kiosk_setup.default_course);
            } else {
                if(!this.props.formValues.tee) {
                    this.getCourseHandicap((this.props.formValues.course.CourseID))
                } else {
                    if(this.props.formValues.teesList.filter(tee => tee === this.props.formValues.tee).length === 0){
                        this.getCourseHandicap((this.props.formValues.course.CourseID))
                    } else {
                        this.setState({loading:false});
                    }
                }
            }
        }

    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    getTeeSetSide(nrHoles, teeType) {
        return nrHoles > 9 ? 'All18' : (teeType === 'front' ? 'F9' : 'B9');
    }

    selectDate(date) {

      if (!sameAsCurrentDate(date)) {
        this.setState({sameDayModalOpen: true})
      }
      this.props.change('date', moment(date).format('MMMM DD, YYYY'));
      if (this.props.formValues.tee != null) {
          let tee_set_side = this.getTeeSetSide(this.props.formValues.nrHoles, this.props.formValues.teeType)
        this.getTeeAGS(this.props.formValues.tee.TeeSetRatingId, date, tee_set_side);
      }
      this.getCourseHandicap(this.props.formValues.course.CourseID, date, false);
    }

    selectTee(tee, type) {
        this.props.change('teeType', type);
        this.props.change('tee', tee);

        if(tee.Holes && tee.Holes.Hole && tee.Holes.Hole.filter(hole => hole.Allocation).length !== 0) {
            this.setState({errorModal: false, error: false});
            this.props.change('yourCourseHandicap', this.props.formValues.handicaps[tee.TeeSetRatingId]);
            let tee_set_side = this.getTeeSetSide(this.props.formValues.nrHoles, type)
            this.getTeeAGS(tee.TeeSetRatingId, this.props.formValues.date, tee_set_side);
        } else {
            this.setState({cantPostHbhModal: true, error: true, errorData: {course: this.props.formValues.course, tee: tee}});
        }
        if(tee.HolesNumber !== 9 && tee.Holes && tee.Holes.Hole && tee.Holes.Hole.length !== 18) {
            this.setState({cantPostHbhModal: true, error: true, errorData: {course: this.props.formValues.course, tee: tee}});
        }

    }

    postTotalScore() {
        this.props.history.push({pathname:`./total-score`, state: {data:this.state.errorData} });
    }

    getCourseHandicap (courseId, date, noDateChange = true) {
        let playedAt = date != null ? date : new Date();
        CourseService.getCourseHandicaps( this.props.Golfer.id, courseId, moment(playedAt).format("YYYY-MM-DD"))
            .then(res => {
                let handicaps = {};
                if(res && res.tee_sets && res.tee_sets.length > 0) {
                    res.tee_sets.map(ts => {
                        handicaps[ts.tee_set_id] = {
                            all: ts.ratings[0] ? ts.ratings[0].course_handicap_display : 0,
                            front: ts.ratings[1] ? ts.ratings[1].course_handicap_display : 0,
                            back: ts.ratings[2] ? ts.ratings[2].course_handicap_display : 0,
                        };
                    });
                }
                this.props.change('handicaps', handicaps);
                let isTee = this.props.formValues.tee;
                if (noDateChange) {
                  this.getCourseDetails(courseId);
                } else if (isTee) {
                  this.props.change('yourCourseHandicap', this.props.formValues.handicaps[this.props.formValues.tee.TeeSetRatingId]);
                }
            })
            .catch(err => {
                let isTee = this.props.formValues.tee;
                if (noDateChange) {
                  this.getCourseDetails(courseId);
                } else if (isTee) {
                  this.props.change('yourCourseHandicap', this.props.formValues.handicaps[this.props.formValues.tee.TeeSetRatingId]);
                }
                console.log(err)
            })
    }

    getTeeAGS(tee_set_id, date, tee_set_side) {
      let params = {golfer_id: this.props.Golfer.id, tee_set_id: tee_set_id, played_at: moment(date).format('YYYY-MM-DD')}
        CourseService.getTeeAGS(params, tee_set_side)
          .then(response => {
              if (response.MaximumScores && response.MaximumScores.MaximumScore.length > 0) {
                  this.props.change('agsMax', response.MaximumScores.MaximumScore);
              }
          })
          .catch(error => {
              this.props.change('agsMax', null);
              this.setState({errorModal: 'cant-post-hbh', error: true});
              console.log(error);
          })
    }

    selectCourse(course) {
        if (this._isMounted) {
            if(this.props.Golfer.homeCourses && this.props.Golfer.homeCourses.length && this.props.Golfer.homeCourses.filter(c => parseInt(course.CourseID) === parseInt(c.course_id)).length > 0) {
                this.selectScore("Home");
            } else {
                this.selectScore("Away");
            }
            this.setState({loading: true});
            this.props.change('handicaps', {});
            this.props.change('yourCourseHandicap', null);
            this.props.change('course', course);
            this.getCourseHandicap(course.CourseID, this.props.formValues.date);

        }
    }

    searchCourse(facilityId) {
        if (this._isMounted) {
            this.setState({course: [], loading: true});
            CourseService.findCourse('', '', '', facilityId)
                .then((response) => {
                    if (response.courses && response.courses.length > 0) {
                        this.selectCourse(response.courses[0]);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    getCourseDetails(courseID) {
        CourseService.getDetails(courseID)
            .then(response => {
                if (response && response.Courses.TeeSets && (response.Courses.TeeSets.TeeSet || response.Courses.TeeSets.TeeSet.length > 0)) {
                    let responseTees = Array.isArray(response.Courses.TeeSets.TeeSet) ? response.Courses.TeeSets.TeeSet : [response.Courses.TeeSets.TeeSet];
                    let initialTeesList  = responseTees.filter(tee => {
                        if(tee.Gender.charAt(0) === this.props.Golfer.gender) {
                            return tee;
                        } else {
                            return null;
                        }
                    });
                    let teesList = initialTeesList.filter(tee => {
                        if (Array.isArray(tee.Ratings.Rating)) {
                            return tee.Ratings.Rating.some(rating => rating.SlopeRating !== undefined);
                        } else {
                            return tee.Ratings.Rating && tee.Ratings.Rating.SlopeRating !== undefined;
                        }
                    });
                    if (teesList.length > 0) {
                        if (this._isMounted) {

                            let tee;
                            // check if the selected course is the kiosk_setup default_course and then select the default_Tee from there...
                            if (this.props.formValues.course.CourseID === parseInt(this.props.kiosk.kiosk_setup.default_course.CourseID)) {
                                tee = teesList.filter(tee =>
                                    parseInt(tee.TeeSetRatingId) === parseInt(this.props.kiosk.kiosk_setup.default_course[this.props.Golfer.gender === 'M' ? 'default_tee_male': 'default_tee_female'].id)
                                )[0];
                            }

                            // check if the selected course is in home_courses then just select the default_tee from there...
                            let checkHomeCourse = this.props.kiosk.clubs.filter(club => club.club_number === parseInt(this.props.Golfer.club_id))[0] && this.props.kiosk.clubs.filter(club => club.club_number === parseInt(this.props.Golfer.club))[0]?.home_courses.filter( course => parseInt(course.id) === this.props.formValues.course.CourseID)[0];
                            if (checkHomeCourse) {
                                tee = teesList.filter(tee => parseInt(tee.TeeSetRatingId) === parseInt(checkHomeCourse[this.props.Golfer.gender === 'M' ? 'default_tee_male': 'default_tee_female'].id))[0];
                            }

                            // check if the selected course is from home_courses then just select the default_tee from there...
                            if (this.props.formValues.course[this.props.Golfer.gender === 'M' ? 'default_tee_male': 'default_tee_female'] && this.props.formValues.course[this.props.Golfer.gender === 'M' ? 'default_tee_male': 'default_tee_female'].id !== "") {
                                tee = teesList.filter(tee =>
                                    tee.TeeSetRatingId === parseInt(this.props.formValues.course[this.props.Golfer.gender === 'M' ? 'default_tee_male': 'default_tee_female'].id)
                                )[0];
                            }

                            if(tee) {
                                this.selectTee(tee, selectTeeType(tee.Ratings.Rating));
                            } else {
                                this.props.change('teeType', null);
                                this.props.change('tee', null);
                            }

                            let teesList9 =  teesList.filter(tee => tee.HolesNumber === 9);

                            if(teesList9.length === teesList.length) {
                                this.props.change('nrHoles', 9);
                                this.props.change('block18h', true)
                            } else {
                                this.props.change('block18h', false)
                            }

                            this.props.change('teesList', teesList);
                            this.props.change('season', response.Courses.Season);
                            this.setState({
                                teeError: null,
                                season: response.Courses.Season,
                                loading: false
                            })

                        }
                    } else {
                        if (initialTeesList.length !== 0) {
                            this.props.change('teesList', []);
                            this.props.change('course', null);
                            this.props.change('tee', null);
                            this.setState({
                                teesList: [],
                                teeError: 'No tee available',
                                errorModal: 'short-course',
                                loading: false
                            })
                        } else {
                            this.props.change('teesList', []);
                            this.props.change('tee', null);
                            this.setState({
                                teesList: [],
                                teeError: 'No tee available',
                                errorModal: 'no-tees',
                                loading: false
                            })
                        }
                    }
                } else {
                    this.props.change('teesList', []);
                    this.props.change('tee', null);
                    this.setState({
                        teesList: [],
                        teeError: 'No tee available',
                        errorModal: 'no-tees',
                        loading: false
                    })
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    selectScore(type) {
        this.props.change('scoreType', type);
    }

    selectNrHoles(nr) {
        this.props.change('nrHoles', nr);

        let tee_set_side = this.getTeeSetSide(nr, this.props.formValues.teeType)
        if (this.props.formValues.tee) {
            this.getTeeAGS(this.props.formValues.tee.TeeSetRatingId, this.props.formValues.date, tee_set_side);
        }
    }

    goToTable() {
        let date = moment(this.props.formValues.date, 'MMMM DD, YYYY').format('MM/DD').split('/');
        let start = moment(date + ' 2000', "MM/DD YYYY").isBefore(moment(this.state.season.SeasonStartDate + ' 2000', "MM/DD YYYY"));
        let end = moment(date + ' 2000', "MM/DD YYYY").isAfter(moment(this.state.season.SeasonEndDate + ' 2000', "MM/DD YYYY"));
        //
        if (!this.state.season.IsAllYear) {
            if (start || end) {
                this.setState({errorModal: 'alert-season'});
                return;
            }
        }
        if( this.props.formValues.nrHoles === 17)
            this.props.change('nrHoles', 18)

        this.props.history.push(`./hole-by-hole/${
            this.props.formValues && this.props.formValues.nrHoles === 9 ?
                (this.props.formValues && this.props.formValues.teeType === 'front' ?
                    'post-front'
                    :
                    'post-back')
                :
                'post-front'
            }`);
    }


    render() {

        if (!this.props.Golfer) return null;
        let {Golfer} = this.props;
        let values = this.props.formValues;
        const hasHandicapIndex = Golfer?.handicap_index != 'NH' && Golfer?.handicap_index != 'WD' 
        let home_courses = [];
        this.props.kiosk.clubs.map(club => club.home_courses.map(course => {home_courses.push(course)}));
        return (
            <Fragment>

                <Navigation backButton={true}
                            goToProfile={true}
                            reviewButton={this.props.subPath === "post" && (this.state.nrHoles === 9 || this.props.step === "back")}
                            postBackButton={this.props.subPath === "post" && this.props.step === "front" && this.state.nrHoles === 18}/>

                <section className="top-padding small">

                    <div className="container wide">

                        <GolferHead Golfer={Golfer}/>

                        {this.state.loading ?
                            <div className="details-container">
                                <div className="loading"></div>
                            </div>
                            : <div className="details-container">
                                <Filters loading={this.state.loading}
                                         date={values && values.date}
                                         nrHoles={values && values.nrHoles}
                                         block18h={values && values.block18h}
                                         display17h={Golfer.use_scaling && hasHandicapIndex}
                                         course={values && values.course}
                                         homeCourses={home_courses}
                                         scoreType={values && values.scoreType}
                                         teeType={values && values.teeType}
                                         tee={values && values.tee}
                                         teeError={this.state.teeError}
                                         teesList={values.teesList}
                                         onSelectDate={(date) => this.selectDate(date)}
                                         onSelectCourse={(course) => this.selectCourse(course)}
                                         onSelectScore={(type) => this.selectScore(type)}
                                         onSelectTee={(tee, type) => this.selectTee(tee, type)}
                                         onSelectNrHoles={(nrHoles) => {
                                             this.selectNrHoles(nrHoles)
                                         }}
                                />
                                <div className="details_column centered">
                                    {values.yourCourseHandicap && <div className={`esc-score_container ${values.nrHoles === 9 ? 'center' : ''}`}>
                                        <span className="esc-score">
                                            <span>Your Course Handicap is </span>
                                            <span className={"value"}>
                                                {values.nrHoles === 18 ? values.yourCourseHandicap.all : values.yourCourseHandicap[values.teeType]}
                                            </span>
                                            <button onClick={() => this.setState({infoModal: true})}
                                                    className="info-modal-trigger">i</button>
                                        </span>
                                    </div>}
                                    <button disabled={this.state.error || this.state.errorModal || !this.props.formValues.agsMax || !this.props.formValues.tee} className="btn fill red full" onClick={() => {
                                        this.goToTable()
                                    }}>
                                        Enter Hole-By-Hole Score
                                    </button>
                                </div>
                                <Modal
                                    isOpen={this.state.cantPostHbhModal}
                                    onRequestClose={() => {
                                        this.setState({cantPostHbhModal: false, errorData: null})
                                    }}
                                    contentLabel="Alert Valid Ghin"
                                    portalClassName="modal"
                                    overlayClassName="modal__overlay"
                                    className="modal__content"
                                    bodyOpenClassName="modal--is-open"
                                    htmlOpenClassName="prevent-scroll"
                                    shouldCloseOnOverlayClick={true}
                                    shouldFocusAfterRender={false}>
                                    <CantPostHbhModal postTotalScore={() => this.postTotalScore()}
                                                      closeModal={() => {this.setState({cantPostHbhModal: false, errorData: null})}}/>
                                </Modal>
                                <Modal
                                  isOpen={this.state.sameDayModalOpen}
                                  onRequestClose={() => {
                                    this.setState({sameDayModalOpen: false})
                                  }}
                                  contentLabel="Alert Valid Ghin"
                                  portalClassName="modal"
                                  overlayClassName="modal__overlay"
                                  className="modal__content"
                                  bodyOpenClassName="modal--is-open"
                                  htmlOpenClassName="prevent-scroll"
                                  shouldCloseOnOverlayClick={true}
                                  shouldFocusAfterRender={false}>
                                  <SameDayModal
                                    closeModal={() => this.setState({sameDayModalOpen: false})}
                                  />
                                </Modal>
                                <AlertsModal type={this.state.infoModal ? 'course-handicap' : this.state.errorModal} open={this.state.infoModal || (this.state.errorModal ? true : false)}
                                             closeModal={() => {
                                                 this.setState({errorModal: false, infoModal: false})
                                             }}/>
                            </div>}
                    </div>

                </section>

            </Fragment>
        );
    }
}


InitialForm = reduxForm({
    // a unique name for the form
    form: 'hbh',
    destroyOnUnmount: false
})(InitialForm);

function mapStateToProps(state) {
    return {
        formValues: getFormValues('hbh')(state),
        Golfer: state.Golfer,
        kiosk: state.kiosk
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InitialForm);
