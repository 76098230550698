import React, {Component, Fragment} from 'react';

export default class AlertScorePosted extends Component {

    render() {
        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.triggerAlertModal()
                }}>Close
                </button>
                <div className="alert-modal">
                    <div className="alert-modal_icon check"></div>
                    <div className="alert-modal_message">
                        Your score has been posted!
                    </div>
                    <div className="alert-modal_actions">
                    </div>
                </div>
            </Fragment>
        );
    }
}


