import React, {Component, Fragment} from 'react';
import Navigation from "../../shared/Navigation";
import CustomScrollbars from "../../shared/CustomScrollbars";
import AlertsModal from "../../Modals/AlertsModal";

export default class GolferSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Golfers: [],
            loading: true,
            modalError: false,
        };
    }

    extractGolfersFromProps() {
        if (this.props.history.location.state && this.props.history.location.state.Golfer) {
            return this.props.history.location.state.Golfer;
        } else {
            return [];
        }
    }

    componentDidMount() {
        let Golfers = this.extractGolfersFromProps();
        Golfers.sort(function (a, b) {
            if (a.first_name < b.first_name) {
                return -1;
            }
            if (a.first_name > b.first_name) {
                return 1;
            }
            return 0;
        });
        this.setState({
            Golfers: Golfers,
            loading: false
        })
    }

    navigateToProfile(golfer) {
        if(golfer.is_under_13) {
            this.setState({modalError: 'minor-golfer'})
        } else {
            this.props.changeGolfer(golfer);
            this.props.history.push(`../${
                btoa(golfer.id)
                    .replace(/[=]/g, '_')
                    .replace(/[-]/g, '!')
                    .replace(/[+]/g, '&')
            }/profile`);
        }

    }

    render() {
        return (
            <Fragment>
                <Navigation backButton={true} postButton={false} backValue={"Back"}/>
                {this.state.loading ?
                    <div className="details-container">
                        <div className="loading"></div>
                    </div>
                    :
                    <section className="top-padding small">
                        <div className="container wide">

                            <h2>Golfer Selection</h2>

                            <div className="selection">

                                <div className="selection_head">
                                    <div className="selection_row">
                                        <div className="selection_cell name">Name</div>
                                        <div className="selection_cell club">Club Name</div>
                                        <div className="selection_cell handicap-index">Handicap Index</div>
                                    </div>
                                </div>

                                <div className="selection_body">
                                    <CustomScrollbars
                                        autoHeightMax={"50vh"}
                                    >
                                        {this.state.Golfers.map((golfer, key) => {
                                            return (
                                                <div key={key} className="selection_row"
                                                     onClick={() => this.navigateToProfile(golfer)}>
                                                    <div className="selection_cell name">
                                                        {golfer.first_name} {golfer.last_name} {golfer.suffix}
                                                    </div>
                                                    <div className="selection_cell club">
                                                        {golfer.club_name}
                                                    </div>
                                                    <div className="selection_cell handicap-index">
                                                        {golfer.hi_display}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </CustomScrollbars>
                                </div>

                            </div>
                        </div>
                    </section>
                }
                <AlertsModal open={this.state.modalError ? true : false}
                             closeModal={() => {
                                 this.setState({modalError: false})
                             }}
                             type={this.state.modalError}/>
            </Fragment>

        );
    }
}


