import React, {Component, Fragment} from 'react';
import {Switch, Route} from 'react-router-dom';
import GolferService from "../../../services/api/golfer";
import PostTotal from "./PostTotal/";
import PostHoleByHole from "./PostHoleByHole/"
import RecentScore from './RecentScore';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {changeGolfer} from "../../shared/actions/golfer";
import KisokService from "../../../services/api/kiosk";
import NotFound from "../../shared/NotFound";

class ScoreIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Golfer: null,
            formModalIsOpen: false,
            loading: false
        }
    }

    getGolferProfile() {
        this.setState({loading: true});
        let ghin = this.props.match.params.id.replace(/_/g, '=').replace(/!/g, '-').replace(/&/g, '+');
        ghin = parseInt(atob(ghin));
        GolferService.findGolferByGHIN(ghin)
            .then(res => {
                if (res && res.golfers && res.golfers) {
                    let golfer = res.golfers[0];
                    golfer.id = golfer.ghin;
                    golfer.affiliations = res.golfers.map(afiliation => {return afiliation.club_id});
                    let courses = [];
                    GolferService.getHomeCourses(ghin)
                        .then(res => {
                            res.facilities.map(facility => facility.home_courses.map(homeCourse => {courses.push(homeCourse)}));
                            golfer.homeCourses = courses;
                            this.props.changeGolfer(golfer);
                            this.setState({Golfer: golfer, loading: false});
                        })
                        .catch(err => {
                            console.log(err);
                        })

                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    componentDidMount() {
        if (this.props.Golfer) {
            this.setState({
                Golfer: this.props.Golfer
            })
        } else {
            this.getGolferProfile();
        }
    }

    render() {
        let {Golfer} = this.state;

        return (
            <Fragment>
                {this.state.loading || !Golfer ?
                    <div className="details-container" style={{height:'100%'}}>
                        <div className="loading"></div>
                    </div>
                    :
                    <Switch>
                        <Route path={`${this.props.match.url}/total-score`} exact
                               render={(props) => <PostTotal {...props} Golfer={Golfer}/>}/>
                        <Route path={`${this.props.match.url}/hole-by-hole`}
                               render={(props) => <PostHoleByHole {...props} id={this.props.match.params.id} Golfer={Golfer}/>}/>
                        <Route path={`${this.props.match.url}/recent-score`} exact
                               render={(props) => <RecentScore {...props} Golfer={Golfer}/>}/>
                        <Route component={NotFound}/>
                    </Switch>
                }
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        Golfer: state.Golfer,
        kiosk: state.kiosk
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({changeGolfer}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoreIndex);
