export function appConfigReducer(state = {}, action) {
    switch (action.type) {
        case 'CHANGE_NETWORK_STATUS':
            return {
                ...state,
                networkStatus: action.payload
            };
        case 'TRIGGER_NETWORK_MODAL':
            return {
                ...state,
                networkModal: action.payload
            };
        default:
            return state;
    }
}