import React, {Component, Fragment} from 'react';
import Navigation from "../../../shared/Navigation";
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import CourseHandicap from "./CourseHandicap";
import MostRecentScores from "./MostRecentScores";
import GolferHead from "../../shared/GolferHead";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {clearLookupGolfer} from "../../../shared/actions/golfer";

class Lookup extends Component {

    componentWillUnmount() {
        this.props.clearLookupGolfer();
    }


    render() {
        let Golfer = this.props.lookupGolfer;

        if (this.props.myGolfer) {
            Golfer = this.props.Golfer;
        } else {
            if(!this.props.lookupGolfer){
                return (<Redirect to={`../`}/>)
            }
        }

        return (
            <Fragment>
                <Navigation backButton={true} backValue={this.props.myGolfer ? "Home" : "Golfer lookup"} postButton={false} goToProfile={true}
                            lookupSearch={this.props.myGolfer ? false : true}/>
                <section className="top-padding small golfer-lookup">
                    <div className="container wide dont-grow">
                        {Golfer &&
                        <GolferHead Golfer={Golfer}/>
                        }
                    </div>

                    <div className="container narrow dont-grow">

                        <div className="tabs-container">
                            <ul className="tabs">
                                <li><NavLink to={`${this.props.match.url}/course-handicap`} activeClassName="is-active">Course
                                    Handicap</NavLink></li>
                                <li><NavLink to={`${this.props.match.url}/most-recent-scores`}
                                             activeClassName="is-active">Scores</NavLink></li>
                            </ul>
                        </div>

                    </div>

                    <div className="container wide grow data-table-pre_container">

                        <Switch>
                            <Redirect exact from={`${this.props.match.url}`}
                                      to={`${this.props.match.url}/course-handicap`}/>
                            <Route path={`${this.props.match.url}/course-handicap`}
                                   exact
                                   render={(props) => <CourseHandicap initialGolfer={this.props.Golfer}
                                                                      kiosk={this.props.kiosk}
                                                                      Golfer={Golfer}/>}/>
                            <Route path={`${this.props.match.url}/most-recent-scores`}
                                   exact
                                   render={(props) => <MostRecentScores initialGolfer={this.props.Golfer}
                                                                        Golfer={Golfer}/>}/>
                        </Switch>

                    </div>

                </section>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        lookupGolfer: state.lookupGolfer,
        Golfer: state.Golfer,
        kiosk: state.kiosk
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({clearLookupGolfer}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Lookup);
