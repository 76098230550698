import React, {Fragment, useState} from 'react';
import GolferService from "../../../services/api/golfer";
import AlertsModal from "../../Modals/AlertsModal";
import CustomKeyboard from "../../shared/Keyboard";
import {isMobile} from "react-device-detect";
import {isIpad} from "../../../checkIpad";

export default function SearchByLocalNumber(props) {
    const [localNumber, setLocalNumber] = useState("");
    const [focused, setFocus] = useState(false);
    const [modalError, triggerModal] = useState(false);

    const onSubmit = (event) => {
        event.preventDefault();

        let reg = /^[a-zA-Z0-9]*$/;
        if (localNumber.length < 2 || localNumber.length > 10 ){
            triggerModal('invalid-local-number');
            return;
        }
        if (!reg.test(localNumber)) {
            triggerModal('invalid-local-number');
            return;
        }

        Promise.all(props.kiosk.clubs.map(club=>{return GolferService.findGolferByLocalNumber(localNumber,club.id)})).then(response => {
            let res = {golfers: []};
            response.map(r => {
                res.golfers = [...res.golfers, ...r.golfers];
                return null;
            });

            if (res && res.golfers.length > 0) {
                if(res.golfers.filter(golfer => !golfer.is_under_13).length > 0) {
                    let golfers = res.golfers.filter(golfer => golfer.status === "Active" && golfer.technology_provider === "GHIN" && !golfer.is_under_13);

                    if (golfers.length > 1) {
                        props.history.push({
                            pathname: '../result/search',
                            state: {
                                Golfer: golfers
                            }
                        })
                    } else {
                        if (golfers[0].status === "Inactive") {
                            triggerModal("inactive-golfer");
                        } else if (golfers[0].technology_provider !== "GHIN") {
                            triggerModal("non-ghin");
                        } else {
                            props.changeGolfer(golfers[0]);
                            props.history.push(`../${
                                btoa(golfers[0].id)
                                    .replace(/[=]/g, '_')
                                    .replace(/[-]/g, '!')
                                    .replace(/[+]/g, '&')
                            }/profile`);
                        }
                    }
                } else {
                    triggerModal("minor-golfer");
                }
            } else {
                triggerModal("no-golfer");
            }

        })
        .catch(error => {
            triggerModal(true);
            console.log(error);
        });
    };
    return (
        <Fragment>
            <form onSubmit={onSubmit}>
                <div className="lookup">
                    <div className="f-row">
                        <div className="f-col is-2-of-3">
                            <label htmlFor="last-name">Enter Member's Local Number</label>
                            <div className="input-container">
                                <input type="text" readOnly={(isMobile || isIpad()) && props.keyboard}
                                       value={localNumber}
                                       onChange={e => setLocalNumber(e.target.value)}
                                       onFocus={() => {
                                           setFocus(true)
                                       }}
                                       autoComplete="off"
                                       id="local-number"
                                       name="local-number"/>
                                {localNumber && <span className="clear-input" onClick={() => {
                                    setLocalNumber('')
                                }}></span>}
                            </div>
                        </div>
                        <div className="f-col is-1-of-3">
                            <input className="btn fill red" type="submit" disabled={!localNumber} value="Continue"/>
                        </div>
                    </div>
                </div>
            </form>
            <AlertsModal open={modalError ? true : false} closeModal={() => {
                triggerModal(false)
            }} type={modalError}/>
            <CustomKeyboard changeValue={(value) => {
                                setLocalNumber(value)
                            }}
                            hideKeyboard={() => setFocus(false)}
                            type={"number-and-abc"}
                            name={'local-number'} value={localNumber}
                            focused={focused}/>
        </Fragment>
    )

}
