import React, {Component, Fragment} from 'react';
import Navigation from "../shared/Navigation";
import {NavLink} from "react-router-dom";
import GolferService from "../../services/api/golfer";
import GolferHead from "./shared/GolferHead";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {changeGolfer} from "../shared/actions/golfer";
import KisokService from "../../services/api/kiosk";

class GolferProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Golfer: null,
            loading: false,
        }
    }

    getGolferProfile() {
        this.setState({loading: true});
        let ghin = this.props.match.params.id.replace(/_/g, '=').replace(/!/g, '-').replace(/&/g, '+');
        ghin = atob(ghin);
        GolferService.findGolferByGHIN(ghin)
            .then(res => {
                if (res && res.golfers && res.golfers) {
                    let golfer = res.golfers[0];
                    golfer.id = golfer.ghin;
                    golfer.affiliations = res.golfers.map(afiliation => {return afiliation.club_id});
                    let courses = [];
                    GolferService.getHomeCourses(ghin)
                        .then(res => {
                            res.facilities.map(facility => facility.home_courses.map(homeCourse => {courses.push(homeCourse)}));
                            golfer.homeCourses = courses;
                            this.props.changeGolfer(golfer);
                            this.setState({Golfer: golfer, loading: false});
                        })
                        .catch(err => {
                            console.log(err);
                        })
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    componentDidMount() {
        if (this.props.Golfer && this.props.Golfer.homeCourses) {
            this.setState({
                Golfer: this.props.Golfer
            })
        } else {
            this.getGolferProfile();
        }
    }

    render() {
        let {Golfer} = this.state;
        return (
            <Fragment>

                <Navigation backButton={false} postButton={false}/>
                {this.state.loading ?
                    <div className="details-container" style={{height:'100%'}}>
                        <div className="loading"></div>
                    </div>
                    :
                    <section className="top-padding small">
                        <div className="container wide">

                            <GolferHead Golfer={Golfer}/>

                        </div>

                        <div className="container narrow">

                            <div className="golfer-profile_body">

                                <div className="golfer-profile_actions">

                                    <NavLink to={"./score/total-score"} className="action">
                                        <span className="action_icon golf"></span>
                                        <span className="action_name">Post <br/> Total Score</span>
                                    </NavLink>

                                    <NavLink to={"./score/hole-by-hole"} className="action">
                                        <span className="action_icon pencil"></span>
                                        <span className="action_name">Post <br/> Hole-by-Hole <br/> Score</span>
                                    </NavLink>

                                    <NavLink to={"./lookup/my-profile"} className="action">
                                        <span className="action_icon user"></span>
                                        <span className="action_name">My <br/> Handicap <br/>Information</span>
                                    </NavLink>

                                    <NavLink to={"./lookup"} className="action">
                                        <span className="action_icon lookup"></span>
                                        <span className="action_name">Golfer <br/> Lookup</span>
                                    </NavLink>

                                </div>

                            </div>

                        </div>
                </section>}
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        Golfer: state.Golfer,
        kiosk: state.kiosk
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({changeGolfer}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GolferProfile);
