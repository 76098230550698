import React, {Component, Fragment} from 'react';
import Navigation from "../../../shared/Navigation";
import CustomScrollbars from "../../../shared/CustomScrollbars";
import GolferService from "../../../../services/api/golfer";
import * as Constants from "../../shared/constants";
import Select from 'react-select';
import AlertsModal from '../../../Modals/AlertsModal';

export default class LookupGolferSelection extends Component {

    constructor(props) {
        super(props);
        var totalPages = 0;
        try {
          totalPages = props.location.state.searchParams.totalPages;
        } catch {}
        this.state = {
            Golfers: [],
            loading: true,
            currentPage: 2,
            fetchNextPage: false,
            scrollBarRef: null,
            scrollPosition: 0,
            clubs: [],
            currentClub: null,
            modalError: false,
            loadMoreLoading: false,
          changeClubLoading: false,
          currentPage: 1,
          totalPages: totalPages,
          disableLoadMoreButton: 1 < totalPages ? false : true
        };
    }

    extractGolfersFromProps() {
        if (this.props.history.location.state && this.props.history.location.state.Golfer) {
            return this.props.history.location.state.Golfer;
        } else {
            return [];
        }
    }

    extractTotalGolfersFromProps() {
        if (this.props.history.location.state && this.props.history.location.state.totalGolfers) {
            return this.props.history.location.state.totalGolfers;
        } else {
            return 0;
        }
    }

    extractFetchNextPageFromProps() {
        if (this.props.history.location.state && this.props.history.location.state.fetchNextPage) {
            return this.props.history.location.state.fetchNextPage;
        } else {
            return false;
        }
    }

    extractClubSelectionFromProps() {
        if (this.props.history.location.state && this.props.history.location.state.clubSelection) {
            return this.props.history.location.state.clubSelection;
        } else {
            return false;
        }
    }

    componentDidMount() {
        let Golfers = this.extractGolfersFromProps();
        let totalGolfers = this.extractTotalGolfersFromProps();
        let fetchNextPage = this.extractFetchNextPageFromProps();
        let clubs = this.generateClubOptions();
        let clubSelection = this.extractClubSelectionFromProps();
        this.setState({
            Golfers: Golfers,
            loading: false,
            totalGolfers,
            fetchNextPage,
            clubs: clubs,
            currentClub: clubs[0].value,
            clubSelection
        });
    }

    navigateToProfile(golfer) {
        this.props.changeLookupGolfer(golfer);
        this.props.history.push(`./golfer`);
    }

    incrementCurrentPage() {
        this.setState({
            currentPage: this.state.currentPage + 1
        });
    }

    updateFetcNextPage() {
        if (this.state.totalGolfers > this.state.currentPage * Constants.OFFSET) {
            this.setState({
                fetchNextPage: true
            });
        } else {
            this.setState({
                fetchNextPage: false
            });
        }
    }

  loadNextPage() {
    const data = this.props.location.state.searchParams
    this.setState({
      disableLoadMoreButton: true
    })
    GolferService.findGolferByNameAndState(data.lastName, data.firstName, data.searchType, this.state.currentPage + 1).then((res) => {
      if (res && res.golfers && res.golfers.length > 0 && res.golfers.filter(golfer => golfer.status === "Active").length > 0) {
        let golfers = res.golfers.filter(golfer => golfer.status === "Active");
        golfers = golfers.map(golfer => { return { ...golfer, ...{ id: golfer.ghin } } });
        this.setState({
          Golfers: [...this.state.Golfers, ...golfers],
          currentPage: this.state.currentPage + 1,
          disableLoadMoreButton: this.state.currentPage + 1 < res['total-pages'] ? false : true
        })
      }
    })
  }

    loadMoreGolfers() {
        this.setState({
            loadMoreLoading: true,
            scrollPosition: this.state.scrollBarRef.scrollTop
        });

        Promise.all([GolferService.findAllGolfersFromClub(this.state.currentClub, this.state.currentPage)]).then(response => {
            let res={golfers: []};

            response.map(r => {
                res.golfers = [...res.golfers, ...r.golfers];
                return null;
            });

            if (res && res.golfers && res.golfers.length > 0) {
                let golfers = res.golfers;
                this.setState({
                    Golfers: [...this.state.Golfers, ...golfers]
                });
            }

            this.updateFetcNextPage();
            this.incrementCurrentPage();
            this.setState({
                loadMoreLoading: false
            });

            this.state.scrollBarRef.scrollTop = this.state.scrollPosition;
        })
        .catch(error => {
            console.log(error);
        });
    }

    loadGolfersFromCurrentClub(currentClub) {
        this.setState({
            Golfers: [],
            changeClubLoading: true,
            currentPage: 2,
            fetchNextPage: false,
            scrollPosition: 0,
            currentClub: currentClub
        });

        Promise.all([GolferService.findAllGolfersFromClub(currentClub, 1)]).then(response => {
            let res={golfers: []};
            let totalGolfers = 0;
            let fetchNextPage = false;

            response.map(r => {
                res.golfers = [...res.golfers, ...r.golfers];
                totalGolfers = r.meta.active_golfers_count;
                return null;
            });

            if (totalGolfers > Constants.OFFSET) {
                fetchNextPage = true;
            }

            if (res && res.golfers && res.golfers.length > 0) {
                let golfers = res.golfers;
                if (golfers.length > 1) {
                    this.setState({
                        Golfers: golfers,
                        fetchNextPage: fetchNextPage,
                        totalGolfers: totalGolfers,
                        changeClubLoading: false
                    });
                } else {
                    this.props.changeLookupGolfer(golfers[0]);
                    this.props.history.push(`../golfer`);
                }
            } else {
                this.setState({
                    changeClubLoading: false,
                    modalError: true
                });
            }

            this.state.scrollBarRef.scrollTop = this.state.scrollPosition;
        })
        .catch(error => {
            console.log(error);
        });
    }

    receiveFromChild = (scrollBarRef) => {
        this.setState({
            scrollBarRef: scrollBarRef
        });
    }

    generateClubOptions() {
        return this.props.kiosk.clubs.map(club => {
            return { value: club.id, label: club.club_name };
        });
    }

    handleSelectChange = (event) => {
        this.loadGolfersFromCurrentClub(event.value);
    }
    
    render() {
        return (
            <Fragment>
                <Navigation backButton={true} backValue="Back" postButton={false}/>
                {this.state.loading ?
                    <div className="details-container">
                        <div className="loading"></div>
                    </div>
                    :
                    <section className="top-padding small">
                        <div className="container wide">
                            {this.state.clubSelection === true && this.state.clubs.length > 1 &&
                                <div className="bottom-margin">
                                    <h2>Club Selection</h2>
                                    <Select
                                        className='react-select-container react-select-container-more'
                                        classNamePrefix="react-select"
                                        options={this.state.clubs}
                                        defaultValue={this.state.clubs[0]}
                                        onChange={this.handleSelectChange}
                                        value={this.state.clubs.find(obj => obj.value === this.state.currentClub)}
                                        isSearchable={false}
                                    />
                                </div>
                            }

                            <h2>Golfer Selection</h2>

                            {this.state.changeClubLoading === true ?
                                <div className="details-container">
                                    <div className="loading gray"></div>
                                </div>
                                :
                                <div className="selection">

                                    <div className="selection_head">
                                        <div className="selection_row">
                                            <div className="selection_cell name">Name</div>
                                            <div className="selection_cell club">Club Name</div>
                                            <div className="selection_cell handicap-index">Handicap Index</div>
                                        </div>
                                    </div>

                                    <div className="selection_body">
                                        <CustomScrollbars
                                            autoHeightMax={"50vh"}
                                            fromChildToParent={this.receiveFromChild}
                                        >
                                            {this.state.Golfers.map((golfer, key) => {
                                                return (
                                                    <div key={key} className="selection_row"
                                                            onClick={() => this.navigateToProfile(golfer)}>
                                                        <div className="selection_cell name">
                                                            {golfer.first_name} {golfer.last_name} {golfer.suffix}
                                                        </div>
                                                        <div className="selection_cell club">
                                                            {golfer.club_name}
                                                        </div>
                                                        <div className="selection_cell handicap-index">
                                                            {golfer.hi_display}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </CustomScrollbars>
                                    </div>
                      <div className="f-row j-center" style={{ marginTop: "32px" }}>
                        <div className="f-col is-1-of-3">
                          <button
                            className="btn outline blue transparent"
                            disabled={this.state.disableLoadMoreButton}
                            onClick={() => { this.loadNextPage() }}
                          >Load More</button>
                        </div>
                      </div>
                                </div>
                            }

                            {this.state.fetchNextPage ? 
                                this.state.loadMoreLoading ?
                                    <div className="details-container">
                                        <div className="loading gray"></div>
                                    </div>
                                    :
                                    <div className="f-row j-center">
                                        <div className="f-col is-1-of-3">
                                            <button onClick={() => this.loadMoreGolfers()} 
                                                    className="btn outline blue transparent top-margin50">
                                                Load More
                                            </button>
                                        </div>
                                    </div>
                                : <div className="empty" />}
                        </div>
                    </section>
                }
                <AlertsModal open={this.state.modalError} closeModal={() => {
                    this.setState({
                        modalError: false
                    })
                }} type='no-golfer'/>
            </Fragment>
        );
    }
}


