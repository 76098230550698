import React, {Fragment, useState} from 'react';
import GolferService from "../../../services/api/golfer";
import AlertsModal from "../../Modals/AlertsModal";
import CustomKeyboard from "../../shared/Keyboard";
import {isMobile} from "react-device-detect";
import {isIpad} from "../../../checkIpad";
import moment from 'moment';

export default function SearchByLastName(props) {
    const [lastName, setLastName] = useState("");
    const [focused, setFocus] = useState(false);
    const [modalError, triggerModal] = useState(false);
    const onSubmit = (event) => {

        event.preventDefault();
        if (!lastName.length) return;
        if (lastName.includes('%')){
          triggerModal("no-golfer");
          return;
        }
        
        Promise.all(props.kiosk.clubs.map(club=>{return GolferService.findGolferByLastName(lastName, '', club.id)})).then(response => {
            let res={golfers: []};
            response.map(r => {
                res.golfers = [...res.golfers, ...r.golfers];
                return null;
            });

            if (res && res.golfers.length > 0) {

                if(res.golfers.filter(golfer => !golfer.is_under_13).length > 0) {

                    let golfers = res.golfers;
                    let filteredGolfers = golfers.filter(golfer => golfer.status === "Active" && golfer.technology_provider === "GHIN");
                    if (filteredGolfers.length > 1) {
                        props.history.push({
                            pathname: '../result/search',
                            state: {
                                Golfer: filteredGolfers
                            }
                        })
                    } else if(filteredGolfers.length === 1) {
                        props.changeGolfer(filteredGolfers[0]);
                        props.history.push(`../${
                            btoa(filteredGolfers[0].id)
                                .replace(/[=]/g, '_')
                                .replace(/[-]/g, '!')
                                .replace(/[+]/g, '&')
                        }/profile`);
                    } else {
                        triggerModal("inactive-golfer");
                    }

                } else {
                    triggerModal("minor-golfer");
                }

            } else {
                triggerModal("no-golfer");
            }

        })
        .catch(error => {
            console.log(error);
        });

    }
    return (
        <Fragment>
            <form onSubmit={onSubmit}>
                <div className="lookup">
                    <div className="f-row">
                        <div className="f-col is-2-of-3">
                            <label htmlFor="last-name">Enter Member's Last Name</label>
                            <div className="input-container">
                                <input type="text" readOnly={(isMobile || isIpad()) && props.keyboard}
                                       value={lastName}
                                       onChange={e => setLastName(e.target.value)}
                                       onFocus={() => setFocus(true)}
                                       autoComplete="off"
                                       id="last-name"
                                       name="last-name"/>
                                {lastName && <span className="clear-input" onClick={() => {
                                    setLastName('')
                                }}></span>}
                            </div>
                        </div>
                        <div className="f-col is-1-of-3">
                            <input className="btn fill red" type="submit" disabled={!lastName} value="Continue"/>
                        </div>
                    </div>
                </div>
            </form>
            <AlertsModal open={modalError ? true : false} closeModal={() => {
                triggerModal(false)
            }} type={modalError}/>
            <CustomKeyboard changeValue={(value) => {
                setLastName(value)
            }} hideKeyboard={() => setFocus(false)} type={'default'} name={'last-name'} value={lastName}
                            focused={focused}/>
        </Fragment>
    );
}
