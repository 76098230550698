import React, {Component, Fragment} from 'react';

export default class AlertNetworkError extends Component {

    render() {
        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.triggerAlertModal()
                }}>Close
                </button>
                <div className="alert-modal">
                    {/*<div className="alert-modal_icon alert"></div>*/}
                    <div className="alert-modal_message">
                        Unable to connect, the task cannot be completed at this time.
                        <br/>
                        Please try again later.
                    </div>
                    <div className="alert-modal_actions">
                        <button className="btn fill blue" onClick={() => {
                            this.props.triggerAlertModal()
                        }}>Ok</button>
                    </div>
                </div>
            </Fragment>
        );
    }
}


