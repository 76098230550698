import React, {Component, Fragment} from 'react';

export default class Differential extends Component {
    render() {
        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.triggerAlertModal()
                }}>Close
                </button>
                <div className="alert-modal">
                    <p className="alert-modal_message">
                        This score currently has no Score Differential, but will receive 
                        an 18-hole Score Differential once enough scores have been posted 
                        (54 holes) to establish a Handicap Index.
                    </p>
                </div>
            </Fragment>
        );
    }
}


