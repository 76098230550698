import React, {Component, Fragment} from 'react';

export default class AlertChangeNrHoles extends Component {

    render() {
        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.triggerAlertModal()
                }}>Close
                </button>
                <div className="alert-modal">
                    <div className="alert-modal_icon"></div>
                    <div className="alert-modal_message">
                        Changing the number of holes will require you to re-enter your scores.
                        <br/><br/>
                        You will be returned to the <br/>round setup screen.
                    </div>
                    <div className="alert-modal_actions">
                        <button className="btn fill blue">Ok</button>
                        <button className="btn outline blue" onClick={() => {
                            this.props.triggerAlertModal()
                        }}>Cancel
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    }
}


