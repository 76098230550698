import React, {Component, Fragment} from 'react';
export default class ScaledScores extends Component {

    render() {
        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.triggerAlertModal()
                }}>Close
                </button>
                <div className="alert-modal">
                  <p className="alert-modal_message">
                    This score received an 18-hole Score Differential based on the number
                    of holes played and an expected Score Differential for the holes not played.
                  </p>
                </div>
            </Fragment>
        );
    }
}


