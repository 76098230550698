import React, {Component, Fragment} from 'react';

export default class AlertChangeSetupTee extends Component {

    render() {
        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.triggerAlertModal()
                }}>Close
                </button>
                <div className="alert-modal">
                    <div className="alert-modal_icon"></div>
                    <div className="alert-modal_message">
                        Changing Tees may update the Adjusted Gross Score that you are about to post.
                    </div>
                    <div className="alert-modal_actions">
                        <button className="btn fill blue" onClick={() => {
                            this.props.onAnsware(true)
                        }}>Confirm
                        </button>
                        <button className="btn outline blue" onClick={() => {
                            this.props.onAnsware(false)
                        }}>Cancel
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    }
}


