import React, {Component, Fragment} from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import {connect} from "react-redux";

class CustomKeyboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            layoutName: "default",
        };
        //this.keyboardRef = React.createRef();
    }

    onChange = input => {
        if (this.props.type === "number") {
            if (parseInt(input) !== 0) {
                this.props.changeValue(input);
            } else {
                this.keyboardRef.setInput(null)
            }
        } else {
            this.props.changeValue(input);
        }
    };

    componentDidUpdate() {

        if (this.props.value !== this.keyboardRef.getInput) {
            this.keyboardRef.setInput(this.props.value)
        }
        if (this.props.focused && this.props.keyboard) {
            if(this.props.type === "number") {
                document.body.classList.add('keyboard--is--open--number')
            } else {
                document.body.classList.add('keyboard--is--open')
            }
            let target = document.getElementById(this.props.name);
            let scrollTop = document.body.scrollHeight;
            if (target) {
                scrollTop = target.getBoundingClientRect().top + window.scrollY ;
            }
            window.scrollTo(0,scrollTop - 100);
        }
        if (!this.props.focused && this.props.keyboard) {
            document.body.classList.remove('keyboard--is--open');
            document.body.classList.remove('keyboard--is--open--number');
        }
    }
    componentWillUnmount() {
        document.body.classList.remove('keyboard--is--open');
        document.body.classList.remove('keyboard--is--open--number');
    }

    onKeyPress = button => {
        if (button === "{shift}") this.handleSpecial('shift');
        if (button === "{special}") this.handleSpecial('special');
        if (button === "{special2}") this.handleSpecial('special2');
        if (button === "{abc}") this.handleSpecial('default');
        if (button === "{abcd}") this.handleSpecial('default-and-numeric');
        if (button === "{123}") this.handleSpecial('number-and-abc');
        if (button === "{hide}") this.props.hideKeyboard();
    };

    handleSpecial = (type) => {
        let layoutName = this.state.layoutName;

        this.setState({
            layoutName: type === "shift" ? (layoutName === "default" ? 'shift' : 'default') : type
        });
    };


    render() {
        return (
            <Fragment>
                {(this.props.focused && this.props.keyboard) &&
                <div className={'keyboard-exit'} onClick={() => this.props.hideKeyboard()}></div>}

                <Keyboard
                    keyboardRef={r => (this.keyboardRef = r)}
                    layoutName={
                        this.props.type === 'number' ? 'number' : (
                            this.props.type === 'number-and-abc' ?  (this.state.layoutName === 'default-and-numeric'? 'default-and-numeric' : 'number-and-abc')
                            :
                            (!this.props.value && this.state.layoutName === 'default' ? 'shift' : this.state.layoutName)
                        )
                    }
                    inputName={this.props.name}
                    layout={{
                        'default': [
                            `q w e r t y u i o p {bksp}`,
                            `a s d f g h j k l`,
                            `{shift} z x c v b n m ' . {shift}`,
                            `{special} {space} _ - {hide}`
                        ],
                        'shift': [
                            `Q W E R T Y U I O P {bksp}`,
                            `A S D F G H J K L`,
                            `{shift} Z X C V B N M ' . {shift}`,
                            `{special} {space} _ - {hide}`
                        ],
                        'number': [
                            '1 2 3 4 5 6 7 8 9 0 {bksp}',
                        ],
                        'number-and-abc': [
                            '1 2 3 4 5 6 7 8 9 0 {bksp}',
                            '{abcd} {space} {hide}'
                        ],
                        'default-and-numeric': [
                            '1 2 3 4 5 6 7 8 9 0 {bksp}',
                            `q w e r t y u i o p`,
                            `a s d f g h j k l`,
                            `z x c v b n m`,
                            `{123} {space} {hide}`
                        ],
                        'special': [
                            `1 2 3 4 5 6 7 8 9 0 {bksp}`,
                            `- / : ; ( ) € & @ "`,
                            `{special2} . , ? ! '`,
                            `{abc} {space} {hide}`
                        ],
                        'special2': [
                            `[ ] { } # % ^ * + = {bksp}`,
                            `_ \\ | ~ < > $ £ ¥ ·`,
                            `{special} . , ? ! '`,
                            `{abc} {space} {hide}`
                        ]
                    }}
                    buttonTheme={[
                        {
                            class: "active",
                            buttons: (this.state.layoutName === "shift" || !this.props.value) ? '{shift}' : 'asd'
                        },
                        {
                            class: "shift",
                            buttons: '{shift}'
                        },
                        {
                            class: "backspace",
                            buttons: '{bksp}'
                        },
                        {
                            class: "hide",
                            buttons: '{hide}'
                        },
                        {
                            class: "space",
                            buttons: '{space}'
                        }
                    ]}
                    display={{
                        '{bksp}': ' ',
                        '{shift}': ' ',
                        '{space}': ' ',
                        '{hide}': ' ',
                        '{special}': '123',
                        '{special2}': '#+=',
                        '{abc}': 'ABC',
                        '{abcd}': 'ABC',
                        '{123}': '123'
                    }}
                    setInput={this.props.value}
                    theme={(this.props.focused && this.props.keyboard) ? 'hg-theme-default' : 'hidden'}
                    onChange={input => {
                        this.onChange(input)
                    }}
                    onKeyPress={(button) => {
                        this.onKeyPress(button)
                    }}
                />
            </Fragment>

        );
    }
}

function mapStateToProps(state) {
    return {
        keyboard:  (state.kiosk.kiosk_setup && state.kiosk.kiosk_setup.show_keyboard)
    };
}

export default connect(mapStateToProps, null)(CustomKeyboard);
