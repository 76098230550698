import React, {Fragment, useState} from 'react';
import GolferService from "../../../../services/api/golfer";
import AlertsModal from "../../../Modals/AlertsModal";
import CustomKeyboard from "../../../shared/Keyboard";
import {isMobile} from "react-device-detect";
import {isIpad} from "../../../../checkIpad";

export default function LookupByGhin(props) {

    const [ghinNumber, setGhinNumber] = useState('');
    const [focused, setFocus] = useState(false);
    const [modalGhin, triggerGhinModal] = useState(false);
    const [modalError, triggerErrorModal] = useState(false);

    const onSubmit = (event) => {
        event.preventDefault();

        let reg = /^\d+$/;

        if (!reg.test(ghinNumber)) {
            triggerGhinModal(true);
            return;
        }
        GolferService.findGolferByGHIN(parseInt(ghinNumber))
            .then(res => {
                if (res && res.golfers.length > 0 && res.golfers.filter(golfer => golfer.status === "Active").length > 0) {
                    let golfer = res.golfers.filter(golfer => golfer.status === "Active")[0];
                    golfer.id = golfer.ghin;
                    props.changeLookupGolfer(golfer);
                    props.history.push(`../golfer`);
                } else {
                    triggerErrorModal(true);
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
    return (
        <Fragment>
            <form onSubmit={onSubmit}>
                <div className="lookup-tab__panel">
                    <div className="f-row">
                        <div className="f-col is-full">
                            <label htmlFor="ghin-number">Enter GHIN Number</label>
                            <div className="input-container">
                                <input type="text" readOnly={(isMobile || isIpad()) && props.keyboard}
                                       value={ghinNumber}
                                       onChange={e => setGhinNumber(e.target.value)}
                                       onFocus={() => setFocus(true)}
                                       autoComplete="off"
                                       id="ghin-number"
                                       name="ghin-number"/>
                                {ghinNumber && <span className="clear-input" onClick={() => {
                                    setGhinNumber('');
                                }}></span>}
                            </div>
                        </div>
                    </div>

                    <div className="f-row">
                        <div className="f-col is-auto centered">
                            <input className="btn fill red" type="submit" disabled={!ghinNumber} value="Continue"/>
                        </div>
                    </div>

                </div>

            </form>
            <AlertsModal open={modalGhin || modalError} closeModal={() => {
                triggerGhinModal(false);
                triggerErrorModal(false)
            }} type={modalGhin ? 'invalid-ghin' : 'no-golfer-ghin'}/>
            <CustomKeyboard changeValue={(value) => {
                setGhinNumber(value)
            }} hideKeyboard={() => setFocus(false)} type={'number'} name={'ghin-number'} value={ghinNumber}
                            focused={focused}/>

        </Fragment>

    );

}


