// reducers.js

import {connectRouter} from 'connected-react-router'
import shared from "./screens/shared/reducers";
import { reducer as formReducer } from 'redux-form';
import {golferReducer} from "./screens/shared/reducers/golfer";
import {lookupGolferReducer} from "./screens/shared/reducers/lookupGolfer";
import {kioskReducer} from "./screens/shared/reducers/kiosk";
import {keyboardReducer} from "./screens/shared/reducers/keyboard";
import {authReducer} from "./screens/Golfer/reducers"

export default (history) => {
    return{
        router: connectRouter(history),
        Golfer: golferReducer,
        lookupGolfer: lookupGolferReducer,
        kiosk: kioskReducer,
        keyboard: keyboardReducer,
        auth: authReducer,
        shared,
        form: formReducer
    }
}
