import React, {Component, Fragment} from 'react';
// import Select from 'react-select';
import CustomScrollbars from "../shared/CustomScrollbars";
import SearchBar from "../shared/SearchBar";
import * as searchBarActions from "../shared/actions/searchBar";
import {changeQuery} from './../shared/actions/searchBar';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import CourseService from "../../services/api/course";
import {Scrollbars} from "react-custom-scrollbars";

// do long running stuff

// const options = [
//     { value: 'new_jersey', label: 'New Jersey' },
//     { value: 'new_york', label: 'New York' },
//     { value: 'montana', label: 'Montana' }
//   ];

class FormCourseModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            loading: false,
            coursesList: [],
            errorType: null,
            focused: false,
        }
    }

    handleChange = (selectedOption) => {
        this.setState({selectedOption});
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.searchTerm !== this.props.searchTerm) {
            if (this.props.searchTerm) {
                this.setState({focused:false});
                this.searchCourse(this.props.searchTerm);
            } else {
                this.setState({coursesList: []})
            }

        }
    }

    searchCourse(name) {
        this.setState({loading: true, courseList: []});
        if (name && name.length > 2)
          CourseService.findCourse(name)
              .then((response) => {
                  if (response.courses && response.courses.length > 0) {
                      this.setState({coursesList: response.courses, loading: false, errorType: null})
                  } else {
                      this.setState({coursesList: [], loading: false, errorType: 'No course found.'})
                  }
              })
              .catch(error => {
                  console.log(error);
              })
          else
            this.setState({coursesList: [], loading: false, errorType: 'No course found.'})
          
    }

    renderCourse(course) {
        return (
            <div className="selection_row" key={course.CourseID} onClick={() => {
                this.props.onSelectCourse(course);
                this.props.closeModal();
            }}>
                <div className="selection_cell">
                    <span className="course-name">{course.FacilityName}</span>
                    <span className="course-location">{course.City}{ course.State || course.Country ? ', ' : ' '} {course.State ? course.State : course.Country }</span>
                </div>
                <div
                    className="selection_cell text-aligh-right">{course.FacilityName !== course.CourseName && course.CourseName}</div>
            </div>
        )
    }

    render() {
        let homeCourses = [];
        this.props.homeCourses.map(el => {
            if(homeCourses.filter(hc => hc.CourseID === el.CourseID).length === 0) homeCourses.push(el);
        });
        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.closeModal();
                }}>Close
                </button>
                <div className="form-modal full">

                    <div className="f-row">
                        <SearchBar type={"course"} focused={this.state.focused} focus={(type) => {this.setState({focused: type})}}/>
                        {/* <div className="f-col is-1-of-3">
                                <label htmlFor="state-or-country">State or Country</label>
                                <Select
                                    className='react-select-container'
                                    classNamePrefix="react-select"
                                    value={selectedOption}
                                    onChange={this.handleChange}
                                    options={options}
                                    isSearchable={false}
                                />
                            </div> */}
                    </div>
                    {!this.state.focused && this.state.coursesList.length === 0 && !this.state.loading && homeCourses.length > 0 && !this.props.searchTerm &&
                        <div className="f-row">

                            <div className="selection">

                                <div className="selection_body">

                                    <Scrollbars autoHeightMax={'60vh'}
                                                autoHeight
                                                autoHide={false}
                                                renderThumbVertical={props => <div {...props} style={{position:'relative', right:6, width: 12, borderRadius:10, backgroundColor:'#c7c8c9'}}/>}
                                    >

                                        {homeCourses.map(course => {
                                            return this.renderCourse(course);
                                        })}

                                    </Scrollbars>

                                </div>

                            </div>

                        </div>
                    }
                    {(this.state.loading && this.state.coursesList.length === 0) && <div className="loading"></div>}
                    {!this.state.loading && this.props.searchTerm && (!this.state.errorType ?
                            (this.state.coursesList.length > 0 &&
                                <div className="f-row">

                                    <div className="selection">

                                        <div className="selection_body">

                                            <Scrollbars autoHeightMax={'60vh'}
                                                        autoHeight
                                                        autoHide={false}
                                                        renderThumbVertical={props => <div {...props} style={{position:'relative', right:6, width: 12, borderRadius:10, backgroundColor:'#c7c8c9'}}/>}
                                            >

                                                {this.state.coursesList.map(course => {
                                                    return this.renderCourse(course);
                                                })}

                                            </Scrollbars>

                                        </div>

                                    </div>

                                </div>)
                            :
                            <div className="f-row">
                                <div className="selection">
                                    <div className="selection_body is-error">{this.state.errorType}</div>
                                </div>
                            </div>
                    )}

                </div>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        searchTerm: state.shared.searchTerm,
        kiosk: state.kiosk
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(searchBarActions, changeQuery, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FormCourseModal);
