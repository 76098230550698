import requestNewApiJSON from './../shared/requestNewApiJSON';
import * as Constants from "../../screens/Golfer/shared/constants";
var qs = require('qs');

function findGolferByLastName(last_name, first_name, club_id) {
    return requestNewApiJSON({
        url: `/clubs/${club_id}/golfers.json`,
        method: 'GET',
        params: first_name ? {
            per_page: 100,
            page: 1,
            club_id,
            full_name: first_name + ' ' + last_name,
        }
        :
        {
            per_page: 100,
            page: 1,
            club_id,
            last_name: last_name,
        }
    })
}

function findGolferByNameAndState(last_name, first_name, state, page) {
    const stateProp = state === 'USA' ? { country: state } : { state };
    return requestNewApiJSON({
        url: '/golfers/search.json',
        method: 'GET',
        params: {
            per_page: 20,
            page: page || 1,
            status: 'Active',
            first_name: first_name ? first_name + '%' : '',
            last_name,
            ...stateProp,
            sorting_criteria: 'last_name_first_name'
        }
    })
}

function findGolferLocalByGHIN(golfer_id,club_id) {
    return requestNewApiJSON({
        url: '/golfers/search.json',
        method: 'GET',
        params: {
            per_page: 20,
            page: 1,
            golfer_id,
            club_id
        }
    })
}


function findGolferByGHIN(golfer_id) {
    return requestNewApiJSON({
        url: '/golfers/search.json',
        method: 'GET',
        params: {
            per_page: 20,
            page: 1,
            golfer_id,
        }
    })
}

function findGolferByLocalNumber(  local_number, club_id) {
    return requestNewApiJSON({
        url: `/clubs/${club_id}/golfers.json`,
        method: 'GET',
        params: {
            per_page: 50,
            page: 1,
            club_id,
            local_number,
        }
    })
}

function getHandicapHistory(id, assoc, club, date_begin, date_end) {
    // let credentials = store.getState().kiosk.credentials;
    // return ghpRequestXML({
    //     url: 'golfermethods.asmx/HandicapHistory',
    //     method: 'POST',
    //     data: qs.stringify({
    //         username: credentials.legacy_user,
    //         password: credentials.legacy_pass,
    //         Assoc: assoc,
    //         Club: club,
    //         Service: 0,
    //         GHINNumber,
    //         DateBegin: dateStart,
    //         DateEnd: dateEnd,
    //         revCount: 20
    //     })
    // })
    return requestNewApiJSON({
        url: `/golfers/${id}/handicap_history`,
        method: "Get",
        params: {
            id,
            date_begin,
            date_end,
            rev_count: 0
        }
    })
}

function getCountriesAndStates(){
    return requestNewApiJSON({
        url: 'get_countries_and_states.json',
        method: 'GET',
        data: qs.stringify({
            all_world_countries_and_state: false
            })
    })
}

function getHomeCourses(ghin) {
    return requestNewApiJSON({
        url: `/golfers/${ghin}/facility_home_courses.json`,
        method: 'GET',
    })
}

function findAllGolfersFromClub(club_id, next_page) {
    return requestNewApiJSON({
        url: `/clubs/${club_id}/golfers.json`,
        method: 'GET',
        params: {
            per_page: Constants.OFFSET,
            page: next_page,
            sorting_criteria: 'last_name',
            order: 'ASC',
            club_id,
            status: 'Active',
        }
    })
}

const GolferService = {
    findGolferByLastName,
    findGolferByNameAndState,
    findGolferLocalByGHIN,
    findGolferByGHIN,
    findGolferByLocalNumber,
    getHandicapHistory,
    getCountriesAndStates,
    getHomeCourses,
    findAllGolfersFromClub
};

export default GolferService;
