import React, {Component, Fragment} from 'react';

export default class AlertValidGhin extends Component {

    render() {
        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.triggerAlertModal()
                }}>Close
                </button>
                <div className="alert-modal">
                    <div className="alert-modal_icon"></div>
                    <div className="alert-modal_message">Please enter a valid Local Number and try again</div>
                    <div className="alert-modal_actions">
                        <button className="btn fill blue" onClick={() => this.props.triggerAlertModal()}>Close</button>
                    </div>
                </div>
            </Fragment>
        );
    }
}


