import React, {Component, Fragment} from 'react';

export default class FormNrHolesModal extends Component {

    render() {
        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.closeModal()
                }}>Close
                </button>
                <div className="form-modal">

                    <div className="box-radio_container">

                        <input type="radio"
                               name="nr-of-holes"
                               value="18"
                               id="eighteen"
                               disabled={this.props.block18h}
                               onClick={() => {
                                   this.props.onSelectNrHoles(18)
                                   this.props.closeModal();
                               }}
                               defaultChecked={this.props.nr === 18}/>
                        <label htmlFor="eighteen">18 Holes</label>

                        <input type="radio"
                               name="nr-of-holes"
                               value="9"
                               id="nine"
                               onClick={() => {
                                       this.props.onSelectNrHoles(9);
                                       this.props.closeModal();
                               }}
                               defaultChecked={this.props.nr === 9}/>
                        <label htmlFor="nine">9 Holes</label>
                    { this.props.display17h &&
                        <>
                            <input type="radio"
                                    name="nr-of-holes"
                                    value="10-17"
                                    id="ten-seventeen"
                                    onClick={() => {
                                            this.props.onSelectNrHoles(17);
                                            this.props.closeModal();
                                    }}
                                    defaultChecked={this.props.nr === 17}/>
                            <label htmlFor="ten-seventeen">10-17 Holes</label>
                        </>
                    }

                    </div>

                </div>
            </Fragment>
        );
    }
}


