import React, {Component, Fragment} from 'react';

export default class AlertKioskIsOffline extends Component {

    render() {
        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.triggerAlertModal()
                }}>Close
                </button>
                <div className="alert-modal">
                    <div className="alert-modal_icon check"></div>
                    <div className="alert-modal_message">
                        Kiosk is currently offline. Your score will be automatically posted once kiosk has Internet
                        connection.
                    </div>
                    <div className="alert-modal_actions">
                        <button className="btn fill blue">Ok</button>
                    </div>
                </div>
            </Fragment>
        );
    }
}


