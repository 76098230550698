import React, {Component, Fragment} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import GolferSearch from "./Search";
import GolferResult from "./Result/Golfers";
import GolferProfile from "./Profile";
import Lookup from "./Lookup/";
import Score from "./Score";
import {changeKiosk} from "../shared/actions/kiosk";
import {checkTokenExpiration} from './shared/checkTocken';
import {changeGolfer} from "../shared/actions/golfer";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import KioskService from "../../services/api/kiosk"
import Header from "../shared/Header";
import Footer from "../shared/Footer";
import Cookies from 'universal-cookie';
import NotFound from "../shared/NotFound";

class Golfer extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            error: false
        }
    }
    getKiosk(updated_at){
        this.setState({loading:true});
        KioskService.findKiosk(this.props.match.params.club_name, updated_at)
            .then(res => {
                if(res && res.credentials) {

                    const cookies = new Cookies();

                    cookies.set('token', res.credentials.new_user_token, {path: '/', expires:null});
                    this.props.dispatch({
                        type: "USER_AUTHENTICATED",
                        payload: !!cookies.get("token")
                    });
                    // Remove NULL home_courses from each club
                    res.clubs = res.clubs.map(
                      (club) => {
                        if (club.home_courses)
                          club.home_courses = club.home_courses.filter( course => course ? true : false);
                        return club;
                      }
                    );
                    this.props.changeKiosk(
                        {
                            ...res,
                            ...{
                                kiosk_setup: {
                                    ...res.kiosk_setup,
                                    ...{
                                        default_course: {
                                            ...res.kiosk_setup.default_course,
                                            CourseID: res.kiosk_setup.default_course && res.kiosk_setup.default_course.id,
                                            FacilityName: res.kiosk_setup.default_course && res.kiosk_setup.default_course.facility_name,
                                            CourseName: res.kiosk_setup.default_course && res.kiosk_setup.default_course.name,

                                        }
                                    }
                                }
                            }}
                    );
                    this.setState({loading:false, error: false});
                } else {
                    this.setState({loading:false, error: false});
                }
            })
            .catch(err => {
                this.setState({loading:false, error: true})
            })
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated || checkTokenExpiration()) {
            this.getKiosk()
        } else {
            this.checkKiosk()
        }
    }

    checkKiosk(){
        if (this.props.kiosk && this.props.kiosk.kiosk_setup) {
            if(this.props.kiosk.kiosk_setup && this.props.kiosk.kiosk_setup.url !== this.props.match.params.club_name) {
                this.getKiosk();
            } else {
                this.getKiosk(this.props.kiosk.kiosk_setup.updated_at);
            }
        } else {
            this.getKiosk();
        }
    }

    render() {
        if (!Object.keys(this.props.kiosk).length || this.state.loading) {
            if(this.state.error){
                return (
                    <div className="details-container loading-container">
                        <div className="identity">
                            <div className="identity_logo"></div>
                            <p className="identity_sub">GOLF HANDICAP <br/>INFORMATION NETWORK</p>
                        </div>
                        <span>Something went wrong. Please check the kiosk url.</span>
                    </div>
                );
            } else {
                return(
                    <div className="details-container loading-container">
                        <div className="identity">
                            <div className="identity_logo"></div>
                            <p className="identity_sub">GOLF HANDICAP <br/>INFORMATION NETWORK</p>
                        </div>
                        <div className="loading"></div>
                    </div>
                )
            }
        } else {
            if(this.state.error){
                return (
                    <div className="details-container loading-container">
                        <div className="identity">
                            <div className="identity_logo"></div>
                            <p className="identity_sub">GOLF HANDICAP <br/>INFORMATION NETWORK</p>
                        </div>
                        <span>Something went wrong. Please check the kiosk url.</span>
                    </div>
                );
            } else {
                return (
                    <Fragment>
                        {Object.keys(this.props.kiosk).length && <Header/>}
                        <Switch>
                            <Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/search`}/>
                            <Route path={`${this.props.match.url}/search/`} component={GolferSearch}/>
                            <Route path={`${this.props.match.url}/result/`} render={() => <GolferResult {...this.props} />}/>
                            <Route path={`${this.props.match.url}/:id/profile`} component={GolferProfile}/>
                            <Route path={`${this.props.match.url}/:id/lookup`} component={Lookup}/>
                            <Route path={`${this.props.match.url}/:id/score/`} component={Score}/>
                            <Route component={NotFound}/>
                        </Switch>
                        {Object.keys(this.props.kiosk).length && <Footer/>}
                    </Fragment>
                );
            }

        }
    }


}

function mapStateToProps(state) {
    return {...state, ...{kiosk: state.kiosk, auth: state.auth}}
}


function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({changeKiosk, changeGolfer}, dispatch);
    return {
        dispatch,
        ...actions
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Golfer);
