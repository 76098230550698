import React, {useState, Fragment} from 'react';
import GolferService from "../../../services/api/golfer";
import AlertsModal from "../../Modals/AlertsModal";
import CustomKeyboard from "../../shared/Keyboard";
import {isMobile} from "react-device-detect";
import {isIpad} from "../../../checkIpad";
export default function SearchGuest(props) {

    const [ghinNumber, setGhinNumber] = useState('');
    const [focused, setFocus] = useState(false);
    const [modalError, triggerErrorModal] = useState(false);

    const onSubmit = (event) => {
        event.preventDefault();

        let reg = /^\d+$/;

        if (!reg.test(ghinNumber)) {
            triggerErrorModal('invalid-ghin');
            return;
        }
        GolferService.findGolferByGHIN(parseInt(ghinNumber))
            .then(res => {
                if (res && res.golfers.length > 0) {
                    if(res.golfers.filter(golfer => !golfer.is_under_13).length > 0) {
                        if (res&& res.golfers && res.golfers.filter(golfer => golfer.technology_provider === "GHIN").length > 0) {
                            if (res.golfers && res.golfers.filter(golfer => golfer.technology_provider === "GHIN")[0]) {
                                if (res.golfers.filter(golfer => golfer.technology_provider === "GHIN").filter(golfer => golfer.status === "Active").length === 0) {
                                    triggerErrorModal("inactive-golfer");
                                } else {
                                    let golfer = res.golfers.filter(golfer => golfer.technology_provider === "GHIN")[0];
                                    golfer.id = golfer.ghin;
                                    props.changeGolfer(golfer);
                                    props.history.push(`../${
                                        btoa(golfer.id)
                                            .replace(/[=]/g, '_')
                                            .replace(/[-]/g, '!')
                                            .replace(/[+]/g, '&')
                                    }/profile`);
                                }

                            }
                        } else {
                            triggerErrorModal("non-ghin");
                        }
                    } else {
                        triggerErrorModal('minor-golfer')
                    }
                } else {
                    triggerErrorModal('no-golfer')
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
    return (
        <Fragment>
            <form onSubmit={onSubmit}>
                <div className="lookup">
                    <div className="f-row">
                        <div className="f-col is-2-of-3">
                            <label htmlFor="last-name">Enter GHIN Number</label>
                            <div className="input-container">
                                <input type="text" readOnly={(isMobile || isIpad()) && props.keyboard}
                                       value={ghinNumber}
                                       onFocus={() => setFocus(true)}
                                       onChange={e => setGhinNumber(e.target.value)}
                                       autoComplete="off"
                                       id="ghin-number"
                                       name="ghin-number"/>
                                {ghinNumber && <span className="clear-input" onClick={() => {
                                    setGhinNumber('');
                                }}></span>}
                            </div>
                        </div>
                        <div className="f-col is-1-of-3">
                            <input className="btn fill red" type="submit" disabled={!ghinNumber} value="Continue"/>
                        </div>
                    </div>
                </div>
            </form>
            <AlertsModal open={modalError ? true : false}
                         closeModal={() => {
                             triggerErrorModal(false)
                         }}
                         type={modalError}/>
            <CustomKeyboard changeValue={(value) => {
                setGhinNumber(value)
            }} hideKeyboard={() => setFocus(false)} type={'number'} name={'ghin-number'} value={ghinNumber}
                            focused={focused}/>
        </Fragment>
    )

}
