import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
const cookies = new Cookies();

export const checkTokenExpiration = (() => {
    if (typeof cookies.get('token') !== "undefined") {
        let decoded = jwt_decode(cookies.get('token'));
        if (decoded.exp < parseInt(Date.now() / 1000)) {
            return true;
        }
        return false;
    }
    return true;
});
