import axios from 'axios'
import {triggerNetworkModal} from "../../screens/shared/actions";
import {store} from '../../store';
import Cookies from 'universal-cookie';
import {checkTokenExpiration} from "../../screens/Golfer/shared/checkTocken";
import KioskService from "../api/kiosk";
const cookies = new Cookies();
const { XMLParser, XMLValidator} = require("fast-xml-parser");

/*
 global window
 */
const getToken = () => {
    const state = store.getState();
    return KioskService.findKiosk(state.kiosk.kiosk_setup.url,'')
}


const client = (() => {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL + "/api/v1/"
    });
})();



client.interceptors.request.use((config) => {
    let originalRequest = config;
    let tokenIsExpired = checkTokenExpiration();
    if (tokenIsExpired && config.url.indexOf('kiosk') === -1) {
        return getToken().then((res) => {
            if(res) {
                const cookies = new Cookies();
                cookies.set('token', res.credentials.new_user_token, {path: '/', expires:null});
                originalRequest.headers['Authorization'] = 'Bearer ' + res.credentials.new_user_token;
                return Promise.resolve(originalRequest);
            } else {
                console.error('something is wrong')
            }

        })
          .catch(err => console.err('something si wrong ', err));
    } else {
        return config;
    }
}, (err) => {
    return Promise.reject(err);
});

const request = function (options) {
    const onSuccess = function (response) {
        const parser = new XMLParser();
        if(response){
            if (XMLValidator.validate(response.data)) {
                if (typeof response.data !== 'object') {
                    return parser.parse(response.data);
                } else {
                    return response.data;
                }
            } else {
                return Promise.reject("Invalid xml")
            }
        }
    };

    const onError = function (error) {
        return Promise.reject(error.response || error.message);
    };
    if (cookies.get('token')) {
        options.headers = {
            "Authorization": "Bearer " + cookies.get('token')
        };
    }
    options.headers = {
        ...options.headers,
        'Accept': 'text/html',
        'Content-Type': 'application/x-www-form-urlencoded'
    };
    return client(options)
        .then(onSuccess)
        .catch(onError);
};

client.interceptors.response.use((config) => {
    return config;
}, (err) => {
    if (err.message === "Network Error") {
        store.dispatch(triggerNetworkModal(true));
    }
    return Promise.reject(err);
});


export default request;
