import React, {Component, Fragment} from 'react';

export default class Adjustments extends Component {

    render() {
        let esr = null;
        let committee = null;
        if(this.props.data.length > 0){
            esr = this.props.data.filter( ad => ad.type === "esr");
            if( esr.length > 0){
                esr = esr[0]
            } else {
                esr = null;
            }
            committee = this.props.data.filter( ad => ad.type === "committee");
            if( committee.length > 0){
                committee = committee[0]
            } else {
                committee = null;
            }
        }

        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.triggerAlertModal()
                }}>Close
                </button>
                <div className="alert-modal">
                    {(esr && !committee) && <div className="alert-modal_message">
                        A {esr.display} Exceptional Score Reduction has been applied to this score differential.
                    </div>}

                    {(committee && !esr) &&  <div className="alert-modal_message">
                        A {committee.display} Committee Adjustment has been applied to this score differential.
                    </div>}

                    {(committee && esr) &&  <div className="alert-modal_message">
                        A {esr.display} Exceptional Score Reduction and a {committee.display} Committee Adjustment have been applied to this score differential.
                    </div>}

                    <div className="alert-modal_actions">
                        <button className="btn fill blue"
                                onClick={() => {
                                    this.props.triggerAlertModal()
                                }}>Ok</button>
                    </div>
                </div>
            </Fragment>
        );
    }
}


