import {map, filter, debounceTime} from 'rxjs/operators'

export function searchBarEpic(action$, store) {
    return  action$.pipe(
          filter(action => action.type === 'CHANGE_SEARCH_TERM_REQUEST'),
          debounceTime(1500),
          map(action => {
              return {
                  type: 'CHANGE_SEARCH_TERM',
                  payload: action.payload
              };
          })
    );
}
