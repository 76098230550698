import React, {Component, Fragment} from 'react';

export default class AdjustedGrossScore extends Component {

    render() {
        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.triggerAlertModal()
                }}>Close
                </button>
                <div className="alert-modal">
                    <div className="alert-modal_message">
                        Adjusted score determined by the maximum number of strokes you can post on a hole based on your Course Handicap.
                    </div>
                </div>
            </Fragment>
        );
    }
}


