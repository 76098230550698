import React, {Component, Fragment} from 'react';
import {Switch, Route} from 'react-router-dom';
import GolferService from "../../../../services/api/golfer";
import InitialForm from "./InitialForm";
import Table from "./Table"
import Review from './Review';
import {connect} from "react-redux";
import {reduxForm, getFormValues} from 'redux-form';
import {bindActionCreators} from "redux";
import moment from "moment/moment";
import {changeGolfer} from "../../../shared/actions/golfer";
import KisokService from "../../../../services/api/kiosk";
import NotFound from "../../../shared/NotFound";


class PostHoleByHole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Golfer: null,
            formModalIsOpen: false,
            loading: false,
        }
    }

    getGolferProfile() {
        this.setState({loading: true});
        let ghin = this.props.match.params.id.replace(/_/g, '=').replace(/!/g, '-').replace(/&/g, '+');
        ghin = parseInt(atob(ghin));
        GolferService.findGolferByGHIN(ghin)
            .then(res => {
                if (res && res.golfers && res.golfers) {
                    let golfer = res.golfers[0];
                    golfer.id = golfer.ghin;
                    golfer.affiliations = res.golfers.map(afiliation => {return afiliation.club_id});
                    let courses = [];
                    GolferService.getHomeCourses(ghin)
                        .then(res => {
                            res.facilities.map(facility => facility.home_courses.map(homeCourse => {courses.push(homeCourse)}));
                            golfer.homeCourses = courses;
                            this.props.changeGolfer(golfer);
                            this.setState({Golfer: golfer, loading: false});
                        })
                        .catch(err => {
                            console.log(err);
                        })

                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    componentDidMount() {
        this.handleInitialize();
        if (this.props.Golfer) {
            this.setState({
                Golfer: this.props.Golfer
            })
        } else {
            this.getGolferProfile();
        }
    }

    handleInitialize() {
        const initData = {
            "nrHoles": 18,
            "scoreType": 'Home',
            "date": moment().format('MMMM DD, YYYY'),
            "holes": {
                hole1: '',
                hole2: '',
                hole3: '',
                hole4: '',
                hole5: '',
                hole6: '',
                hole7: '',
                hole8: '',
                hole9: '',
                hole10: '',
                hole11: '',
                hole12: '',
                hole13: '',
                hole14: '',
                hole15: '',
                hole16: '',
                hole17: '',
                hole18: ''
            },
            "ags": {
                hole1: '',
                hole2: '',
                hole3: '',
                hole4: '',
                hole5: '',
                hole6: '',
                hole7: '',
                hole8: '',
                hole9: '',
                hole10: '',
                hole11: '',
                hole12: '',
                hole13: '',
                hole14: '',
                hole15: '',
                hole16: '',
                hole17: '',
                hole18: ''
            }
        };

        this.props.initialize(initData);
    }


    render() {
        let {Golfer} = this.state;
        return (
            <Fragment>
                {this.state.loading || !Golfer ?
                    <div className="details-container" style={{height:'100%'}}>
                        <div className="loading"></div>
                    </div>
                    :
                    <Switch>
                        <Route path={`${this.props.match.url}`} exact
                               render={(props) => <InitialForm {...props} Golfer={Golfer}/>}/>
                        <Route path={`${this.props.match.url}/post-front`} exact
                               render={(props) => <Table step="front" {...props} Golfer={Golfer}/>}/>
                        <Route path={`${this.props.match.url}/post-back`} exact
                               render={(props) => <Table step="back" {...props} Golfer={Golfer}/>}/>
                        <Route path={`${this.props.match.url}/post-review`} exact
                               render={(props) => <Review step="review" {...props} Golfer={Golfer}/>}/>
                        <Route component={NotFound}/>
                    </Switch>
                }
            </Fragment>
        );
    }
}

PostHoleByHole = reduxForm({
    // a unique name for the form
    form: 'hbh',
    destroyOnUnmount: true
})(PostHoleByHole);

function mapStateToProps(state) {
    return {
        formValues: getFormValues('hbh')(state),
        Golfer: state.Golfer,
        kiosk: state.kiosk
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({changeGolfer}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PostHoleByHole);
