import React, {Component, Fragment} from 'react';
import {Provider} from 'react-redux';
import {Switch, Route, Redirect} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import {PersistGate} from 'redux-persist/es/integration/react';

import Golfer from './screens/Golfer';
import  {store,history} from './store';
import Cookies from 'universal-cookie';
export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            persisted: false
        }
    }

    dispatchAuthenticationStatus() {
        const cookies = new Cookies();
        this.props.store.dispatch({
            type: "@@ghin/USER_AUTHENTICATED",
            payload: !!cookies.get("token")
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.persisted !== prevState.persisted) {
            this.dispatchAuthenticationStatus()
        }
    }
    render() {
        return (
            <Provider store={store}>
                <PersistGate onBeforeLift={() => this.setState({persisted: true})} persistor={this.props.persistor}>
                    <ConnectedRouter history={history}>
                        <Fragment>
                            <Switch>
                                <Redirect exact from="/:club_name" to="/kiosk/:club_name/golfer"/>
                                <Redirect exact from="/kiosk/:club_name/" to="/kiosk/:club_name/golfer"/>
                                <Route path="/kiosk/:club_name/golfer" component={Golfer}/>
                            </Switch>
                        </Fragment>
                    </ConnectedRouter>
                </PersistGate>
            </Provider>
        );
    }
}
