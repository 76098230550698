import requestNewApiXML from './../shared/requestNewApiXML';
import requestNewApiJSON from './../shared/requestNewApiJSON';
var qs = require('qs');

function getScores(ghinNumber) {
    return requestNewApiJSON ({
        url: `/golfers/${ghinNumber}/scores.json`,
        method: 'GET',
    })
}

function getRecentScores(ghinNumber) {
    return requestNewApiJSON ({
        url: 'scores.json',
        method: 'GET',
        params:{
            golfer_id: ghinNumber,
            statuses: "Validated",
            offset: 0,
            limit: 20
        }
    })
}

function postTotalScore(ghinNumber, courseId, teeSetId, teeSetSide, date, scoreType, nrHoles, gender, ags) {
    return requestNewApiJSON({
        url: 'scores/adjusted.json',
        method: 'POST',
        data: qs.stringify({
            golfer_id: ghinNumber,
            course_id: courseId,
            tee_set_id: teeSetId,
            tee_set_side: teeSetSide,
            played_at: date,
            score_type: scoreType,
            number_of_holes: nrHoles,
            gender: gender,
            adjusted_gross_score: ags
        })
    })
}

function postTotalScore9and9(ghinNumber, courseId, teeSetId, teeSetSide, date, scoreType, nrHoles, gender, ags,front9, back9) {
    return requestNewApiXML({
        url: 'scores/18h9and9.xml',
        method: 'POST',
        data: qs.stringify({
            golfer_id: ghinNumber,
            course_id: courseId,
            tee_set_id: teeSetId,
            tee_set_side: teeSetSide,
            played_at: date,
            score_type: scoreType,
            number_of_holes: nrHoles,
            gender: gender,
            front9_adjusted: front9,
            back9_adjusted: back9
        })
    })
}

function postHoleByHole(data) {
    return requestNewApiJSON ({
        url: 'scores/hbh.json',
        method: 'POST',
        data: data
    })
}


function confirmScore(ghinNumber, scoreId) {
    return requestNewApiXML({
        url: `scores/${scoreId}/confirm.xml`,
        method: 'POST',
        data: qs.stringify({
            golfer_id: ghinNumber,

        })
    })
}

function getChildScores(scoreId, params) {
    return requestNewApiJSON({
        url: `/scores/${scoreId}/get_child_scores.json`,
        params
    })
}


const ScoreService = {
    getScores,
    getRecentScores,
    postTotalScore,
    postTotalScore9and9,
    postHoleByHole,
    confirmScore,
    getChildScores
};

export default ScoreService;
