export function authReducer(state = {}, action) {
	switch(action.type) {
		case 'USER_AUTHENTICATED':
			return {
				...state,
				isAuthenticated: action.payload
			};
		default:
			return state;
	}
}
