import React, {Component, Fragment} from 'react';
import Modal from "react-modal";
import FormScoreTypeModal from "../../../Modals/FormScoreTypeModal";
import FormDateModal from "../../../Modals/FormDateModal";
import FormNrHolesModal from "../../../Modals/FormNrHolesModal";
import FormTeesModal from "../../../Modals/FormTeesModal";
import FormCourseModal from "../../../Modals/FormCourseModal";

export default class Score extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            scoreModal: false,
            dateModal: false,
            nrHolesModal: false,
            teesModal: false,
            courseModal: false,
        }
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    closeModals() {
        this.setState({
            scoreModal: false,
            dateModal: false,
            nrHolesModal: false,
            teesModal: false,
            courseModal: false
        });
    }

    render() {
        return (
            <Fragment>

                <Modal
                    isOpen={this.state.scoreModal || this.state.dateModal || this.state.nrHolesModal || this.state.teesModal || this.state.courseModal}
                    contentLabel="Alert Valid Ghin"
                    portalClassName="modal"
                    overlayClassName="modal__overlay  keyboard-included"
                    className="modal__content"
                    bodyOpenClassName="modal--is-open"
                    htmlOpenClassName="prevent-scroll"
                    shouldCloseOnOverlayClick={true}
                    shouldFocusAfterRender={false}
                    onRequestClose={() => {this.closeModals()}}
                >
                    {this.state.scoreModal &&
                    <FormScoreTypeModal type={this.props.scoreType}
                                        nrHoles={this.props.nrHoles}
                                        closeModal={() => {
                                            this.closeModals()
                                        }}
                                        onSelectScore={(type) => {
                                            this.closeModals();
                                            this.props.onSelectScore(type)
                                        }}/>
                    }
                    {this.state.dateModal &&
                    <FormDateModal date={this.props.date}
                                   closeModal={() => {
                                       this.closeModals()
                                   }}
                                   onSelectDate={(date) => {
                                       this.closeModals();
                                       this.props.onSelectDate(date)
                                   }}/>
                    }
                    {this.state.nrHolesModal &&
                    <FormNrHolesModal nr={this.props.nrHoles}
                                      tournament={this.props.scoreType}
                                      block18h={this.props.block18h}
                                      display17h={this.props.display17h}
                                      closeModal={() => {
                                          this.closeModals()
                                      }}
                                      onSelectNrHoles={(nr) => {
                                          this.closeModals();
                                          this.props.onSelectNrHoles(nr)
                                      }}/>
                    }
                    {this.state.teesModal &&
                    <FormTeesModal nrHoles={this.props.nrHoles}
                                   teesList={!this.props.loading && this.props.teesList.filter(tee=> this.props.nrHoles === 18 ? (tee.HolesNumber === this.props.nrHoles) : true)}
                                   closeModal={() => {
                                       this.closeModals()
                                   }}
                                   onSelectTee={(tee, type) => {
                                       this.closeModals();
                                       this.props.onSelectTee(tee, type)
                                   }}/>
                    }
                    {this.state.courseModal &&
                    <FormCourseModal closeModal={() => {
                                            this.closeModals()
                                        }}
                                     homeCourses={this.props.homeCourses && this.props.homeCourses.map(course => {return {
                                         CourseID: course.id,
                                         FacilityName: course.facility_name,
                                         CourseName: course.name,
                                         City: course.city,
                                         Country: course.state,
                                         default_tee_male: course.default_tee_male,
                                         default_tee_female: course.default_tee_female
                                     }})}
                                     onSelectCourse={(course) => {
                                         this.closeModals();
                                         this.props.onSelectCourse(course)
                                     }}/>
                    }

                </Modal>

                <div className="details_column">
                    <div className="details_cell course" onClick={() => {
                        if (this.props.review) {
                            this.props.showChangeModal();
                        } else {
                            this.setState({courseModal: true})
                        }

                    }}>
                        <div className="details_label">Course</div>
                        {this.props.course ?
                            <Fragment>
                                <div className={`details_data ${this.props.inactive ? '' : 'pointer'}`}>{this.props.course && this.props.course.FacilityName}</div>
                                <div className="details_data-sub">{(this.props.course && this.props.course.FacilityName !== this.props.course.CourseName) && this.props.course.CourseName}</div>
                            </Fragment>
                            :
                            <div className="details_data pointer">Select Course</div>
                        }
                    </div>
                    <div className="details_cell" onClick={() => {
                        if (this.props.review) {
                            this.props.showChangeModal();
                        } else {
                            this.setState({nrHolesModal: true})
                        }
                    }}>
                        <div className="details_label">Number Of Holes Played</div>
                        <div
                            className={`details_data ${this.props.inactive ? '' : 'pointer'}`}>
                                {this.props.nrHoles === 17 ? '10-17' : this.props.nrHoles} Holes
                        </div>
                    </div>
                    <div className="details_cell" onClick={() => {
                            this.setState({scoreModal: true})
                    }}>
                        <div className="details_label">Score Type</div>
                        <div
                            className={`details_data ${this.props.inactive ? '' : 'pointer'}`}>{this.props.scoreType}</div>
                    </div>
                    <div className={`details_cell ${this.props.teeError ? 'disabled' : ''}`} onClick={() => {
                            this.setState({teesModal: true})
                    }}>
                    <div className={`details_label`} >Tees</div>
                        {this.props.teeError ?
                            <div className={`details_data`}>
                                {this.props.teeError}
                            </div>
                            :
                                <div className={`details_data ${this.props.inactive ? '' : 'pointer'}`}>
                                    {this.props.tee ? this.props.tee.TeeSetRatingName.toString().replace(/&amp;/g, '&') : 'Select Tees'}
                                    {this.props.tee && this.props.nrHoles === 9 ? " (" + this.props.teeType.charAt(0).toUpperCase() + this.props.teeType.slice(1) + ")" : ''}
                                </div>
                        }
                    </div>
                    <div className="details_cell" onClick={() => {
                            this.setState({dateModal: true})
                    }}>
                        <div className="details_label">Date Played</div>
                        <div className={`details_data ${this.props.inactive ? '' : 'pointer'}`}>{this.props.date}</div>
                    </div>
                </div>

            </Fragment>
        );
    }
}


