// Returns true if the param date matches
// current date (year, month, day only)
// Returns false otherwise
export const sameAsCurrentDate = (date) => {
  let currentDate = new Date();

  date.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  return currentDate.getTime() == date.getTime();
};