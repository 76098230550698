import axios from 'axios';
import {triggerNetworkModal} from "../../screens/shared/actions";
import {store} from '../../store';
import Cookies from 'universal-cookie';
import KioskService from "../api/kiosk";
import {checkTokenExpiration} from '../../screens/Golfer/shared/checkTocken';
const { XMLParser } = require("fast-xml-parser");

const cookies = new Cookies();
/*
 global window
 */

const getToken = () => {
    const state = store.getState();
    return KioskService.findKiosk(state.kiosk.kiosk_setup.url,'')
}


const client = (() => {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL + "/api/v1/"
    });
})();


client.interceptors.request.use((config) => {
    let originalRequest = config;
    let tokenIsExpired = checkTokenExpiration();
    if (tokenIsExpired) {
        return getToken().then((res) => {
            if(res) {
                const cookies = new Cookies();
                cookies.set('token', res.credentials.new_user_token, {path: '/', expires:null});
                originalRequest.headers['Authorization'] = 'Bearer ' + res.credentials.new_user_token;
                return Promise.resolve(originalRequest);
            } else {
                console.error('something is wrong')
            }
        });
    } else {
        return config;
    }
}, (err) => {
    return Promise.reject(err);
});

const request = function (options, store) {
    const onSuccess = function (response) {
      if (typeof response.data !== 'object') {
          const parser = new XMLParser();
          const result = parser.parse(response.data);
          return result ? result : response.data;
      } else {
        if (typeof response.headers['total-count'] !== 'undefined' && typeof response.headers['per-page'] !== 'undefined') {
          return {
            ...response.data,
            'per-page': response.headers['per-page'],
            'total-count': response.headers['total-count'],
            'total-pages': response.headers['total-pages'],
            'current-page': response.headers['current-page']
          }
        } else {
          return response.data;
        }
      }
    };

    const onError = function (error) {
        console.log(error);
        return Promise.reject(error.response || error.message);
    };
    options.headers = {
        ...options.headers,
        'Accept': 'text/html',
        'Content-Type': 'application/json'
    };
    if (cookies.get('token')) {
        options.headers = {
            "Authorization": "Bearer " + cookies.get('token')
        };
    }
    return client(options)
        .then(onSuccess)
        .catch(onError);
};
client.interceptors.response.use((config) => {
    return config;
}, (err) => {
    if (err.message === "Network Error") {

        store.dispatch(triggerNetworkModal(true));
    }
    return Promise.reject(err);
});

export default request;
