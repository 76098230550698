import React, {Component, Fragment} from 'react';

export default class ContentTerms extends Component {

    constructor(props){
        super(props);
        this.state = {loading: true}
    }

    render() {
        return (
            <Fragment>
                <button className="modal__close" onClick={() => {this.props.closeModal()}}>Close</button>
                <div className="content-modal normalized">
                    {this.state.loading && <div className="details-container">
                        <div className="loading gray"></div>
                    </div>}
                    <iframe style={this.state.loading ? {display: 'none'} : null}  onLoad={()=>{this.setState({loading: false})}} width="100%" height="600px" src="https://www.usga.org/content/usga/home-page/Handicap-ghin/ghin-kiosk-terms-of-service.html"/>
                </div>
            </Fragment>
        );
    }
}


