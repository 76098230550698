import React, {Component, Fragment} from 'react';

export default class CourseHandicap extends Component {

    render() {
        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.triggerAlertModal()
                }}>Close
                </button>
                <div className="alert-modal">
                    <div className="alert-modal_message">
                        <span className={'title'}>Your Course Handicap™</span>
                        Indicates the number of strokes you need to play to Par for the selected tees and to adjust hole scores for handicap purposes.
                    </div>
                </div>
            </Fragment>
        );
    }
}
