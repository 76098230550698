import React, { Component, Fragment } from "react";

class NotFound extends Component {
    render() {
        const endIndex = this.props.match.url.indexOf("/golfer") + 7;
        const homeUrl = this.props.match.url.slice(0, endIndex);
        return (
            <Fragment>
                <nav></nav>
                <br />
                <br />
                <p style={{ textAlign: "center" }}>
                    <p>Page Not Found!</p>
                    <div className="btn_container">
                        <button
                            className="btn lnk"
                            onClick={() => this.props.history.push(homeUrl + '/search/name')}
                            role="link"
                        >
                            Home
                        </button>
                    </div>
                </p>
                <br />
                <br />
            </Fragment>
        );
    }
}

export default NotFound;