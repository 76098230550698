import React, {Component, Fragment} from 'react';

export default class FormScoreTypeModal extends Component {

    render() {

        return (
            <Fragment>
                <button className="modal__close" onClick={() => {
                    this.props.closeModal()
                }}>Close
                </button>
                <div className="form-modal">

                    <div className="box-radio_container">
                        <input type="radio"
                               name="score-type"
                               value="home"
                               id="home"
                               onClick={() => {
                                   this.props.onSelectScore("Home");
                                   this.props.closeModal();
                               }}
                               checked={this.props.type === "Home"}/>
                        <label htmlFor="home">Home</label>

                        <input type="radio"
                               name="score-type"
                               value="away"
                               id="away"
                               onClick={() => {
                                   this.props.onSelectScore("Away");
                                   this.props.closeModal();
                               }}
                               checked={this.props.type === "Away"}/>
                        <label htmlFor="away">Away</label>

                        <Fragment>
                            <input type="radio"
                                   name="score-type"
                                   value="tournament"
                                   id="tournament"
                                   onClick={() => {
                                       this.props.onSelectScore("Competition");
                                       this.props.closeModal();
                                   }}
                                   checked={this.props.type === "Competition"}/>
                            <label htmlFor="tournament">Competition</label>
                        </Fragment>

                    </div>

                </div>
            </Fragment>
        );
    }
}


