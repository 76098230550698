import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {changeNetworkStatus, triggerNetworkModal} from "./actions";
import {bindActionCreators} from "redux";
import {Detector} from "react-detect-offline";
import {withRouter} from "react-router-dom";
import AlertsModal from '../Modals/AlertsModal';
import {clearGolfer} from "./actions/golfer";


class Navigation extends Component {

    render() {
        return (
            <Fragment>
                <nav>
                    <div className="container wide">
                        <div className="row h-centered">
                            <div className="nav_left">
                                {this.props.backButton &&
                                <button className="nav-link left"
                                        onClick={() => {
                                            if (this.props.backValue === "Round setup") {
                                                this.props.backAction();
                                            } else {
                                                this.props.goToProfile ?
                                                    (

                                                        this.props.history.push(`/kiosk/${this.props.match.url.match(/\/kiosk\/(.*)\/golfer\//)[1]}/golfer/${
                                                            btoa(this.props.Golfer.ghin)
                                                                .replace(/[=]/g, '_')
                                                                .replace(/[-]/g, '!')
                                                                .replace(/[+]/g, '&')
                                                        }/${this.props.lookupSearch ? 'lookup' : 'profile'}`)
                                                    )
                                                    :
                                                    this.props.history.goBack()
                                            }
                                        }}>
                                    {this.props.reviewBackButton ? 'Score entry' : this.props.backValue ? this.props.backValue : 'Home'}
                                </button>
                                }
                                {this.props.postAnotherScore &&
                                <button className="nav-link left"
                                        onClick={(e) => {
                                            this.props.history.push(`/kiosk/${this.props.match.url.match(/\/kiosk\/(.*)\/golfer/)[1]}/golfer/${
                                                btoa(this.props.Golfer.ghin)
                                                    .replace(/[=]/g, '_')
                                                    .replace(/[-]/g, '!')
                                                    .replace(/[+]/g, '&')
                                                }/profile`);
                                        }}>Home</button>}
                            </div>
                            <div className="nav_center">
                                <Detector render={({online}) => (!online &&
                                    <div className="offline-mode">Kiosk is in offline mode</div>)}
                                          onChange={(online) => {
                                              this.props.changeNetworkStatus(online)
                                          }}/>
                            </div>
                            <div className="nav_right">
                                {this.props.reviewButton &&
                                <button className="btn fill red right"
                                        disabled={!this.props.enableNext || this.props.disabledReviewButton}
                                        onClick={(e) => {
                                            this.props.onValidateFields();
                                        }}>Review Round</button>}
                                {this.props.postBackButton &&
                                <button className="btn fill red right"
                                        disabled={!this.props.enableNext}
                                        onClick={(e) => {
                                            this.props.onValidateFields();
                                        }}>Back 9</button>}
                                {this.props.exitKiosk &&  <button className="btn fill red right"
                                                                  onClick={(e) => {
                                                                      this.props.clearGolfer(null);
                                                                      this.props.history.push(`/kiosk/${this.props.match.url.match(/\/kiosk\/(.*)\/golfer/)[1]}/`);
                                                                  }}>Exit kiosk</button>}
                            </div>
                        </div>
                    </div>
                </nav>
                <AlertsModal open={this.props.appConfig.networkModal} type='network-error' closeModal={() => {
                    this.props.triggerNetworkModal(false)
                }}/>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        appConfig: state.shared.appConfig,
        Golfer: state.Golfer,
        kiosk: state.kiosk
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({changeNetworkStatus, triggerNetworkModal, clearGolfer}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation));
