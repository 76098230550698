import React, {Component, Fragment} from 'react';
import CustomScrollbars from '../../../shared/CustomScrollbars';
import Modal from "react-modal";
import FormCourseModal from "../../../Modals/FormCourseModal";
import CourseService from "../../../../services/api/course";
import {connect} from "react-redux";
import moment from "moment";

class CourseHandicap extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            holesType: 18,
            course: {},
            searchCourseModal: false,
            courseHandicaps: [],
            loading: false,
            block18h: false
        }
    }


    componentDidMount() {
        this._isMounted = true;
        if (this.props.Golfer) {
            this.selectCourse(this.props.kiosk.kiosk_setup.default_course);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    changeHoleType(type) {
        this.setState({
            holesType: type
        })
    }

    selectCourse(course) {
        if (this._isMounted) {
            this.setState({
                course: course,
                loading: true,
                searchCourseModal: false
            }, () => {
                this.getCourseHandicaps();
            });

        }
    }

    getCourseHandicaps() {
        CourseService.getCourseHandicaps( this.props.Golfer.id, this.state.course.CourseID, moment(new Date()).format("YYYY-MM-DD"))
            .then(response => {
                if (response && response.tee_sets && response.tee_sets.length > 0) {
                    let isShortCourse = response.tee_sets.filter(tee => tee.ratings.filter(rating => rating.slope_rating).length !== 0).length === 0;
                    if (this._isMounted) {
                        let courseHandicaps  = response.tee_sets.filter(tee => tee.gender=== this.props.Golfer.gender)
                        .filter(tee => tee.ratings.filter(rating => rating.slope_rating).length !== 0);
                        let verifyHoles = courseHandicaps.filter(tee => tee.ratings.length !== 3);

                        if (courseHandicaps.length !== 0) {
                            this.setState({
                                courseHandicaps: courseHandicaps,
                                loading: false,
                                holesType: verifyHoles.length === courseHandicaps.length ? 9 : 18,
                                block18h: verifyHoles.length === courseHandicaps.length
                            });
                        } else {
                            this.setState({courseHandicaps: [], loading: false})
                        }
                    }
                } else {
                    this.setState({courseHandicaps: [], loading: false})
                }
            })
            .catch(error => {
                console.log(error);
            })

    }

    renderTees(tee, key) {
        if (this.state.holesType === 18 ) {
            const all18 =  tee.ratings.filter(tee=> tee.tee_set_side === "All 18")[0];
            if (all18 && tee.ratings[1] && tee.ratings[2]) return (<tr className="table_row" key={this.props.Golfer.id + tee.name + key}>
                <td className="table_cell tees">{tee.name}</td>
                <td className="table_cell rating-slope"> {parseFloat(all18['course_rating']).toFixed(1)} / {all18['slope_rating']} / {all18['par']} </td>
                <td className="table_cell course-handicap"> {all18['course_handicap_display']}</td>
            </tr>);
        } else {
            const front = tee.ratings.filter(tee=> tee.tee_set_side === "F9")[0];
            const back = tee.ratings.filter(tee=> tee.tee_set_side === "B9")[0];
            return (<Fragment key={this.props.Golfer.id + tee.name + key}>
                {front && <tr className="table_row" key={this.props.Golfer.id + tee.name + key + 'front'}>
                    <td className="table_cell tees">{tee.name} (FRONT)</td>
                    <td className="table_cell rating-slope"> {parseFloat(front['course_rating']).toFixed(1)} / {front['slope_rating']} / {front['par']}  </td>
                    <td className="table_cell course-handicap">{front['course_handicap_display']}</td>
                </tr>}
                {back && <tr className="table_row" key={this.props.Golfer.id + tee.name + key + 'back'}>
                    <td className="table_cell tees">{tee.name} (BACK)</td>
                    <td className="table_cell rating-slope">{parseFloat(back['course_rating']).toFixed(1)} / {back['slope_rating']} / {back['par']}  </td>
                    <td className="table_cell course-handicap">{back['course_handicap_display']}</td>
                </tr>}
            </Fragment>);
        }
    }

    render() {
        if (!this.props.Golfer) return null;
        let home_courses = [];
        this.props.kiosk.clubs.map(club => club.home_courses.map(course => {home_courses.push(course)}));
        return (
            <Fragment>

                {this.state.loading ?
                    <div className="details-container">
                        <div className="loading"></div>
                    </div>

                    :

                    <div className="data-table_container grow">

                        <div className="data-table_pre">
                            <div className="pre-left" onClick={() => {
                                this.setState({searchCourseModal: true})
                            }}>
                                <h3 className="pre-title icon search">{this.state.course.FacilityName}</h3>
                                <h3 className="pre-title">{this.state.course.FacilityName !== this.state.course.CourseName && this.state.course.CourseName}</h3>
                            </div>
                            <div className="pre-right">
                                <div className="toggle-radio_container">
                                    <input type="radio" id="eighteen-holes" disabled={this.state.block18h} onChange={() => {
                                        this.changeHoleType(18)
                                    }} value="eighteen-holes" name="holes" checked={this.state.holesType === 18}/>
                                    <label htmlFor="eighteen-holes">18 Holes</label>
                                    <input type="radio" id="nine-holes" onChange={() => {
                                        this.changeHoleType(9)
                                    }} value="nine-holes" name="holes" checked={this.state.holesType === 9}/>
                                    <label htmlFor="nine-holes">9 Holes</label>
                                </div>
                            </div>
                        </div>

                        {this.state.courseHandicaps.length > 0 && <div className="table_head course-handicap">
                            <div className="table_row">
                                <div className="table_cell tees">TEES</div>
                                <div className="table_cell rating-slope">C.R. / SLOPE / Par</div>
                                <div className="table_cell course-handicap">COURSE HANDICAP</div>
                            </div>
                        </div>}

                        <CustomScrollbars>

                            {this.state.courseHandicaps.length > 0 ? <table className="data-table course-handicap">
                                <tbody>

                                <Fragment>
                                    {this.state.courseHandicaps.sort((a, b) => ((a.ratings.length > 0 && a.ratings[0]['course_rating']) < (b.ratings.length > 0 &&b.ratings[0]['course_rating'])) ? 1 : -1).map((tee, key) => {
                                        return this.renderTees(tee, key);
                                    })}
                                </Fragment>

                                </tbody>
                            </table> :
                                <p className={'center'}>No tee available</p>
                            }

                        </CustomScrollbars>
                    </div>
                }
                <Modal
                    isOpen={this.state.searchCourseModal}
                    onRequestClose={() => {
                        this.setState({searchCourseModal: false})
                    }}
                    contentLabel="Alert Valid Ghin"
                    portalClassName="modal"
                    overlayClassName="modal__overlay"
                    className="modal__content"
                    bodyOpenClassName="modal--is-open"
                    htmlOpenClassName="prevent-scroll"
                    shouldCloseOnOverlayClick={true}
                    shouldFocusAfterRender={false}
                >
                    <FormCourseModal closeModal={() => {
                                            this.setState({searchCourseModal: false})
                                        }}
                                     onSelectCourse={(course) => {
                                            this.selectCourse(course)
                                        }}
                                     homeCourses={home_courses &&  home_courses.map(course => {return {
                                         CourseID: course.id,
                                         FacilityName: course.facility_name,
                                         CourseName: course.name,
                                         City: course.city,
                                         Country: course.state,
                                         default_tee_male: course.default_tee_male,
                                         default_tee_female: course.default_tee_female
                                     }})}
                    />
                </Modal>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        kiosk: state.kiosk
    };
}


export default connect(mapStateToProps, null)(CourseHandicap);
