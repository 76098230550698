import React, {Component, Fragment} from 'react';
import Navigation from "../../../shared/Navigation";
import AlertsModal from '../../../Modals/AlertsModal';
import Modal from "react-modal";
import Filters from "../shared/Filters";
import moment from "moment";
import CourseService from "../../../../services/api/course";
import GolferHead from "../../shared/GolferHead";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ScoreService from "../../../../services/api/score";
import CustomKeyboard from "../../../shared/Keyboard";
import {isMobile} from "react-device-detect";
import {isIpad} from "../../../../checkIpad";
import { sameAsCurrentDate } from '../../../../helpers/date-helper';
import SameDayModal from '../../../Modals/SameDayModal';
import {selectTeeType} from '../../../shared/CourseHelper'
const { XMLParser } = require("fast-xml-parser");

class PostTotal extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            Golfer: null,
            scoreModal: false,
            dateModal: false,
            nrHolesModal: false,
            teesModal: false,
            courseModal: false,
            scoreType: 'Home',
            nrHoles: 18,
            tee: null,
            teeType: null,
            teeError: null,
            teesList: [],
            date: moment().format('MMMM DD, YYYY'),
            course: {},
            season: {},
            loading: true,
            step: '',
            back9: '',
            front9: '',
            total9: '',
            infoModalIsOpen: false,
            errorModal: false,
            confirmModal: false,
            confirmModalMessage: null,
            focused: false,
            focusedInput: '',
            error:null
        }
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.kiosk) {
            if (this.props.location.state && this.props.location.state.data) {
                this.selectCourse(this.props.location.state.data.course);
            } else {
                this.selectCourse(this.props.kiosk.kiosk_setup.default_course);
            }
        }
        document.addEventListener("keydown", this.handleKeyDown.bind(this));
    }



    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown.bind(this));
        this._isMounted = false;
    }


    selectDate(date) {

      if (!sameAsCurrentDate(date)) {
        this.setState({sameDayModalOpen: true})
      }

      this.setState({
          date: moment(date).format('MMMM DD, YYYY')
      });
    }

    selectTee(tee, type) {
        this.setState({
            teeType: type,
            tee: tee
        })
    }

    selectCourse(course) {
        if (this._isMounted) {
            if(this.props.Golfer.homeCourses && this.props.Golfer.homeCourses.length && this.props.Golfer.homeCourses.filter(c => parseInt(course.CourseID) === parseInt(c.course_id)).length > 0) {
                this.selectScore("Home");
            } else {
                this.selectScore("Away");
            }
            this.setState({
                course: course,
                loading: true,
            });
        }
        this.getCourseDetails(course.CourseID);
    }

    getCourseDetails(courseID) {
        CourseService.getDetails(courseID)
            .then(response => {
                if (response && response.Courses.TeeSets && (response.Courses.TeeSets.TeeSet || response.Courses.TeeSets.TeeSet.length > 0)) {
                    let responseTees = Array.isArray(response.Courses.TeeSets.TeeSet) ? response.Courses.TeeSets.TeeSet : [response.Courses.TeeSets.TeeSet];
                    let initialTeesList  = responseTees.filter(tee => {
                        if(tee.Gender.charAt(0) === this.props.Golfer.gender) {
                            return tee;
                        } else {
                            return null;
                        }
                    });

                    let teesList = initialTeesList.filter(tee => {
                        if (Array.isArray(tee.Ratings.Rating)) {
                            return tee.Ratings.Rating.some(rating => rating.SlopeRating !== undefined);
                        } else {
                            return tee.Ratings.Rating && tee.Ratings.Rating.SlopeRating !== undefined;
                        }
                    });

                    if (teesList.length > 0) {
                        if (this._isMounted) {

                            let tee;

                            // check if the selected course is the kiosk_setup default_course and then select the default_Tee from there...
                            if (this.state.course.CourseID === parseInt(this.props.kiosk.kiosk_setup.default_course.CourseID)) {
                                tee = teesList.filter(tee =>
                                    parseInt(tee.TeeSetRatingId) === parseInt(this.props.kiosk.kiosk_setup.default_course[this.props.Golfer.gender === 'M' ? 'default_tee_male': 'default_tee_female'].id)
                                )[0];
                            }
                            // check if the selected course is in home_courses then just select the default_tee from there...
                            let checkHomeCourse = this.props.kiosk.clubs.filter(club => club.club_number === parseInt(this.props.Golfer.club_id))[0] && this.props.kiosk.clubs.filter(club => club.club_number === parseInt(this.props.Golfer.club))[0]?.home_courses.filter( course => parseInt(course.id) === this.state.course.CourseID)[0];
                            if (checkHomeCourse) {
                                tee = teesList.filter(tee => parseInt(tee.TeeSetRatingId) === parseInt(checkHomeCourse[this.props.Golfer.gender === 'M' ? 'default_tee_male': 'default_tee_female'].id))[0];
                            }

                            // check if the selected course is from home_courses then just select the default_tee from there...
                            if (this.state.course[this.props.Golfer.gender === 'M' ? 'default_tee_male': 'default_tee_female'] && this.state.course[this.props.Golfer.gender === 'M' ? 'default_tee_male': 'default_tee_female'].id !== "") {
                                tee = teesList.filter(tee =>
                                    tee.TeeSetRatingId === parseInt(this.state.course[this.props.Golfer.gender === 'M' ? 'default_tee_male': 'default_tee_female'].id)
                                )[0];
                            }

                            if (this.props.location.state && this.props.location.state.data) {
                                tee = teesList.filter(tee => tee.TeeSetRatingId === this.props.location.state.data.tee.TeeSetRatingId)[0];
                            }
                            
                            if(tee) {                          
                                this.selectTee(tee, selectTeeType(tee.Ratings.Rating))
                            } else {
                                this.setState({
                                    teeType: null,
                                    tee: null
                                })
                            }

                            let teesList9 =  teesList.filter(tee => tee.HolesNumber === 9);
                            this.setState({
                                teesList: teesList,
                                block18h: teesList9.length === teesList.length,
                                nrHoles: teesList9.length === teesList.length ? 9 : this.state.nrHoles,
                                teeError: null,
                                season: response.Courses.Season,
                                loading: false
                            })

                        }
                    } else {
                        if(initialTeesList.length !== 0) {
                            this.setState({
                                errorModal: 'short-course',
                                course: null,
                                teesList: [],
                                tee: null,
                                teeError: 'No tee available',
                                season: null,
                                loading: false
                            })
                        } else {
                            this.setState({
                                teesList: [],
                                tee: null,
                                teeError: 'No tee available',
                                errorModal: 'no-tees',
                                loading: false
                            })
                        }

                    }
                } else {
                    this.setState({
                        teesList: [],
                        tee: null,
                        teeError: 'No tee available',
                        errorModal: 'no-tees',
                        loading: false
                    })
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
    
    selectScore(type) {
        this.setState({scoreType: type});
    }

    selectNrHoles(nr) {
        this.setState({nrHoles: nr});
    }

    openModal() {
        this.setState({infoModalIsOpen: true});
    }

    closeModal() {
        this.setState({infoModalIsOpen: false, errorModal: false});
    }

    checkValue(e) {
        let key = e.target ? e.target.value : e;
        let name = e.target ? e.target.name : this.state.focusedInput;
        let reg = /^\d+$/;
        let length = key.length;
        if ((reg.test(key) || !key) && parseInt(key) !== 0) {
            if (name === 'total-score') {
                if (length <= 3) {
                    this.setState({[name]: key});
                    if (length === 3) {
                        if (e.target) {
                            e.target.blur()
                        }
                        this.setState({focused: false, focusedInput: ''})
                    }
                }
            } else {
                if (length <= 2) {
                    this.setState({[name]: key});
                    if (length === 2) {
                        if (e.target) {
                            e.target.blur();
                        }
                        this.setState({focused: false, focusedInput: ''})

                        if (name === "front9") {
                            this.refs['back'].focus();
                        }
                    }
                }
            }
        }
    }

    submit() {

        let date = moment(this.state.date, 'MMMM DD, YYYY').format('MM/DD').split('/');
        let start = moment(date + ' 2000', "MM/DD YYYY").isBefore(moment(this.state.season.SeasonStartDate + ' 2000', "MM/DD YYYY"));
        let end = moment(date + ' 2000', "MM/DD YYYY").isAfter(moment(this.state.season.SeasonEndDate + ' 2000', "MM/DD YYYY"));

        if(this.state.teeError){
            return;
        }

        if (!this.state.season.IsAllYear) {
            if (start || end) {
                this.setState({errorModal: 'alert-season'});
                return;
            }
        }

        this.setState({loading: true});

        let playedAt = moment(this.state.date, 'MMMM DD, YYYY').format('YYYY-MM-DD');
        let teeType = 'All18';
        if (this.state.nrHoles === 9) {
            switch (this.state.teeType) {
                case 'front':
                    teeType = 'F9';
                    break;
                case 'back':
                    teeType = 'B9';
                    break;
                default:
                    break;
            }
        }

        if((this.state.nrHoles === 18 && this.state['total-score']) || (this.state.nrHoles === 9 && this.state.total9)) {
            ScoreService.postTotalScore(this.props.Golfer.id, this.state.course.CourseID, this.state.tee.TeeSetRatingId, teeType, playedAt, this.state.scoreType === "Competition" ? "T" : this.state.scoreType.charAt(0), this.state.nrHoles, this.props.Golfer.gender, this.state.nrHoles === 9 ? this.state.total9 : this.state['total-score'])
                .then(res => {
                    const message = res.score.validation_message_display
                    if (res.score.status === "UnderReview" && message) {
                        this.setState({confirmModal: true, confirmModalMessage: message, loading: false, scoreId: res.score.id});
                    } else {
                        this.props.history.push('./recent-score');
                    }
                })
                .catch(err => {
                    if (err.data.errors && err.data.errors.played_at) {
                        this.setState({
                            error: err.data.errors.played_at[0],
                            loading: false
                        })
                    } else if (err.data.errors && err.data.errors.adjusted_gross_score) {
                        this.setState({
                            error: "Adjusted gross score " + err.data.errors.adjusted_gross_score[0] + ". " + "Adjusted gross score " + err.data.errors.adjusted_gross_score[1] + ".",
                            loading: false
                        })
                    }
                    else if(err.data.errors && err.data.errors.hi){
                        this.setState({
                            error: err.data.errors.hi[0],
                            loading: false
                        })
                    }
                    else if (err.data.errors && err.data.errors.score) {
                        this.setState({
                            error: 'You have exceeded the number of scores that can be posted for this day. Please ensure the correct date played has been selected when posting scores.',
                            loading: false
                        });
                    }
                    console.log(err);
                })
        } else {
            ScoreService.postTotalScore9and9(this.props.Golfer.id, this.state.course.CourseID, this.state.tee.TeeSetRatingId, teeType, playedAt, this.state.scoreType === "Competition" ? "T" : this.state.scoreType.charAt(0), this.state.nrHoles, this.props.Golfer.gender, this.state['total-score'], parseInt(this.state.front9), parseInt(this.state.back9))
                .then(res => {
                    const message = res.Score["validation-message-display"]
                    if (res.Score.status === "UnderReview" && message) {
                            this.setState({confirmModal: true, confirmModalMessage: message, loading: false, scoreId: res.Score.id});
                    } else {
                        this.props.history.push('./recent-score');
                    }
                })
                .catch(err => {
                    const parser = new XMLParser();
                    let result = parser.parse(err.data)

                    let errors = [];
                    if (result.Errors) {
                        const resErrors = result.Errors;
                        Object.keys(resErrors).forEach(error => {
                            if (error === 'front9-adjusted' || error === 'back9-adjusted') {
                                const errM =
                                    error.split("-")[0][0].toUpperCase() + error.split("-")[0].slice(1);
                                const errorMrg = `${errM} score ${resErrors[error][error]}. `
                                errors.push(errorMrg) ;
                            } else {
                                errors.push(resErrors[error][error]);
                            }
                        })
                        this.setState({
                            error: errors,
                            loading: false
                        })
                    }
                    console.log(err);
                })
        }


    }

    handleKeyDown(e){
        let self = this;
        if(this._isMounted){
            if (e.keyCode === 13 && !(self.state.nrHoles === 9 ? !self.state.total9 : !((self.state.front9 && self.state.back9) || self.state['total-score']))) {
                self.submit();
            }
        }
    }

    confirmScore() {
        this.setState({loading: true, confirmModal: false});
        ScoreService.confirmScore(this.props.Golfer.id, this.state.scoreId)
            .then(res => {
                this.props.history.push('./recent-score');
            })
            .catch(err => {
                console.log(err);
            })
    }

    render() {
        if (!this.props.Golfer) return null;
        let {Golfer} = this.props;
        let home_courses = [];
        this.props.kiosk.clubs.map(club => club.home_courses.map(course => {home_courses.push(course)}));
        return (
            <Fragment>
                <Navigation backButton={true}
                            reviewButton={this.props.subPath === "post" && (this.state.nrHoles === 9 || this.props.step === "back")}
                            postBackButton={this.props.subPath === "post" && this.props.step === "front" && this.state.nrHoles === 18}/>

                <section className="top-padding small">

                    <div className="container wide">

                        <GolferHead Golfer={Golfer}/>

                        {this.state.loading ?
                            <div className="details-container">
                                <div className="loading"></div>
                            </div>
                            : <div className="details-container">
                                <Filters loading={this.state.loading}
                                         date={this.state.date}
                                         nrHoles={this.state.nrHoles}
                                         block18h={this.state.block18h}
                                         homeCourses={home_courses}
                                         course={this.state.course}
                                         scoreType={this.state.scoreType}
                                         teeType={this.state.teeType}
                                         tee={this.state.tee}
                                         teeError={this.state.teeError}
                                         teesList={this.state.teesList}
                                         onSelectDate={(date) => this.selectDate(date)}
                                         onSelectCourse={(course) => this.selectCourse(course)}
                                         onSelectScore={(type) => this.selectScore(type)}
                                         onSelectTee={(tee, type) => this.selectTee(tee, type)}
                                         onSelectNrHoles={(nrHoles) => {
                                             this.selectNrHoles(nrHoles)
                                         }}
                                />
                                <div className="details_column">

                                    <div className={`esc-score_container ${this.state.nrHoles === 9 ? 'center' : ''}`}>
                                    <span className="esc-score">
                                        Adjusted Gross Score
                                        <button onClick={() => this.openModal()}
                                                className="info-modal-trigger">i</button>
                                    </span>
                                    </div>

                                    <div className="post-total-score-container">
                                        {this.state.nrHoles === 9 ?
                                            <div className="score-cell">
                                                <label htmlFor="total9">Total Score <span>*</span></label>
                                                <input type="text" readOnly={(isMobile || isIpad()) && this.props.keyboard}
                                                       onFocus={() => {
                                                           this.setState({focused: true, focusedInput: 'total9'})
                                                       }}
                                                       autoComplete="off"
                                                       value={this.state.total9}
                                                       onChange={(e) => {
                                                           this.checkValue(e)
                                                       }}
                                                       name="total9"
                                                       id="total9"
                                                       className={`score-input total ${(this.state.focusedInput === 'total9' && this.state.focused) ? 'focused' : ''}`}/>
                                            </div>
                                            :
                                            <Fragment>
                                                <div className="score-cell">
                                                    <label htmlFor="front9">Front 9</label>
                                                    <input type="text" readOnly={(isMobile || isIpad()) && this.props.keyboard}
                                                           autoComplete="off"
                                                           onFocus={() => {
                                                               this.setState({focused: true, focusedInput: 'front9'})
                                                           }}
                                                           disabled={this.state['total-score']}
                                                           value={this.state.front9}
                                                           onChange={(e) => {
                                                               this.checkValue(e)
                                                           }}
                                                           name="front9"
                                                           id="front9"
                                                           className={`score-input total ${(this.state.focusedInput === 'front9' && this.state.focused) ? 'focused' : ''}`}/>
                                                </div>
                                                <div className="score-cell">
                                                    <label htmlFor="back9">Back 9</label>
                                                    <input type="text" readOnly={(isMobile || isIpad()) && this.props.keyboard}
                                                           ref="back"
                                                           autoComplete="off"
                                                           onFocus={() => {
                                                               this.setState({focused: true, focusedInput: 'back9'})
                                                           }}
                                                           disabled={this.state['total-score']}
                                                           value={this.state.back9}
                                                           onChange={(e) => {
                                                               this.checkValue(e)
                                                           }}
                                                           name="back9"
                                                           id="back9"
                                                           className={`score-input total ${(this.state.focusedInput === 'back9' && this.state.focused) ? 'focused' : ''}`}/>
                                                </div>
                                                <div className="score-cell">
                                                    <label htmlFor="total-score">Total Score <span>*</span></label>
                                                    <input type="text" readOnly={(isMobile || isIpad()) && this.props.keyboard}
                                                           autoComplete="off"
                                                           onFocus={() => {
                                                               this.setState({
                                                                   focused: true,
                                                                   focusedInput: 'total-score'
                                                               })
                                                           }}
                                                           disabled={this.state.back9 || this.state.front9}
                                                           value={
                                                               this.state.nrHoles === 18 && (
                                                                   (this.state.back9 || this.state.front9) ?
                                                                       (this.state.back9 ? parseInt(this.state.back9) : 0) + (this.state.front9 ? parseInt(this.state.front9) : 0)
                                                                       :
                                                                       this.state['total-score'] ?
                                                                           this.state['total-score']
                                                                           :
                                                                           ''
                                                               )
                                                           }
                                                           onChange={(e) => {
                                                               this.checkValue(e)
                                                           }}
                                                           name="total-score"
                                                           id="total-score"
                                                           className={`score-input total ${(this.state.focusedInput === 'total-score' && this.state.focused) ? 'focused' : ''}`}/>
                                                </div>
                                            </Fragment>
                                        }
                                    </div>

                                    <button className="btn fill red full"
                                            disabled={(this.state.teeError || this.state.nrHoles === 9 ? !this.state.total9 : !((this.state.front9 && this.state.back9) || this.state['total-score'])) || !this.state.tee}
                                            onClick={() => {
                                                this.submit()
                                            }}>Post Score
                                    </button>

                                    <AlertsModal type={'api-error'} open={this.state.error ? true : false} errorMessage={this.state.error} closeModal={() => this.setState({error: null})} />
                                    <AlertsModal type={this.state.infoModalIsOpen ? 'adjusted' : this.state.errorModal}
                                                 open={this.state.infoModalIsOpen || (this.state.errorModal ? true : false)}
                                                 closeModal={() => {
                                                     this.closeModal()
                                                 }}/>
                                    <AlertsModal 
                                        answare={(value) => { value ? this.confirmScore() : this.setState({confirmModal: false}) }} 
                                        type={'alert-under-review-score'} 
                                        open={this.state.confirmModal}
                                        message={this.state.confirmModalMessage}
                                        closeModal={() => {this.setState({confirmModal: false})
                                    }}/>
                                    <Modal
                                      isOpen={this.state.sameDayModalOpen}
                                      onRequestClose={() => {
                                        this.setState({sameDayModalOpen: false})
                                      }}
                                      contentLabel="Alert Valid Ghin"
                                      portalClassName="modal"
                                      overlayClassName="modal__overlay"
                                      className="modal__content"
                                      bodyOpenClassName="modal--is-open"
                                      htmlOpenClassName="prevent-scroll"
                                      shouldCloseOnOverlayClick={true}
                                      shouldFocusAfterRender={false}>
                                      <SameDayModal
                                        closeModal={() => this.setState({sameDayModalOpen: false})}
                                      />
                                    </Modal>

                                </div>
                            </div>}

                    </div>

                </section>
                <CustomKeyboard changeValue={(value) => {
                    this.checkValue(value)
                }}
                                hideKeyboard={() => this.setState({focused: false})}
                                type={'number'}
                                name={this.state.focusedInput}
                                value={this.state[this.state.focusedInput]}
                                focused={this.state.focused}/>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        Golfer: state.Golfer,
        kiosk: state.kiosk,
        keyboard:  (state.kiosk.kiosk_setup && state.kiosk.kiosk_setup.show_keyboard)
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PostTotal);
