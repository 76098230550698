import React, {Component} from 'react';

export default class GolferHead extends Component {

    constructor(){
        super();
        this.state = {
            showTooltip: false
        }
    }

    render() {
        if (!this.props.Golfer) return null;
        let {Golfer} = this.props;
        return (

            <div className="golfer-profile_head">

                <div className="golfer-profile_identity">
                    <h2 className="golfer-profile_name">{Golfer.player_name}</h2>
                </div>

                <div className="golfer-profile_stats">
                    <div className="golfer-profile_handicap-container">
                        <div className="golfer-profile_profile_handicap-index">
                            {Golfer.hi_display}
                            {
                                (Golfer.message_club_authorized ?
                                    <div className="tooltip" >
                                        <button onMouseEnter={()=>this.setState({showTooltip: true})} onMouseLeave={()=>this.setState({showTooltip: false})} className="info-modal-trigger">!</button>
                                        <div className={`tooltip-content ${this.state.showTooltip ? 'show' : ''}`}>
                                            {Golfer.message_club_authorized}
                                        </div>
                                    </div>
                                    :
                                    (Golfer.hard_cap === "true" || Golfer.soft_cap === "true") ?
                                        <div className="tooltip" >
                                            <button onMouseEnter={()=>this.setState({showTooltip: true})} onMouseLeave={()=>this.setState({showTooltip: false})} className="info-modal-trigger">!</button>
                                            <div className={`tooltip-content ${this.state.showTooltip ? 'show' : ''}`}>
                                                {(Golfer.hard_cap === "true" && Golfer.soft_cap === "true") && "A Hard Cap has been applied to this Handicap Index, which restricts upward movement to 5.0 strokes over the Low Handicap Index (in effect when the score was posted) after the application of the Soft Cap."}
                                                {(Golfer.hard_cap === "false" && Golfer.soft_cap === "true") && "A Soft Cap has been applied to this Handicap Index, which suppresses upward movement by 50% after a 3.0 stroke increase over the Low Handicap Index (in effect when the score was posted) has been reached."}
                                            </div>
                                        </div>
                                        :
                                    "")
                            }
                        </div>
                        <div className="golfer-profile_handicap-label">
                            Handicap <br/> Index <sup>&reg;</sup>
                        </div>
                    </div>
                    <div className="golfer-profile_trend-container">
                        <span className="golfer-profile_trend-label">Low H.I.:</span>
                        <span
                            className="golfer-profile_trend-index">
                            {Golfer.low_hi_display}
                            </span>
                    </div>
                </div>
            </div>


        );
    }
}


