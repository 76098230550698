import React, {Component, Fragment} from 'react';

export default class SameDayModal extends Component {



    render() {
        return (
            <Fragment>
                <button
                  className="modal__close"
                  onClick={() => {
                    this.props.closeModal()
                  }}>
                  Close
                </button>
                <div className="alert-modal">
                    <div className="alert-modal_icon"></div>

                    <div className="alert-modal_message">
                      Please post scores on the day of play. Doing so will ensure an up-to-date Handicap Index.
                    </div>

                    <div className="alert-modal_actions">
                        <button className="btn fill blue" onClick={() => this.props.closeModal()}>OK</button>
                    </div>
                </div>
            </Fragment>
        );
    }
}


