import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";

export const firebaseConfig = {
  apiKey: "AIzaSyBxgTOAWxiud0HuaE5tN-5NTlzFnrtyz-I",
  authDomain: "ghin-mobile-app.firebaseapp.com",
  databaseURL: "https://ghin-mobile-app.firebaseio.com",
  projectId: "ghin-mobile-app",
  storageBucket: "ghin-mobile-app.appspot.com",
  messagingSenderId: "884417644529",
  appId: "1:884417644529:web:a8984ee8b1908ea2f72650",
  measurementId: "G-PEX3R9BCTJ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(app);

export function setEvent(
  association_name,
  club_name,
  event_name,
  tag_key,
  tag_value
) {
  setUserProperties(firebaseAnalytics, {
    OS: "KIOSK",
    AssociationName: association_name,
    ClubName: club_name,
  });

  tag_key == "Logo_Display"
    ? logEvent(firebaseAnalytics, event_name, {
        Logo_Display: tag_value,
      })
    : logEvent(firebaseAnalytics, event_name, {
        Display: tag_value,
      });
}
