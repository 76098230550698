
export const selectTeeType = (ratings) => {
  let teeType = 'front'
  if (ratings) {
    const has_front = ratings.filter(rating => rating.RatingType === 'Front').length > 0;
    const has_back = ratings.filter(rating => rating.RatingType === 'Back').length > 0;
    
    if (!has_front && has_back) 
      teeType = 'back';
  }
  return teeType;
}
