import React, {Component, Fragment} from 'react';
import AlertsModal from '../../../Modals/AlertsModal';
import GolferHead from "../../shared/GolferHead";
import Navigation from "../../../shared/Navigation";
import {getFormValues, reduxForm} from "redux-form";
import {Redirect} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import CustomKeyboard from '../../../shared/Keyboard';
import {isMobile} from "react-device-detect";
import {isIpad} from "../../../../checkIpad";

class Table extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clearModal: false,
            validateModal: false,
            error: false,
            confimrClearModal: false,
            goToSetup: false,
            target: null,
            focused: false,
            focusedInput: null,
            enableNext: false
        };
    }

    checkValue(e, target) {
        let value = e.target ? e.target.value : e;
        let name = e.target ? e.target.name : this.state.focusedInput;
        let reg = /^\d+$/;
        let holes = this.props.formValues && JSON.parse(JSON.stringify(this.props.formValues.holes));
        let ags = this.props.formValues && this.props.formValues.ags;
        let nextTarget = null;
        let tee_set_side = this.props.formValues.nrHoles === 18 ? 'All18' : (this.props.formValues.teeType === 'front' ? 'F9' : 'B9')
        let index = tee_set_side === "B9" ? 11 : 2
        /// add values
        if (!isNaN(parseInt(value)) && parseInt(value) !== 0) {
            if (parseInt(value) < 20) {
                holes[name] = value;
                if (parseInt(value) !== 0) ags[name] = Math.min(value, this.props.formValues.agsMax[target - index]['maximum-score']);
                if(!ags[name]) {ags[name]=''}
            }
        } else {
            holes[name] = '';
            ags[name] = '';
        }

        // focus the next empty target
        if(holes['hole' + target]) {
            nextTarget = Object.keys(holes).find(hole => !holes[hole] && hole.slice(4) > target);
            if (nextTarget) {
                nextTarget = parseInt(nextTarget.slice(4));
            } else {
                nextTarget = 20;
            }
        } else {
            nextTarget = target;
        }

        if ((reg.test(value) || !value) && parseInt(value) !== 0 && (isNaN(parseInt(value)) || parseInt(value) < 20)) {
            if (parseInt(value) !== 1 && !isNaN(parseInt(value))  && target) {
                if (!this.props.formValues.holes['hole' + nextTarget]) {
                    switch (nextTarget) {
                        case 10:
                            this.refs[target-1].blur();
                            this.setState({focused: false, focusedInput: null, target: null});
                            break;
                        case 19:
                            this.refs[target-1].blur();
                            this.setState({focused: false, focusedInput: null, target: null});
                            break;
                        case 20:
                            this.refs[target - 1].blur();
                            this.setState({focused: false, focusedInput: null, target: null});
                            break;
                        default:
                            this.refs[nextTarget]?.focus();
                            break;
                    }
                } else {
                    if(!isNaN(parseInt(value))){
                        this.refs[target - 1].blur();
                        this.setState({focused: false, focusedInput: null, target: null});
                    }
                }
            }
        }

        // change values
        this.props.change("holes", holes);
        this.props.change("ags", ags);
        this.checkHoles(holes);
    }

    triggerAlert(type) {
        this.setState({clearModal: type, goToSetup: false, validateModal: false});
    }

    // clearInput(field) {
    //     let holes = this.props.formValues && this.props.formValues.holes;
    //     let ags = this.props.formValues && this.props.formValues.ags;
    //     holes[field] = '';
    //     ags[field] = '';
    //     this.props.change("holes", holes);
    //     this.props.change("ags", ags);
    // }

    clearAll(setup) {
        let holes = {
            hole1: '',
            hole2: '',
            hole3: '',
            hole4: '',
            hole5: '',
            hole6: '',
            hole7: '',
            hole8: '',
            hole9: '',
            hole10: '',
            hole11: '',
            hole12: '',
            hole13: '',
            hole14: '',
            hole15: '',
            hole16: '',
            hole17: '',
            hole18: ''
        };
        let ags = {
            hole1: '',
            hole2: '',
            hole3: '',
            hole4: '',
            hole5: '',
            hole6: '',
            hole7: '',
            hole8: '',
            hole9: '',
            hole10: '',
            hole11: '',
            hole12: '',
            hole13: '',
            hole14: '',
            hole15: '',
            hole16: '',
            hole17: '',
            hole18: ''
        };
        this.props.change('holes', holes);
        this.props.change('ags', ags);
        this.setState({
            clearModal: false,
            error: false
        });
        if (setup) {
            this.props.history.push('../hole-by-hole');
        } else {
            if (this.props.step === "back" && this.props.formValues.nrHoles === 18) this.props.history.goBack()
        }
    }

    checkAtLeastOneFilledSide(holes, hasHandicapIndex) {
        const holesValues = Object.values(holes);
        const holesFilled = holesValues.filter((value) => value !== "").length;
        if (holesFilled === 18) 
            return true;
      
        const frontFilled = holesValues.slice(0, 9).filter((value) => value !== "");
        const backFilled = holesValues.slice(9, 18).filter((value) => value !== "");
      
        if (hasHandicapIndex)
          return frontFilled.length === 9 || backFilled.length === 9;
        else {
          return (frontFilled.length === 9 || backFilled.length === 9) && holesFilled === 9;
        }
      }

    checkFilledSide(holes, side){
        for (let i = (side === "front" ? 1 : 10); i <= (side === "front" ? 9 : 18); i++) {
            if (holes['hole' + i] === '') {
                return false
            }
        }
        return true
    }

    checkHoles(holes) {
        let checked = true;
        const nrHoles = this.props.formValues.nrHoles;
        const useScaling = this.props.Golfer?.use_scaling;

        if (nrHoles == 9) {
            checked = this.checkFilledSide(holes, this.props.step )
        }
        
        this.setState({enableNext: checked});
    }

    validateFields() {
        let checked = this.validateSide()
        const nrHoles = this.props.formValues.nrHoles;

        if (checked) {
            this.setState({error: false});
            this.props.history.push(this.props.step === "front" ? (nrHoles === 18 ? './post-back' : './post-review') : './post-review');
        } else {
            this.setState({error: this.props.step, focused: false, validateModal: true})
        }
    }
    
    validateSide() {
      let checked = true;
      const holes = this.props.formValues.holes;
      const nrHoles = this.props.formValues.nrHoles;
      const useScaling = this.props.Golfer?.use_scaling;
      const hasHandicapIndex = this.props.Golfer?.handicap_index != 'NH' && this.props.Golfer?.handicap_index != 'WD'

      if (useScaling && nrHoles == 18) {
        // no validattion for front side
        if (this.props.step === "back") {
          checked = this.checkAtLeastOneFilledSide(holes, hasHandicapIndex)
        }
      }
      else {
        checked = this.checkFilledSide(holes, this.props.step )
      }
      
      return checked
    }


    returnTotal(array, what, from, to) {
        let total = 0;
        for (let i = from; i <= to; i++) {
            total += what === 'hole' ? (array[what + i] ? parseInt(array[what + i]) : 0) : array[i][what];
        }
        return total;
    }

    goBack() {
        let exists = false;
        Object.keys(this.props.formValues.holes).map(hole => {
            if (this.props.formValues.holes[hole]) {
                exists = true;
            }
            return null;
        });
        if (exists) {
            this.setState({clearModal: true, goToSetup: true})
        } else {
            this.props.history.push('../hole-by-hole');
        }
    }

    generateCells(side, type) {
        let values = this.props.formValues;
        let a = [];
        if (side === 'front') {
            a = [1, 2, 3, 4, 5, 6, 7, 8, 9];
        } else {
            a = [10, 11, 12, 13, 14, 15, 16, 17, 18];
        }

        return a.map(i => {
            if (type) {
                if (type === 'yards') return <div key={i} className="hbh_cell">{values.tee.Holes.Hole[i - 1].Length}</div>;
                if (type === 'par') return <div key={i} className="hbh_cell">{values.tee.Holes.Hole[i - 1].Par}</div>;
                if (type === 'stroke') return <div key={i} className="hbh_cell">{values.tee.Holes.Hole[i-1].Allocation}</div>;
                if (type === 'hole') return (
                    <div key={i} className="hbh_cell">
                        <input type="text" readOnly={(isMobile || isIpad()) && this.props.keyboard}
                               name={`hole${i}`}
                               value={values && values.holes['hole' + i]}
                               ref={`${i}`}
                               onFocus={() => {
                                   this.setState({focused: true, focusedInput: 'hole' + i, target: i + 1});
                                   // this.clearInput('hole' + i)
                               }}
                               onChange={(e) => {
                                   this.checkValue(e, i + 1)
                               }}
                               autoComplete="off"
                               className={`hbh-score-input ${(this.state.focused && this.state.focusedInput === 'hole' + i) ? 'focused' : ''} ${this.state.error === 'front' && !values.holes['hole' + i] ? 'is-danger' : ''} `}/>
                    </div>);
                if (type === 'ags') return <div key={i} className="hbh_cell">{values.ags['hole' + i]}</div>;
                return null;
            } else {
                return <div key={i} className="hbh_cell">{i}</div>;
            }
        });


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( this.props.step && (prevProps.step !== this.props.step)){
            let self = this;
            this.checkHoles(this.props.formValues.holes);
            if(self.props.step === "front"){
                setTimeout(function () {
                    self.refs[1].focus();
                }, 50);
            } else {
                setTimeout(function () {
                    self.refs[10].focus();
                }, 50);
            }
        }
    }

    componentDidMount() {
        let self = this;
        if(this.props.formValues && this.props.formValues.holes) {
            this.checkHoles(this.props.formValues.holes);
        }
        if(this.props.formValues.tee) {
            if(self.props.step === "front"){
                setTimeout(function () {
                    self.refs[1].focus();
                }, 50);
            } else {
                setTimeout(function () {
                    self.refs[10].focus();
                }, 50);
            }
        }

    }

    render() {

        if (!this.props.Golfer) return null;
        let {Golfer} = this.props;
        let values = this.props.formValues;
        const hasHandicapIndex = Golfer?.handicap_index != 'NH' && Golfer?.handicap_index != 'WD' 

        if (!values.tee) {
            return (<Redirect to="../hole-by-hole"/>);
        }
        return (
            <Fragment>
                <Navigation backButton={true}
                            backAction={() => this.goBack()}
                            backValue={(this.props.step === "back" && values.nrHoles === 18) ? "Front 9" : "Round setup"}
                            onValidateFields={() => {
                                this.validateFields()
                            }}
                            reviewButton={values && (values.nrHoles === 9 || this.props.step === "back")}
                            disabledReviewButton={!this.validateSide()}
                            enableNext={this.state.enableNext}
                            postBackButton={values && this.props.step === "front" && values.nrHoles === 18}/>

                <section className="top-padding small">

                    <div className="container wide">

                        <GolferHead Golfer={Golfer}/>
                        <AlertsModal answare={(value) => {
                            value ? this.clearAll(this.state.goToSetup) : this.triggerAlert(false)
                        }}
                                     open={this.state.clearModal || this.state.validateModal}
                                     closeModal={() => {
                                         this.triggerAlert(false)
                                     }}
                                     roundSetup={this.state.goToSetup}
                                     scaled18HBH={Golfer?.use_scaling && hasHandicapIndex && values.nrHoles === 18}
                                     type={this.state.validateModal ? 'valid-score' : 'clear-all-scores'}/>

                        <div className="hbh-container">

                            <div className="hbh-pre_head">
                                <div className="hbh-pre_cell"><span className="hbh-title">{this.props.step} 9</span>
                                </div>
                                <div className="hbh-pre_cell" onClick={() => {
                                    this.triggerAlert(true)
                                }}><span className="hbh-clear">Clear All Scores</span></div>
                            </div>

                            <div className="hbh">


                                <Fragment>
                                    <div className="hbh_row hole">
                                        <div className="hbh_cell label">Hole</div>
                                        {this.generateCells(this.props.step)}
                                        <div className="hbh_cell">{values.nrHoles === 9 ? 'TOT' : this.props.step === 'front' ? 'OUT' : 'IN'}</div>
                                        {(this.props.step === 'back' && values.nrHoles === 18) &&
                                        <div className="hbh_cell">TOT</div>}
                                    </div>

                                    <div className="hbh_row yards">
                                        <div className="hbh_cell label">Yards</div>
                                        {this.generateCells(this.props.step, 'yards')}
                                        <div
                                            className="hbh_cell">{this.returnTotal(values.tee.Holes.Hole, 'Length', this.props.step === 'front' ? 0 : 9, this.props.step === 'front' ? 8 : 17)}</div>
                                        {(this.props.step === 'back' && values.nrHoles === 18) && <div
                                            className="hbh_cell">{this.returnTotal(values.tee.Holes.Hole, 'Length', 0, 17)}</div>}
                                    </div>

                                    <div className="hbh_row par">
                                        <div className="hbh_cell label">Par</div>
                                        {this.generateCells(this.props.step, 'par')}
                                        <div
                                            className="hbh_cell">{this.returnTotal(values.tee.Holes.Hole, 'Par', this.props.step === 'front' ? 0 : 9, this.props.step === 'front' ? 8 : 17)}</div>
                                        {(this.props.step === 'back' && values.nrHoles === 18) && <div
                                            className="hbh_cell">{this.returnTotal(values.tee.Holes.Hole, 'Par', 0, 17)}</div>}
                                    </div>
                                    <div className="hbh_row">
                                        <div className="hbh_cell label">Stroke Index</div>
                                        {this.generateCells(this.props.step, 'stroke')}
                                        <div className="hbh_cell"></div>
                                        {(this.props.step === 'back' && values.nrHoles === 18) &&
                                        <div className="hbh_cell"></div>}
                                    </div>
                                    <div className="hbh_row score">
                                        <div className="hbh_cell label">Score</div>
                                        {this.generateCells(this.props.step, 'hole')}
                                        <div
                                            className="hbh_cell">{this.returnTotal(values.holes, 'hole', this.props.step === 'front' ? 1 : 10, this.props.step === 'front' ? 9 : 18)}</div>
                                        {(this.props.step === 'back' && values.nrHoles === 18) &&
                                        <div className="hbh_cell">{this.returnTotal(values.holes, 'hole', 1, 18)}</div>}
                                    </div>

                                    <div className="hbh_row esc_score ">
                                        <div className="hbh_cell label ">Adj. Score</div>
                                        {this.generateCells(this.props.step, 'ags')}
                                        <div
                                            className="hbh_cell">{this.returnTotal(values.ags, 'hole', this.props.step === 'front' ? 1 : 10, this.props.step === 'front' ? 9 : 18)}</div>
                                        {(this.props.step === 'back' && values.nrHoles === 18) &&
                                        <div className="hbh_cell">{this.returnTotal(values.ags, 'hole', 1, 18)}</div>}
                                    </div>

                                </Fragment>

                            </div>

                        </div>
                    </div>
                </section>
                <CustomKeyboard changeValue={(value) => {
                    this.checkValue(value, this.state.target)
                }}
                                hideKeyboard={() => this.setState({focused: false})}
                                type={'number'}
                                name={this.state.focusedInput}
                                value={this.props.formValues.holes[this.state.focusedInput]}
                                focused={this.state.focused}/>
            </Fragment>
        );
    }
}

Table = reduxForm({
    // a unique name for the form
    form: 'hbh',
    destroyOnUnmount: false
})(Table);

function mapStateToProps(state) {
    return {
        formValues: getFormValues('hbh')(state),
        Golfer: state.Golfer,
        kiosk: state.kiosk,
        keyboard:  (state.kiosk.kiosk_setup && state.kiosk.kiosk_setup.show_keyboard)
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Table);
