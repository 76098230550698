import React, {Component} from 'react';
import AlertValidGhin from "./AlertsModals/AlertValidGhin";
import AlertValidLocalNumber from "./AlertsModals/AlertValidLocalNumber";
import AlertValidCriteria from "./AlertsModals/AlertValidCriteria";
import AlertNoGolferGhin from "./AlertsModals/AlertNoGolferGhin";
import AlertNetworkError from "./AlertsModals/AlertNetworkError";
import AlertKioskIsOffline from "./AlertsModals/AlertKioskIsOffline";
import AlertClearAllScores from "./AlertsModals/AlertClearAllScores";
import AlertChangeNrHoles from "./AlertsModals/AlertChangeNrHoles";
import AlertScorePosted from "./AlertsModals/AlertScorePosted";
import AlertScoreLow from "./AlertsModals/AlertScoreLow";
import AlertSeason from "./AlertsModals/AlertSeason";
import AlertScoreHigh from "./AlertsModals/AlertScoreHigh";
import AdjustedGrossScore from "./AlertsModals/AdjustedGrossScore";
import CourseHandicap from "./AlertsModals/CourseHandicap";
import PCC from "./PCC";
import Adjustments from "./Adjustments";
import AlertValidScore from "./AlertsModals/AlertValidScore";
import CantPostHbhModal from "./CantPostHbhModal";
import AlertNoTees from "./AlertsModals/AlertNoTees";
import AlertDuplicateScores from "./AlertsModals/AlertDuplicateScore";
import AlertChangeSetupScores from "./AlertsModals/AlertChangeSetupScore";
import AlertChangeSetupTee from "./AlertsModals/AlertChangeSetupTee";
import AlertApiError from "./AlertsModals/AlertApiError";
import AlertInactiveGolfer from "./AlertsModals/AlertInactiveGolfer";
import AlertNonGhinGolfer from "./AlertsModals/AlertNonGhinGolfer";
import FormCourseModal from "./FormCourseModal";
import Modal from "react-modal";
import AlertShortCourse from "./AlertsModals/AlertShortCourse";
import CombinedScores from "./AlertsModals/CombinedScores";
import ScaledScores from "./AlertsModals/ScaledScore"
import AlertMinorGolfer from "./AlertsModals/AlertMinorGolfer";
import Differential from "./AlertsModals/Differential";

export default class AlertsModal extends Component {

    componentWillMount() {
        Modal.setAppElement('body');
    }

    getType(type) {
        switch (type) {
            case 'invalid-ghin':
                return <AlertValidGhin triggerAlertModal={() => this.props.closeModal()}/>;
            case 'invalid-local-number':
                return <AlertValidLocalNumber triggerAlertModal={() => this.props.closeModal()}/>;
            case 'network-error':
                return <AlertNetworkError triggerAlertModal={() => this.props.closeModal()}/>;
            case 'kiosk-is-ofline':
                return <AlertKioskIsOffline triggerAlertModal={() => this.props.closeModal()}/>;
            case 'clear-all-scores':
                return <AlertClearAllScores message={this.props.roundSetup} onAnsware={(value) => {
                    this.props.answare(value)
                }} triggerAlertModal={() => this.props.closeModal()}/>;
            case 'change-number-holes':
                return <AlertChangeNrHoles triggerAlertModal={() => this.props.closeModal()}/>;
            case 'score-posted':
                return <AlertScorePosted triggerAlertModal={() => this.props.closeModal()}/>;
            case 'form-course':
                return <FormCourseModal triggerAlertModal={() => this.props.closeModal()}/>;
            case 'no-golfer':
                return <AlertValidCriteria triggerAlertModal={() => this.props.closeModal()}/>;
            case 'no-golfer-ghin':
                return <AlertNoGolferGhin triggerAlertModal={() => this.props.closeModal()}/>;
            case 'inactive-golfer':
                return <AlertInactiveGolfer triggerAlertModal={() => this.props.closeModal()}/>;
            case 'minor-golfer':
                return <AlertMinorGolfer triggerAlertModal={() => this.props.closeModal()}/>;
            case 'non-ghin':
                return <AlertNonGhinGolfer triggerAlertModal={() => this.props.closeModal()}/>;
            case 'valid-score':
                return <AlertValidScore scaled18HBH = {this.props.scaled18HBH} triggerAlertModal={() => this.props.closeModal()}/>;
            case 'cant-post-hbh':
                return <CantPostHbhModal triggerAlertModal={() => this.props.closeModal()}/>;
            case 'adjusted':
                return <AdjustedGrossScore triggerAlertModal={() => this.props.closeModal()}/>;
            case 'course-handicap':
                return <CourseHandicap triggerAlertModal={() => this.props.closeModal()}/>;
            case 'adjustments':
                return <Adjustments data={this.props.data} triggerAlertModal={() => this.props.closeModal()}/>;
            case 'pcc':
                return <PCC triggerAlertModal={() => this.props.closeModal()}/>;
            case 'alert-score-low':
                return <AlertScoreLow triggerAlertModal={() => this.props.closeModal()}/>;
            case 'no-tees':
                return <AlertNoTees triggerAlertModal={() => this.props.closeModal()}/>;
            case 'short-course':
                return <AlertShortCourse triggerAlertModal={() => this.props.closeModal()}/>;
            case 'alert-season':
                return <AlertSeason triggerAlertModal={() => this.props.closeModal()}/>;
            case 'alert-score-high':
                return <AlertScoreHigh triggerAlertModal={() => this.props.closeModal()}/>;
            case 'alert-under-review-score':
                return <AlertDuplicateScores message = {this.props.message} onAnsware={(value) => {
                    this.props.answare(value)
                }} triggerAlertModal={() => this.props.closeModal()}/>;
            case 'change-setup-score':
                return <AlertChangeSetupScores onAnsware={(value) => {
                    this.props.answare(value)
                }} triggerAlertModal={() => this.props.closeModal()}/>;
            case 'change-tee' :
                return <AlertChangeSetupTee onAnsware={(value) => {this.props.answare(value)}} triggerAlertModal={() => this.props.closeModal()}/>;
            case 'combined-scores':
                return <CombinedScores data={this.props.data} triggerAlertModal={() => this.props.closeModal()} />;
            case 'scaled-scores':
                return <ScaledScores triggerAlertModal={() => this.props.closeModal()}/>;
            case 'differential':
                return <Differential triggerAlertModal={() => this.props.closeModal()}/>;
            case 'api-error':
                return <AlertApiError errorMessage={this.props.errorMessage} triggerAlertModal={() => this.props.closeModal()}/>
            default:
                return null;
        }
    }

    render() {
        return (
            <Modal
                isOpen={this.props.open}
                contentLabel="Alert Valid Ghin"
                portalClassName="modal"
                overlayClassName="modal__overlay"
                className="modal__content"
                bodyOpenClassName="modal--is-open"
                htmlOpenClassName="prevent-scroll"
                shouldCloseOnOverlayClick={true}
                shouldFocusAfterRender={false}
                onRequestClose={() => this.props.closeModal()}
            >
                {this.getType(this.props.type)}
            </Modal>
        );
    }
}


