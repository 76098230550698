export function lookupGolferReducer(state = null, action) {
    switch (action.type) {
        case 'CHANGE_LOOKUP_GOLFER':
            return {...state, ...action.payload};
        case 'CLEAR_LOOKUP_GOLFER':
            return null;
        default:
            return state;
    }
}
