import React, {Component} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import { findDOMNode } from 'react-dom';

export default class CustomScrollbars extends Component {
    constructor(props) {
        super(props);
        this.scrollBarsRef = null;
    }

    componentDidMount() {
        if (this.props.fromChildToParent) {
            const container = findDOMNode(this.scrollBarsRef);
            const inner = container.querySelector("div");

            this.props.fromChildToParent(inner);
        }
    }

    render() {

        return (

            <Scrollbars className="custom_scrollbars" autoHeightMax={this.props.autoHeightMax ? this.props.autoHeightMax : '100%'}
                // renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                // renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                renderThumbVertical={props => <div {...props} style={{position:'relative', right:6, width: 12, borderRadius:10, backgroundColor:'#c7c8c9'}}/>}
                onScroll={this.handleScroll}
                onScrollFrame={this.handleScrollFrame}
                onScrollStart={this.handleScrollStart}
                onScrollStop={this.handleScrollStop}
                onUpdate={this.handleUpdate}
                //hideTracksWhenNotNeeded={true}
                autoHeight
                autoHide={false}
                ref={ref => this.scrollBarsRef = ref}
                {...this.props} />

        );

    }

}
