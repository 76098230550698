import React, {Component, Fragment} from 'react';
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import {changeLookupGolfer} from "../../../shared/actions/golfer";

import Navigation from '../../../shared/Navigation'
import LookupByName from './LookupByName';
import LookupByGhin from './LookupByGhin';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

class LookupSearch extends Component {

    render() {
        return (
            <Fragment>
                <Navigation backButton={true} postButton={false} goToProfile={true}/>

                <section className="top-padding small golfer-lookup">
                    <div className="container wide">

                        <div className="tabs-container">
                            <ul className="tabs">
                                <li><NavLink to={`${this.props.match.url}/name`} activeClassName="is-active">Lookup By
                                    Name</NavLink></li>
                                <li><NavLink to={`${this.props.match.url}/ghin`} activeClassName="is-active">Lookup By
                                    Ghin Number</NavLink></li>
                            </ul>
                        </div>

                        <Switch>
                            <Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/name`}/>
                            <Route path={`${this.props.match.url}/name`} exact
                                   render={(props) => <LookupByName {...this.props} />}/>
                            <Route path={`${this.props.match.url}/ghin`} exact
                                   render={(props) => <LookupByGhin {...this.props} />}/>
                        </Switch>

                    </div>
                </section>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        kiosk: state.kiosk,
        Golfer: state.Golfer,
        keyboard:  (state.kiosk.kiosk_setup && state.kiosk.kiosk_setup.show_keyboard)
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({changeLookupGolfer}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LookupSearch);

